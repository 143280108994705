import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { ROUTES } from "@/routes";
import { useSwapsHistory } from "@/services/subgraph";
import { useWallet } from "@/contexts/WalletContext";
import { Flexbox } from "@/components/Flexbox";
import type { SwapToken, NativeSwapToken } from "@/pages/Swap/types";

import { getTimestamp14DaysAgo } from "./utils/getTimestamp14DaysAgo";
import { SwapRecentTransactionsStateMessage } from "./components/SwapRecentTransactionsStateMessage";
import { SwapRecentTransactionsRow } from "./components/SwapRecentTransactionsRow";
import { useIdToggle } from "@/hooks/useIdToggle";
import { SwapRecentTransactionsDetailsDialog } from "./components/SwapRecentTransactionsDetailsDialog";

const MAX_TRANSACTIONS_COUNT = 4;

interface Props {
  tokenIn: SwapToken | NativeSwapToken | undefined;
  tokenOut: SwapToken | NativeSwapToken | undefined;
}

export const SwapRecentTransactions: React.FC<Props> = (props) => {
  const { tokenIn, tokenOut } = props;

  const { connectedAddress, connectedChain, connectedWalletProvider } =
    useWallet();

  const swapsHistoryStartTimestamp = React.useRef<number>(
    getTimestamp14DaysAgo()
  );

  const { data: swapsHistory } = useSwapsHistory(
    connectedChain !== null ? connectedChain.subgraphUrl : undefined,
    tokenIn !== undefined && tokenIn.xToken !== undefined
      ? tokenIn.xToken.address
      : undefined,
    tokenOut !== undefined && tokenOut.xToken !== undefined
      ? tokenOut.xToken.address
      : undefined,
    swapsHistoryStartTimestamp.current,
    connectedAddress !== null ? connectedAddress : undefined
  );

  const {
    id: swapDetailsId,
    toggleOn: openSwapDetails,
    toggleOff: closeSwapDetails,
  } = useIdToggle<string | undefined>(undefined);

  const swapDetails =
    swapsHistory !== undefined
      ? swapsHistory.find((swap) => {
          return swap.id === swapDetailsId;
        })
      : undefined;

  const getContent = (): React.ReactNode => {
    if (connectedAddress === null) {
      return (
        <SwapRecentTransactionsStateMessage>
          No account connected
        </SwapRecentTransactionsStateMessage>
      );
    }

    if (tokenIn === undefined || tokenOut === undefined) {
      return (
        <SwapRecentTransactionsStateMessage>
          No token pair selected
        </SwapRecentTransactionsStateMessage>
      );
    }

    if (swapsHistory !== undefined && swapsHistory.length === 0) {
      return (
        <SwapRecentTransactionsStateMessage>
          No swaps in the past 14 days
        </SwapRecentTransactionsStateMessage>
      );
    }

    return (
      <React.Fragment>
        <Flexbox
          direction="column"
          justifyContent="start"
          alignItems="start"
          sx={{
            width: "100%",
          }}
        >
          {swapsHistory !== undefined
            ? swapsHistory.slice(0, MAX_TRANSACTIONS_COUNT).map((swap) => {
                return (
                  <SwapRecentTransactionsRow
                    key={swap.id}
                    swap={swap}
                    openDetails={() => {
                      openSwapDetails(swap.id);
                    }}
                  />
                );
              })
            : null}
        </Flexbox>

        <Link
          href={ROUTES.TRADE_PLATFORM_WALLETS}
          target="_blank"
          rel="noopener"
          underline="hover"
          color="primary"
          sx={{
            marginTop: 2,
          }}
        >
          View all swaps
        </Link>
      </React.Fragment>
    );
  };

  return (
    <Flexbox
      direction="column"
      justifyContent="start"
      alignItems="start"
      sx={(theme) => {
        return {
          width: "100%",
          backgroundColor: theme.custom.colors.white,
          borderRadius: "8px",
          padding: "8px",
        };
      }}
    >
      <Typography
        variant="h6"
        fontWeight={700}
        sx={(theme) => {
          return {
            flex: 1,
            color: theme.custom.colors.gray,
          };
        }}
      >
        Recent transactions
      </Typography>

      {getContent()}

      <SwapRecentTransactionsDetailsDialog
        swapDetails={swapDetails}
        connectedChain={connectedChain}
        connectedWalletProvider={connectedWalletProvider}
        close={closeSwapDetails}
      />
    </Flexbox>
  );
};
