import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import NumberFormat, { InputAttributes } from "react-number-format";

interface Props {
  value: string;
  isSelected?: boolean;
  setValue: (value: string) => void;
}

export const SwapFormCustomSlippageInput: React.FC<Props> = (props) => {
  const { value, isSelected = false, setValue } = props;

  return (
    <StyledTextField
      placeholder="Custom"
      value={value}
      $isSelected={isSelected}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        endAdornment: (
          <InputAdornment position="end" disableTypography={true}>
            %
          </InputAdornment>
        ),
      }}
      inputProps={{
        style: {
          height: "36px",
          fontSize: "1rem",
          textAlign: "right",
          padding: 0,
        },
      }}
    />
  );
};

interface StyledTextFieldProps {
  $isSelected: boolean;
}
const StyledTextField = styled(TextField)<StyledTextFieldProps>(
  ({ theme, $isSelected }) => {
    return {
      width: "98px",
      height: "36px",
      "& .MuiOutlinedInput-root": {
        fontWeight: 500,
        color: $isSelected
          ? theme.custom.colors.white
          : theme.custom.colors.gray,
        paddingRight: "10px",
        backgroundColor: $isSelected
          ? theme.custom.colors.primary
          : theme.custom.colors.white,
        transition: theme.custom.transitions.backgroundColor,

        "& fieldset": {
          borderColor: $isSelected
            ? theme.custom.colors.primary
            : theme.custom.colors.lightgray,
        },
        "&:hover fieldset": {
          borderColor: theme.custom.colors.primary,
          boxShadow: theme.custom.shadows.levelOne,
        },
        "&.Mui-focused": {
          color: theme.custom.colors.primary,
          backgroundColor: theme.custom.colors.white,
        },
        "&.Mui-focused fieldset": {
          borderWidth: "1px",
          borderColor: theme.custom.colors.primary,
          boxShadow: theme.custom.shadows.levelOne,
        },
        "& .MuiInputAdornment-root": {
          color: "currentColor",
        },
        "& .MuiInputBase-input::placeholder": {
          color: theme.custom.colors.gray,
        },
      },
      "& .MuiInputAdornment-root": {
        color: theme.custom.colors.gray,
        marginLeft: "4px",
      },
    };
  }
);

interface NumberFormatCustomProps {
  value: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const NumberFormatCustom = React.forwardRef<
  NumberFormat<InputAttributes>,
  NumberFormatCustomProps
>(function NumberFormatCustom(props, ref) {
  const { value, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      value={value}
      getInputRef={ref}
      onValueChange={(values) => {
        // onValueChange is not same as onChange, it gets called on whenever there is change in value which can be caused by
        // any event like change or blur event or by a prop change
        if (values.value !== value) {
          onChange({ target: { name: "", value: values.value } });
        }
      }}
      isNumericString={true}
      isAllowed={(values) => {
        if (values.floatValue === undefined) {
          return true;
        }
        return values.floatValue >= 0 && values.floatValue <= 100;
      }}
    />
  );
});
