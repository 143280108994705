import React from "react";

import { Chain } from "@/constants/chains";
import { Address, Signer } from "@/types/web3";
import {
  State,
  useTwoStepTransactionFlag,
} from "@/hooks/useTwoStepTransactionFlag";
import { getWrapNativeTokenTransaction } from "@/contracts";
import {
  getTransactionEstimatedGasLimit,
  getTransactionGasEstimation,
} from "@/services/gas-estimator";
import { captureException } from "@/services/sentry";
import { getDenormalizedDecimalsNumber } from "@/utils/getDenormalizedDecimalsNumber";
import { getNormalizedDecimalsNumber } from "@/utils/getNormalizedDecimalsNumber";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";
import { matchIsNativeSwapToken } from "@/pages/Swap/utils/matchIsNativeSwapToken";

import type { SwapTransactionResult } from "../types";
import { getMaxNativeTokenInAmount } from "../utils/getMaxNativeTokenInAmount";
import { matchIsWrappingNativeToken } from "../utils/matchIsWrappingNativeToken";
import { getSwapEthersErrorTitle } from "../utils/getSwapEthersErrorTitle";

type WrapNativeToken = (amount: string) => Promise<SwapTransactionResult>;

interface ReturnValue {
  wrapState: State;
  maxNativeTokenInAmount: string | undefined;
  isWrappingNativeToken: boolean;
  wrapNativeToken: WrapNativeToken;
}

export function useWrapNativeToken(
  tokenIn: SwapToken | NativeSwapToken | undefined,
  tokenOut: SwapToken | NativeSwapToken | undefined,
  maxTokenInAmount: string | undefined,
  connectedChain: Chain | null,
  connectedWalletSigner: Signer | null,
  setTokenOutAddress: (address: Address) => void
): ReturnValue {
  const {
    state: wrapState,
    startConfirmation: startWrapConfirmation,
    startExecution: startWrapExecution,
    stop: stopWrap,
  } = useTwoStepTransactionFlag();

  const [wrapNativeTokenGasFee, setWrapNativeTokenGasFee] = React.useState<
    string | undefined
  >(undefined);

  const wrapNativeToken = React.useCallback<WrapNativeToken>(
    async (amount) => {
      if (connectedChain === null || connectedWalletSigner === null) {
        return {
          title: "There was an issue. Please try again.",
          hadSuccess: false,
        };
      }

      const normalizedAmount = getNormalizedDecimalsNumber(
        amount,
        connectedChain.nativeToken.decimals
      ).toString();

      let transactionHash: string | undefined = undefined;

      startWrapConfirmation();
      try {
        const populatedTransaction = await getWrapNativeTokenTransaction(
          connectedChain.wrappedNativeToken.address,
          normalizedAmount,
          connectedWalletSigner
        );

        const gasLimit = await getTransactionEstimatedGasLimit(
          populatedTransaction,
          connectedWalletSigner
        );

        const transaction = await connectedWalletSigner.sendTransaction({
          ...populatedTransaction,
          gasLimit,
        });

        transactionHash = transaction.hash;

        startWrapExecution();

        await transaction.wait();

        return {
          title: "Wrap successful.",
          transactionHash,
          hadSuccess: true,
        };
      } catch (error) {
        captureException(error as Error);

        return {
          title: getSwapEthersErrorTitle(error as Error),
          transactionHash,
          hadSuccess: false,
        };
      } finally {
        stopWrap();
      }
    },
    [
      connectedChain,
      connectedWalletSigner,
      startWrapConfirmation,
      startWrapExecution,
      stopWrap,
    ]
  );

  // This effect listens on picking the native token to select the wrapped native token on the other side
  React.useEffect(() => {
    if (
      tokenIn !== undefined &&
      connectedChain !== null &&
      matchIsNativeSwapToken(tokenIn)
    ) {
      setTokenOutAddress(connectedChain.wrappedNativeToken.address);
    }
  }, [tokenIn, connectedChain, setTokenOutAddress]);

  const isWrappingNativeToken = matchIsWrappingNativeToken(
    tokenIn,
    tokenOut,
    connectedChain
  );

  // This effect is in charge of updating the wrap native token gas fee value
  React.useEffect(() => {
    if (
      connectedChain === null ||
      connectedWalletSigner === null ||
      isWrappingNativeToken === false
    ) {
      return;
    }

    const updateWrapNativeTokenGasFee = async () => {
      try {
        const populatedTransaction = await getWrapNativeTokenTransaction(
          connectedChain.wrappedNativeToken.address,
          "1", // amount does not matter for gas fee estimation
          connectedWalletSigner
        );

        const { gasEstimate } = await getTransactionGasEstimation(
          populatedTransaction,
          connectedWalletSigner,
          connectedChain.key
        );

        const denormalizedGasEstimate = getDenormalizedDecimalsNumber(
          gasEstimate,
          connectedChain.nativeToken.decimals
        );

        setWrapNativeTokenGasFee(denormalizedGasEstimate.toString());
      } catch {
        // do nothing
      }
    };

    updateWrapNativeTokenGasFee();
  }, [connectedChain, connectedWalletSigner, isWrappingNativeToken]);

  const maxNativeTokenInAmount = isWrappingNativeToken
    ? getMaxNativeTokenInAmount(maxTokenInAmount, wrapNativeTokenGasFee)
    : maxTokenInAmount;

  const value = React.useMemo<ReturnValue>(() => {
    return {
      wrapState,
      maxNativeTokenInAmount,
      isWrappingNativeToken,
      wrapNativeToken,
    };
  }, [
    wrapState,
    maxNativeTokenInAmount,
    isWrappingNativeToken,
    wrapNativeToken,
  ]);

  return value;
}
