import React from "react";
import { SwapTxType } from "@swarmmarkets/smart-order-router";

import type { EthersBigNumber } from "@/types/web3";
import { Chain } from "@/constants/chains";
import { useBatchedValues } from "@/hooks/useBatchedValues";
import { getXTokenPriceInSmt } from "@/services/multicall";
import { useSwapAdvancedSettingsLocalStorage } from "@/services/local-storage";
import type {
  NativeSwapToken,
  SwapOptimizationStrategy,
  SwapToken,
} from "@/pages/Swap/types";

interface ReturnValue {
  swapType: SwapTxType;
  swapOptimizationStrategy: SwapOptimizationStrategy;
  swapSlippageTolerance: number;
  isSmtFeeDiscountEnabled: boolean;
  isSmtFeeDiscountAvailable: boolean;
  isSmtFeeDiscountApplied: boolean;
  setSwapType: (swapType: SwapTxType) => void;
  setSwapOptimizationStrategy: (
    swapOptimizationStrategy: SwapOptimizationStrategy
  ) => void;
  setIsSmtFeeDiscountEnabled: (isSmtFeeDiscountEnabled: boolean) => void;
}

export function useSwapSettings(
  connectedChain: Chain | null,
  tokenIn: SwapToken | NativeSwapToken | undefined,
  isWrappingNativeToken: boolean,
  isUnwrappingNativeToken: boolean
): ReturnValue {
  const [swapType, setSwapType] = React.useState<SwapTxType>(
    SwapTxType.exactIn
  );

  const [swapOptimizationStrategy, setSwapOptimizationStrategy] =
    React.useState<SwapOptimizationStrategy>("price");

  const { value: settings } = useSwapAdvancedSettingsLocalStorage();

  const [isSmtFeeDiscountEnabled, setIsSmtFeeDiscountEnabled] =
    React.useState<boolean>(settings.isSmtFeeDiscountEnabled);

  const smtFeeDiscountBatchedValuesCalls = React.useMemo(() => {
    if (
      connectedChain === null ||
      tokenIn === undefined ||
      tokenIn.xToken === undefined
    ) {
      return [];
    }

    return [
      getXTokenPriceInSmt(
        connectedChain.contractsAddresses.smtPriceFeed,
        tokenIn.xToken.address,
        { label: "xTokenInPriceInSmt" }
      ),
    ];
  }, [connectedChain, tokenIn]);

  const smtFeeDiscountBatchedValues = useBatchedValues<{
    xTokenInPriceInSmt: EthersBigNumber;
  }>(smtFeeDiscountBatchedValuesCalls);

  const isSmtFeeDiscountAvailable =
    isWrappingNativeToken === false &&
    isUnwrappingNativeToken === false &&
    smtFeeDiscountBatchedValues !== null &&
    smtFeeDiscountBatchedValues.xTokenInPriceInSmt !== undefined &&
    smtFeeDiscountBatchedValues.xTokenInPriceInSmt.gt(0);

  const isSmtFeeDiscountApplied =
    isSmtFeeDiscountAvailable && isSmtFeeDiscountEnabled;

  const value = React.useMemo<ReturnValue>(() => {
    return {
      swapType,
      swapOptimizationStrategy,
      swapSlippageTolerance: settings.slippageTolerance,
      isSmtFeeDiscountEnabled,
      isSmtFeeDiscountAvailable,
      isSmtFeeDiscountApplied,
      setSwapType,
      setSwapOptimizationStrategy,
      setIsSmtFeeDiscountEnabled,
    };
  }, [
    swapType,
    swapOptimizationStrategy,
    settings.slippageTolerance,
    isSmtFeeDiscountEnabled,
    isSmtFeeDiscountAvailable,
    isSmtFeeDiscountApplied,
    setSwapType,
    setSwapOptimizationStrategy,
    setIsSmtFeeDiscountEnabled,
  ]);

  return value;
}
