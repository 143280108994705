import { XTokenModel } from '../types/x-token.model'
import { unifyAddress } from '../utils/unify-address'
import { PoolToken } from './pool-token'

export class XToken {
  public readonly id: string

  public readonly decimals: number

  public readonly paused: boolean

  public readonly poolTokens: PoolToken[]

  constructor(xToken: XTokenModel) {
    this.id = unifyAddress(xToken.id)
    this.decimals = Number(xToken?.decimals || 0)
    this.paused = Boolean(xToken?.paused)

    const allPoolTokens = xToken?.poolTokens?.map(token => new PoolToken(token)) || []

    this.poolTokens = allPoolTokens.filter(
      ({ pool, balance }) => pool && pool.publicSwap && pool.tokensList.length > 1 && balance.gt(0),
    )
  }
}
