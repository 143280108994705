import { Call } from "../types";

export function getSwapFeeProtocolFeePercentage(
  protocolFeeAddress: string,
  options: { label?: string } = {}
): Call {
  const { label = "swapFeeProtocolFeePercentage" } = options;

  return {
    target: protocolFeeAddress,
    call: ["protocolFee()(uint256)"],
    label,
  };
}
