import type { Theme } from "@mui/material/styles";
import type { SxProps } from "@mui/system/styleFunctionSx";

export type SxPropArgument = SxProps<Theme> | undefined;

export function getMergedSxProps(...sxProps: SxPropArgument[]): SxProps<Theme> {
  const getBoxPropsSx = (sxProp: SxPropArgument) => {
    if (sxProp === undefined) {
      return [];
    }

    if (Array.isArray(sxProp)) {
      return sxProp;
    }

    return [sxProp];
  };

  return sxProps.reduce<ReturnType<typeof getBoxPropsSx>>(
    (currentMergedSxProps, sxProp) => {
      return [...currentMergedSxProps, ...getBoxPropsSx(sxProp)];
    },
    []
  );
}
