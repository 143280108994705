import React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { ROUTES } from "@/routes";
import { getBigNumber } from "@/utils/getBigNumber";
import { Flexbox } from "@/components/Flexbox";
import { Balance } from "@/components/Balance";

interface Props {
  symbol: string;
  minAmount: string;
}

export const SwapFormSecurityRestriction: React.FC<Props> = (props) => {
  const { symbol, minAmount } = props;

  return (
    <Flexbox
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={(theme) => {
        return {
          width: "100%",
          minHeight: "40px",
          paddingX: "10px",
          paddingY: "6px",
          borderRadius: "4px",
          backgroundColor: theme.custom.colors.background.default,
        };
      }}
    >
      <Typography
        sx={{
          fontSize: "0.75rem",
        }}
      >
        The issuer of {symbol} has set a minimum initial purchase amount of at
        least €
        <Balance
          value={getBigNumber(minAmount).div(1000).toString()}
          decimals={0}
        />
        K.{" "}
        <Link
          href={ROUTES.DOCS_SECURITY_RESTRICTION}
          target="_blank"
          rel="noopener"
          color="inherit"
        >
          Learn&nbsp;more
        </Link>
      </Typography>
    </Flexbox>
  );
};
