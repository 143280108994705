import SwapRouter, { SwapError } from "@swarmmarkets/smart-order-router";

import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";

const MAX_NUMBER_OF_POOLS = 5;

export function getSwapRouter(
  tokenIn: SwapToken | NativeSwapToken | undefined,
  tokenOut: SwapToken | NativeSwapToken | undefined
) {
  if (
    tokenIn === undefined ||
    tokenOut === undefined ||
    tokenIn.xToken === undefined ||
    tokenOut.xToken === undefined
  ) {
    return { swapRouter: undefined, swapRouterError: undefined };
  }

  const getSwapRouterSwapToken = (token: SwapToken) => {
    return {
      id: token.address,
      decimals: token.decimals,
      xToken: {
        id: token.xToken.address,
        decimals: token.xToken.decimals,
        paused: token.xToken.paused,
        poolTokens: token.xToken.poolTokens.map((poolToken) => {
          return {
            id: poolToken.address,
            balance: poolToken.balance,
            decimals: poolToken.decimals,
            denormWeight: poolToken.denormWeight,
            poolId: {
              id: poolToken.poolId.address,
              publicSwap: poolToken.poolId.publicSwap,
              swapFee: poolToken.poolId.swapFee,
              tokensList: poolToken.poolId.tokensList,
              totalWeight: poolToken.poolId.totalWeight,
              tokens: poolToken.poolId.tokens.map((token) => {
                return {
                  id: token.address,
                  balance: token.balance,
                  decimals: token.decimals,
                  denormWeight: token.denormWeight,
                };
              }),
            },
          };
        }),
      },
    };
  };

  const swapRouterTokenIn = getSwapRouterSwapToken(tokenIn);
  const swapRouterTokenOut = getSwapRouterSwapToken(tokenOut);

  try {
    const router = new SwapRouter(
      swapRouterTokenIn,
      swapRouterTokenOut,
      MAX_NUMBER_OF_POOLS
    );
    return { swapRouter: router, swapRouterError: undefined };
  } catch (error) {
    if (error instanceof SwapError) {
      return { swapRouter: undefined, swapRouterError: error.hash };
    }

    throw error;
  }
}
