import { LocalStorageService } from "../instance";
import { booleanEncoder, booleanDecoder } from "../codecs";
import { useLocalStorage } from "../useLocalStorage";

export const isWaitingProtocolAfterOnboardingStorage =
  new LocalStorageService<boolean>(
    "is-waiting-protocol-afer-onboarding",
    booleanEncoder(),
    booleanDecoder(false)
  );

export const useIsWaitingProtocolAfterOnboardingStorage = () => {
  return useLocalStorage(isWaitingProtocolAfterOnboardingStorage);
};
