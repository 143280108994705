import React from "react";
import { useTheme } from "@mui/material/styles";

import type { Breakpoint as BreakpointType } from "@/theme";
import { useContainerObserver } from "@/contexts/ContainerObserverContext";

interface BaseProps {
  children: React.ReactNode;
}

interface FromProps extends BaseProps {
  from: BreakpointType;
  until?: BreakpointType;
}

interface UntilProps extends BaseProps {
  from?: BreakpointType;
  until: BreakpointType;
}

export const Breakpoint: React.FC<FromProps | UntilProps> = (props) => {
  const { from, until, children } = props;

  const theme = useTheme();

  const { containerWidth } = useContainerObserver();

  const fromMatches =
    containerWidth >= theme.breakpoints.values[from || "mobile"];
  const untilMatches =
    containerWidth < theme.breakpoints.values[until || "mobile"];

  if (fromMatches && (untilMatches || until === undefined)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- this a workaround around not using React.ReactElement as children type
    return <React.Fragment>{children}</React.Fragment>;
  }

  return null;
};
