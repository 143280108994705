import React from "react";
import IconButton from "@mui/material/IconButton";
import SwapHorizontalIcon from "@mui/icons-material/SwapHoriz";
import Typography from "@mui/material/Typography";

import { getBigNumber } from "@/utils/getBigNumber";
import { useToggle } from "@/hooks/useToggle";
import { Flexbox } from "@/components/Flexbox";
import { Balance } from "@/components/Balance";

interface Props {
  tokenASymbol?: string;
  tokenBSymbol?: string;
  swapUsdPrice?: string;
}

export const SwapFormPriceSummary: React.FC<Props> = (props) => {
  const { tokenASymbol, tokenBSymbol, swapUsdPrice } = props;

  const { isOn: isInversed, toggle: toggleIsInversed } = useToggle();

  return (
    <Flexbox
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0.5}
      sx={{ width: "100%" }}
    >
      <Typography
        data-testid="label"
        component="span"
        fontSize="0.875rem"
        sx={(theme) => {
          return {
            color: theme.custom.colors.gray,
          };
        }}
      >
        {tokenASymbol !== undefined &&
        tokenBSymbol !== undefined &&
        swapUsdPrice !== undefined ? (
          <span>
            Price: 1 {isInversed ? tokenBSymbol : tokenASymbol} per{" "}
            <Balance
              value={
                isInversed
                  ? getBigNumber(1).div(swapUsdPrice).toString()
                  : swapUsdPrice
              }
              decimals={6}
            />{" "}
            {isInversed ? tokenASymbol : tokenBSymbol}
          </span>
        ) : (
          "--"
        )}
      </Typography>
      <IconButton
        aria-label="swap units"
        size="medium"
        color="primary"
        sx={{
          padding: "2.5px",
        }}
        onClick={toggleIsInversed}
      >
        <SwapHorizontalIcon />
      </IconButton>
    </Flexbox>
  );
};
