import {
  TRADE_PLATFORM_BASE_URL,
  WEBSITE_URL,
  DOCS_BASE_URL,
} from "./constants/environment";

export const ROUTES = Object.freeze({
  TRADE_PLATFORM_PASSPORT: getTradePlatformUrl("/passport"),
  TRADE_PLATFORM_WALLETS: getTradePlatformUrl("/wallets"),
  TRADE_PLATFORM_ONBOARDING: getTradePlatformUrl("/onboarding"),
  WEBSITE: WEBSITE_URL,
  DOCS_ABOUT: getDocsUrl("/about/about"),
  DOCS_SUPPORT: DOCS_BASE_URL,
  DOCS_LEGAL_AND_PRIVACY: getDocsUrl("/about/terms"),
  DOCS_PRIVACY: getDocsUrl("/about/terms/privacy"),
  DOCS_TERMS: getDocsUrl("/about/terms/tos"),
  DOCS_IMPRINT: getDocsUrl("/about/terms/imprint"),
  DOCS_SWAPS: getDocsUrl("/core-concepts/swaps"),
  DOCS_FAQ: getDocsUrl("/getting-started/faq"),
  DOCS_SECURITY_RESTRICTION: getDocsUrl("/reference/securities"),
});

function getTradePlatformUrl(path: string): string {
  return `${TRADE_PLATFORM_BASE_URL}${path}`;
}

function getDocsUrl(path: string): string {
  return `${DOCS_BASE_URL}${path}`;
}
