import React from "react";

import { useWallet } from "@/contexts/WalletContext";
import {
  Call,
  Config,
  useBatchedValues as useMulticallBatchedValues,
} from "@/services/multicall";

export function useBatchedValues<BatchedValues extends Record<string, any>>(
  calls: Call[]
) {
  const { connectedChain } = useWallet();

  const config = React.useMemo<Config | null>(() => {
    if (connectedChain === null) {
      return null;
    }

    return {
      rpcUrl: connectedChain.rpcUrl,
      multicallAddress: connectedChain.multicallAddress,
    };
  }, [connectedChain]);

  return useMulticallBatchedValues<BatchedValues>(calls, config);
}
