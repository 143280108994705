import React from "react";

import {
  ChainKey,
  TestnetChainKey,
  NATIVE_TOKEN_ADDRESS,
} from "@/constants/chains";
import { Address, BigNumber, EthersBigNumber } from "@/types/web3";
import { ListedToken, useListedTokens } from "@/services/subgraph";
import { useExchangePrices } from "@/services/api";
import {
  getErc20AllowanceMulticall,
  getErc20BalanceMulticall,
  getNativeTokenBalanceMulticall,
} from "@/services/multicall";
import { useWallet } from "@/contexts/WalletContext";
import { useConfiguration } from "@/state/configuration";
import { useBatchedValues } from "@/hooks/useBatchedValues";
import { useCPK } from "@/hooks/useCPK";
import { matchIsSameSymbol } from "@/utils/matchIsSameSymbol";
import { getDenormalizedDecimalsNumber } from "@/utils/getDenormalizedDecimalsNumber";
import { getNormalizedDecimalsNumber } from "@/utils/getNormalizedDecimalsNumber";
import { getBigNumber } from "@/utils/getBigNumber";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";

import { matchIsNativeSwapToken } from "../utils/matchIsNativeSwapToken";
import { matchIsSwapToken } from "../utils/matchIsSwapToken";

type ReturnValue = (SwapToken | NativeSwapToken)[] | undefined;

export function useSwapTokens(): ReturnValue {
  const { configuration } = useConfiguration();
  const { connectedChain: walletConnectedChain, connectedAddress } =
    useWallet();
  const cpk = useCPK();

  const connectedChain =
    walletConnectedChain !== null
      ? walletConnectedChain
      : configuration.supportedChains[0];

  const { data: listedTokens } = useListedTokens(
    connectedChain !== undefined ? connectedChain.subgraphUrl : undefined
  );

  const swapTokens = React.useMemo<
    (SwapToken | NativeSwapToken)[] | undefined
  >(() => {
    if (connectedChain === undefined || listedTokens === undefined) {
      return undefined;
    }

    const matchIsSupportedAsset = (token: { symbol: string }): boolean => {
      const connectedChainSupportedAssets =
        configuration.supportedAssetsByChainKey[connectedChain.key];

      if (connectedChainSupportedAssets === undefined) {
        return false;
      }

      return connectedChainSupportedAssets.some((supportedAsset) => {
        return matchIsSameSymbol(supportedAsset, token.symbol);
      });
    };

    const getListedTokenWithInitialInjectedValues = (
      listedToken: ListedToken
    ): SwapToken => {
      return {
        ...listedToken,
        balance: undefined,
        allowance: undefined,
        usdPrice: undefined,
        usdBalance: undefined,
        eurPrice: undefined,
        eurBalance: undefined,
        xToken: { ...listedToken.xToken, cpkBalance: undefined },
      };
    };

    const getNativeTokenWithInitialInjectedValues = (nativeToken: {
      name: string;
      symbol: string;
      address: Address;
      decimals: number;
    }): NativeSwapToken => {
      return {
        ...nativeToken,
        balance: undefined,
        allowance: undefined,
        usdPrice: undefined,
        usdBalance: undefined,
        eurPrice: undefined,
        eurBalance: undefined,
      };
    };

    const listedTokensSwapTokens = listedTokens
      .filter(matchIsSupportedAsset)
      .map(getListedTokenWithInitialInjectedValues);

    const addedSwapTokens = matchIsSupportedAsset(connectedChain.nativeToken)
      ? [getNativeTokenWithInitialInjectedValues(connectedChain.nativeToken)]
      : [];

    const swapTokens = [...listedTokensSwapTokens, ...addedSwapTokens];

    return swapTokens;
  }, [connectedChain, listedTokens, configuration]);

  const { data: exchangePrices } = useExchangePrices(
    connectedChain !== undefined ? connectedChain.key : null,
    connectedChain !== undefined && swapTokens !== undefined
      ? swapTokens.map((swapToken) => {
          if (connectedChain === null) {
            return swapToken.address;
          }

          const tesnetToMainnetMapping =
            TESTNET_TO_MAINNET_ADDRESSES[connectedChain.key as TestnetChainKey];

          if (tesnetToMainnetMapping !== undefined) {
            const mainnetAddress = tesnetToMainnetMapping[swapToken.address];

            if (mainnetAddress !== undefined) {
              return mainnetAddress;
            }
          }

          return swapToken.address;
        })
      : []
  );

  const calls = React.useMemo(() => {
    const cpkAddress = cpk === null ? undefined : cpk.address;

    if (
      connectedChain === null ||
      connectedAddress === null ||
      cpk === null ||
      cpkAddress === undefined ||
      swapTokens === undefined
    ) {
      return [];
    }

    const swapTokensAccountBalances = swapTokens.map((swapToken) => {
      if (matchIsNativeSwapToken(swapToken)) {
        return getNativeTokenBalanceMulticall(connectedAddress, {
          label: `accountBalance-${swapToken.symbol}`,
        });
      }

      return getErc20BalanceMulticall(swapToken.address, connectedAddress, {
        label: `accountBalance-${swapToken.symbol}`,
      });
    });

    const swapTokensCpkBalances = swapTokens.map((swapToken) => {
      if (matchIsNativeSwapToken(swapToken)) {
        return getNativeTokenBalanceMulticall(cpkAddress, {
          label: `cpkBalance-${swapToken.symbol}`,
        });
      }

      return getErc20BalanceMulticall(swapToken.address, cpkAddress, {
        label: `cpkBalance-${swapToken.symbol}`,
      });
    });

    const swapTokensXTokenCpkBalances = swapTokens
      .filter(matchIsSwapToken)
      .map((swapToken) => {
        return getErc20BalanceMulticall(swapToken.xToken.address, cpkAddress, {
          label: `xToken-cpkBalance-${swapToken.xToken.symbol}`,
        });
      });

    const swapTokensAllowances = swapTokens
      .filter(matchIsSwapToken)
      .map((swapToken) => {
        return getErc20AllowanceMulticall(
          swapToken.address,
          connectedAddress,
          cpkAddress,
          {
            label: `allowance-${swapToken.symbol}`,
          }
        );
      });

    return [
      ...swapTokensAccountBalances,
      ...swapTokensCpkBalances,
      ...swapTokensXTokenCpkBalances,
      ...swapTokensAllowances,
    ];
  }, [connectedChain, connectedAddress, cpk, swapTokens]);

  const batchedValues =
    useBatchedValues<
      Record<
        | `accountBalance-${string}`
        | `cpkBalance-${string}`
        | `xToken-cpkBalance-${string}`
        | `allowance-${string}`,
        EthersBigNumber
      >
    >(calls);

  const value = React.useMemo<ReturnValue>(() => {
    if (connectedChain === null || swapTokens === undefined) {
      return undefined;
    }

    const getBalance = (
      swapToken: SwapToken | NativeSwapToken
    ): BigNumber | undefined => {
      if (batchedValues === null) {
        return undefined;
      }

      const accountBalance =
        batchedValues[`accountBalance-${swapToken.symbol}`];
      const cpkBalance = batchedValues[`cpkBalance-${swapToken.symbol}`];

      if (accountBalance === undefined || cpkBalance === undefined) {
        return undefined;
      }

      return getBigNumber(accountBalance).add(getBigNumber(cpkBalance));
    };

    const getXTokenBalance = (swapToken: SwapToken): BigNumber | undefined => {
      if (batchedValues === null) {
        return undefined;
      }

      const xTokenCpkBalance =
        batchedValues[`xToken-cpkBalance-${swapToken.xToken.symbol}`];

      if (xTokenCpkBalance === undefined) {
        return undefined;
      }

      return getBigNumber(xTokenCpkBalance);
    };

    const getAllowance = (
      swapToken: SwapToken | NativeSwapToken
    ): BigNumber | undefined => {
      if (matchIsNativeSwapToken(swapToken)) {
        if (swapToken.balance === undefined) {
          return undefined;
        }

        return getNormalizedDecimalsNumber(
          swapToken.balance,
          swapToken.decimals
        );
      }

      if (batchedValues === null) {
        return undefined;
      }

      const allowance = batchedValues[`allowance-${swapToken.symbol}`];

      return allowance !== undefined ? getBigNumber(allowance) : undefined;
    };

    const getUsdPrice = (
      swapToken: SwapToken | NativeSwapToken
    ): string | undefined => {
      if (connectedChain === undefined) {
        return undefined;
      }

      const hardcodedExchangePrice =
        USD_HARDCODED_EXCHANGE_RATES[connectedChain.key][swapToken.address];
      if (hardcodedExchangePrice !== undefined) {
        return hardcodedExchangePrice;
      }

      if (exchangePrices === undefined) {
        return undefined;
      }

      if (connectedChain === null) {
        return undefined;
      }

      const tesnetToMainnetMapping =
        TESTNET_TO_MAINNET_ADDRESSES[connectedChain.key as TestnetChainKey];

      const tokenAddress =
        tesnetToMainnetMapping !== undefined
          ? tesnetToMainnetMapping[swapToken.address]
          : swapToken.address;

      if (tokenAddress === undefined) {
        return undefined;
      }

      const exchangePrice = exchangePrices[tokenAddress];
      if (exchangePrice !== undefined) {
        return exchangePrice.usd;
      }

      return undefined;
    };

    const getEurPrice = (
      swapToken: SwapToken | NativeSwapToken
    ): string | undefined => {
      if (connectedChain === undefined) {
        return undefined;
      }

      const hardcodedExchangePrice =
        EUR_HARDCODED_EXCHANGE_RATES[connectedChain.key][swapToken.address];
      if (hardcodedExchangePrice !== undefined) {
        return hardcodedExchangePrice;
      }

      if (exchangePrices === undefined) {
        return undefined;
      }

      if (connectedChain === null) {
        return undefined;
      }

      const tesnetToMainnetMapping =
        TESTNET_TO_MAINNET_ADDRESSES[connectedChain.key as TestnetChainKey];

      const tokenAddress =
        tesnetToMainnetMapping !== undefined
          ? tesnetToMainnetMapping[swapToken.address]
          : swapToken.address;

      if (tokenAddress === undefined) {
        return undefined;
      }

      const exchangePrice = exchangePrices[tokenAddress];
      if (exchangePrice !== undefined) {
        return exchangePrice.eur;
      }

      return undefined;
    };

    return swapTokens.map((swapToken) => {
      const swapTokenWithInjectedValues = { ...swapToken };

      // Balance
      const balance = getBalance(swapTokenWithInjectedValues);
      const normalizedBalance =
        balance !== undefined
          ? getDenormalizedDecimalsNumber(
              balance,
              swapTokenWithInjectedValues.decimals
            ).toString()
          : undefined;
      swapTokenWithInjectedValues.balance = normalizedBalance;

      // xToken Balance
      if (matchIsSwapToken(swapTokenWithInjectedValues)) {
        const xTokenBalance = getXTokenBalance(swapTokenWithInjectedValues);
        const normalizedXTokenBalance =
          xTokenBalance !== undefined
            ? getDenormalizedDecimalsNumber(
                xTokenBalance,
                swapTokenWithInjectedValues.xToken.decimals
              ).toString()
            : undefined;
        swapTokenWithInjectedValues.xToken.cpkBalance = normalizedXTokenBalance;
      }

      // Allowance
      const allowance = getAllowance(swapTokenWithInjectedValues);
      const normalizedAllowance =
        allowance !== undefined
          ? getDenormalizedDecimalsNumber(
              allowance,
              swapTokenWithInjectedValues.decimals
            ).toString()
          : undefined;
      swapTokenWithInjectedValues.allowance = normalizedAllowance;

      // USD price
      const usdPrice = getUsdPrice(swapTokenWithInjectedValues);
      swapTokenWithInjectedValues.usdPrice = usdPrice;

      // USD Balance
      const usdBalance =
        normalizedBalance !== undefined && usdPrice !== undefined
          ? getBigNumber(normalizedBalance).mul(usdPrice).toString()
          : undefined;
      swapTokenWithInjectedValues.usdBalance = usdBalance;

      // EUR price
      const eurPrice = getEurPrice(swapTokenWithInjectedValues);
      swapTokenWithInjectedValues.eurPrice = eurPrice;

      // EUR Balance
      const eurBalance =
        normalizedBalance !== undefined && eurPrice !== undefined
          ? getBigNumber(normalizedBalance).mul(eurPrice).toString()
          : undefined;
      swapTokenWithInjectedValues.eurBalance = eurBalance;

      return swapTokenWithInjectedValues;
    });
  }, [connectedChain, swapTokens, exchangePrices, batchedValues]);

  return value;
}

const USD_HARDCODED_EXCHANGE_RATES: Record<ChainKey, Record<string, string>> = {
  goerli: {
    // AAPL
    "0xe39a5100b5b5b9985c5338b873ee2b10d5c36a7a": "148.61",
    // TSLA
    "0x485029123ca201dff418ed8a081d88fd08331f41": "716.56",
    // COIN
    "0x2436cf1e565caa76e693197373cc441a90567fcd": "69.54",
  },
  rinkeby: {
    // AAPL
    "0xce15df031db6e3361c8229e1a2aae9abd1e124f7": "148.61",
    // TSLA
    "0x5688b125b3f2f85eace2042c6465e2ce377f5beb": "716.56",
    // COIN
    "0x052a561ea641f272755f93e04b3a5e39ef7c2aba": "69.54",
  },
  mumbai: {
    // RET
    "0x679ad40e5343aadd5d86a895b5b372e3f0b54976": "100.00",
  },
  ethereum: {},
  polygon: {},
};

const EUR_HARDCODED_EXCHANGE_RATES: Record<ChainKey, Record<string, string>> = {
  goerli: {
    // AAPL
    "0xe39a5100b5b5b9985c5338b873ee2b10d5c36a7a": "148.61",
    // TSLA
    "0x485029123ca201dff418ed8a081d88fd08331f41": "716.56",
    // COIN
    "0x2436cf1e565caa76e693197373cc441a90567fcd": "69.54",
  },
  rinkeby: {
    // AAPL
    "0xce15df031db6e3361c8229e1a2aae9abd1e124f7": "148.61",
    // TSLA
    "0x5688b125b3f2f85eace2042c6465e2ce377f5beb": "716.56",
    // COIN
    "0x052a561ea641f272755f93e04b3a5e39ef7c2aba": "69.54",
  },
  mumbai: {
    // RET
    "0x679ad40e5343aadd5d86a895b5b372e3f0b54976": "100.00",
  },
  ethereum: {},
  polygon: {},
};

const TESTNET_TO_MAINNET_ADDRESSES: Record<
  TestnetChainKey,
  Record<string, string>
> = {
  goerli: {
    // SMT
    "0x8fb7556388dda82507c4bc63fd787370070a5b5e":
      "0xb17548c7b510427baac4e267bea62e800b247173",
    // WBTC
    "0x28f3bbbf7591617d7162b832468718f6a0deb43b":
      "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    // WETH
    "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6":
      "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    // Native token uses WETH price
    [NATIVE_TOKEN_ADDRESS]: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    // DAI
    "0xeae974165ddfcb8ffaf266594067563d02617bd1":
      "0x6b175474e89094c44da98b954eedeac495271d0f",
    // USDC
    "0xe791bc51d14b0507a622070d4edf4eaddeeaab6e":
      "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  },
  rinkeby: {
    // SMT
    "0x199eeb1de08fcc9371249d88b710369d0c6ff255":
      "0xb17548c7b510427baac4e267bea62e800b247173",
    // WBTC
    "0x2370694665fecc03c86693e9a03b6874e9321372":
      "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    // WETH
    "0xc778417e063141139fce010982780140aa0cd5ab":
      "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    // Native token uses WETH price
    [NATIVE_TOKEN_ADDRESS]: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    // DAI
    "0x98e06323f0008dd8990229c3ff299353b69491c0":
      "0x6b175474e89094c44da98b954eedeac495271d0f",
    // USDC
    "0x775badfdcc4478ba25d55f076b781fd66cdaf408":
      "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  },
  mumbai: {
    // SMT
    "0x20e237b96f4be16e660a943a42516123065f3d65":
      "0xe631dabef60c37a37d70d3b4f812871df663226f",
    // WBTC
    "0x37d45f6f8fe9e326654115eabf6214031345bbd2":
      "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    // WETH
    "0x10d74c806604a074b48388c92e6de0bd211842de":
      "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    // Native token uses WETH price
    [NATIVE_TOKEN_ADDRESS]: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    // DAI
    "0x40ff8ecf26b645bddde0ea55fc92ba9f9795d2ef":
      "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
    // USDC
    "0x5fd6a096a23e95692e37ec7583011863a63214aa":
      "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
  },
};
