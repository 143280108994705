import type { BigSource } from "@/types/web3";
import { ZERO } from "@/constants/one-time-values";
import { getNormalizedDecimalsNumber } from "@/utils/getNormalizedDecimalsNumber";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";

import { getProtocolFee } from "./getProtocolFee";

export function getTokenInAmountProtocolFee(
  tokenIn: SwapToken | NativeSwapToken | undefined,
  tokenInAmount: string,
  swapDetails: { totalSwapFeeAmount: BigSource } | undefined,
  swapFeeProtocolFeePercentage: number,
  tokenAmountMinimumProtocolFeePercentage: number
) {
  if (
    tokenIn === undefined ||
    tokenInAmount === "" ||
    swapDetails === undefined
  ) {
    return ZERO;
  }

  const normalizedAmountIn = getNormalizedDecimalsNumber(
    tokenInAmount,
    tokenIn.decimals
  );

  return getProtocolFee(
    normalizedAmountIn,
    swapDetails.totalSwapFeeAmount,
    swapFeeProtocolFeePercentage,
    tokenAmountMinimumProtocolFeePercentage
  );
}
