import Big from 'big.js'

import { PoolTokenModel } from '../types/pool-token.model'
import big, { scale } from '../utils/big'
import { Pool } from './pool'

export class PoolToken {
  private id: string

  private readonly decimals: number

  public readonly balance: Big

  public readonly denormWeight: Big

  public readonly pool: Pool | undefined

  constructor(_poolToken: PoolTokenModel) {
    this.id = _poolToken.id
    this.decimals = Number(_poolToken?.decimals || 0)
    this.balance = scale(_poolToken?.balance || 0, this.decimals)
    this.denormWeight = big(_poolToken?.denormWeight)
    this.pool = _poolToken?.poolId && new Pool(_poolToken.poolId)
  }

  get xTokenId(): string {
    return this.id.split('-')[1]
  }
}
