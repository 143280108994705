import React from "react";

import { useStore } from "@/state";

import type { Configuration } from "../types";
import { selectConfiguration, selectSetConfiguration } from "../selectors";

interface ReturnValue {
  configuration: Configuration;
  setConfiguration: (configuration: Configuration) => void;
}

export function useConfiguration(): ReturnValue {
  const configuration = useStore(selectConfiguration);
  const setConfiguration = useStore(selectSetConfiguration);

  const value = React.useMemo<ReturnValue>(() => {
    return {
      configuration,
      setConfiguration,
    };
  }, [configuration, setConfiguration]);

  return value;
}
