import type { BaseResponse } from "../types";
import { makeApiFetchRequest } from "../makeApiFetchRequest";
import { useApiFetchRequest } from "../useApiFetchRequest";

type Tier = "tier0" | "tier1" | "tier2" | "tier90" | "tier99" | "admin";

interface TierAttributes {
  tier: Tier;
}

export async function getTier(address: string): Promise<Tier> {
  const response = await makeApiFetchRequest<BaseResponse<TierAttributes>>(
    `/addresses/${address}/tier`,
    {
      method: "GET",
      isAuthorizationRequired: false,
    }
  );
  return response.attributes.tier;
}

export function useTier(address: string | null) {
  return useApiFetchRequest(
    address !== null ? ["useTier", address] : null,
    getTier
  );
}
