import React from "react";

interface Props {}

export const AboutIcon: React.FC<Props> = (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      style={{ height: "100%" }}
    >
      <path
        d="M9.997 5.863a1.013 1.013 0 1 0 .009 2.024 1.013 1.013 0 0 0-.01-2.024Z"
        fill="currentColor"
      />
      <path
        d="M10 17.5A7.5 7.5 0 0 1 2.5 10 7.5 7.5 0 0 1 10 2.5a7.5 7.5 0 0 1 7.5 7.5 7.5 7.5 0 0 1-7.5 7.5ZM10 10v4.167"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="square"
      />
    </svg>
  );
};
