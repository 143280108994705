import React from "react";
import useSWR from "swr";

import { CONFIG } from "@/constants/config";

type Manifest = Array<{ symbol: string }>;

interface ReturnValue {
  manifest: Manifest | undefined;
  error: Error | undefined;
}

const fetcher = (...args: Parameters<typeof fetch>) => {
  return fetch(...args).then((res) => {
    return res.json();
  });
};

export function useIconsCdnManifest(): ReturnValue {
  const { data, error } = useSWR<Manifest, Error>(
    `${CONFIG.RESOURCES.ICONS_CDN_URL}/manifest.json`,
    fetcher
  );

  const value = React.useMemo<ReturnValue>(() => {
    return {
      manifest: data,
      error,
    };
  }, [data, error]);

  return value;
}
