import React from "react";

import { useWallet } from "@/contexts/WalletContext";
import { getExplorerTransactionUrl } from "@/utils/getExplorerTransactionUrl";

export interface FeedbackItemPayload {
  title: React.ReactNode;
  transactionHash?: string;
  hadSuccess: boolean;
}

interface FeedbackItem {
  title: React.ReactNode;
  explorerUrl?: string;
  hadSuccess: boolean;
}

type ShowFeedback = (feedbackItem: FeedbackItemPayload) => void;

type HideFeedback = () => void;

interface ReturnValue {
  feedbackItem: FeedbackItem | undefined;
  showFeedback: ShowFeedback;
  hideFeedback: HideFeedback;
}

export function useSwapTransactionFeedback(): ReturnValue {
  const { connectedChain } = useWallet();

  const [feedbackItem, setFeedbackItem] = React.useState<
    FeedbackItem | undefined
  >(undefined);

  const showFeedback = React.useCallback<ShowFeedback>(
    (payload) => {
      if (connectedChain === null) {
        return;
      }

      setFeedbackItem({
        title: payload.title,
        explorerUrl:
          payload.transactionHash !== undefined
            ? getExplorerTransactionUrl(
                connectedChain.key,
                payload.transactionHash
              )
            : undefined,
        hadSuccess: payload.hadSuccess,
      });
    },
    [connectedChain]
  );

  const hideFeedback = React.useCallback<HideFeedback>(() => {
    setFeedbackItem(undefined);
  }, []);

  const value = React.useMemo<ReturnValue>(() => {
    return { feedbackItem, showFeedback, hideFeedback };
  }, [feedbackItem, showFeedback, hideFeedback]);

  return value;
}
