import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

const thumbStyles = {
  width: "35px",
  height: "28px",
  margin: 3,
  borderRadius: "2px",
};

const StyledSwitch = styled(Switch)(({ theme }) => {
  return {
    width: 78,
    height: 36,
    padding: 0,
    border: theme.custom.borders.lightgray,
    borderRadius: "4px",

    "&:focus-within": {
      borderColor: theme.custom.colors.primary,
    },

    "& .MuiSwitch-switchBase": {
      padding: 0,

      "&:hover": {
        background: "none",
      },

      "&.Mui-checked": {
        ...thumbStyles,
        backgroundColor: theme.custom.colors.primary,
        transform: "translateX(34px)",

        "& + .MuiSwitch-track": {
          backgroundColor: "transparent",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      ...thumbStyles,
      backgroundColor: theme.custom.colors.gray,
      "&::after": {
        content: "'Off'",
        position: "absolute",
        top: "5px",
        left: "8px",
        fontFamily: theme.typography.fontFamily,
        fontSize: "1rem",
        fontWeight: 500,
        color: theme.custom.colors.white,
      },
    },
    "& .MuiSwitch-track": {
      backgroundColor: "transparent",
      "&::after": {
        content: "'Off'",
        position: "absolute",
        top: "5px",
        left: "8px",
        fontFamily: theme.typography.fontFamily,
        fontSize: "1rem",
        fontWeight: 500,
        color: theme.custom.colors.gray,
      },
      "&::before": {
        content: "'On'",
        position: "absolute",
        top: "5px",
        right: "8px",
        fontFamily: theme.typography.fontFamily,
        fontSize: "1rem",
        fontWeight: 500,
        color: theme.custom.colors.gray,
      },
    },
  };
});

interface Props {
  ariaLabel: string;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
}

export const SwapFormOnOffSwitch: React.FC<Props> = (props) => {
  const { ariaLabel, isChecked, setIsChecked } = props;

  return (
    <StyledSwitch
      checkedIcon={
        <Typography
          component="span"
          sx={(theme) => {
            return { color: theme.custom.colors.white };
          }}
        >
          On
        </Typography>
      }
      inputProps={{ "aria-label": ariaLabel }}
      checked={isChecked}
      onChange={(event) => {
        setIsChecked(event.target.checked);
      }}
    />
  );
};
