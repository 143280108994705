import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { SwapTxType } from "@swarmmarkets/smart-order-router";
import CPK, { TransactionResult } from "contract-proxy-kit";

import type { Address, BigNumber, Signer } from "@/types/web3";
import { Chain } from "@/constants/chains";
import {
  approveErc20,
  makeMultihopBatchSwapExactIn,
  makeMultihopBatchSwapExactOut,
} from "@/contracts";
import { useTwoStepTransactionFlag } from "@/hooks/useTwoStepTransactionFlag";
import { useToggle } from "@/hooks/useToggle";
import { getBigNumber } from "@/utils/getBigNumber";
import { getDenormalizedDecimalsNumber } from "@/utils/getDenormalizedDecimalsNumber";
import type { NativeSwapToken, SwapToken, Tier } from "@/pages/Swap/types";
import { Flexbox } from "@/components/Flexbox";
import { useDialog } from "@/components/Dialog";
import { Configuration } from "@/state/configuration";
import { captureException } from "@/services/sentry";

import { SwapPriceChange } from "../SwapPriceChange";

import type { SwapTransactionResult } from "./types";
import { getSwapRouter } from "./utils/getSwapRouter";
import { getSwapDetails } from "./utils/getSwapDetails";
import { getSwapMaxTokenInAmount } from "./utils/getSwapMaxTokenInAmount";
import { getTokenInAmountProtocolFee } from "./utils/getTokenInAmountProtocolFee";
import { matchHasCpkLeftovers } from "./utils/matchHasCpkLeftovers";
import { useSwarmProtocolFee } from "./hooks/useSwarmProtocolFee";
import { useSwapValues } from "./hooks/useSwapValues";
import { useSwapSettings } from "./hooks/useSwapSettings";
import { useSwapReverse } from "./hooks/useSwapReverse";
import { useSwapSmtProtocolFee } from "./hooks/useSwapSmtProtocolFee";
import { useWrapNativeToken } from "./hooks/useWrapNativeToken";
import { useUnwrapNativeToken } from "./hooks/useUnwrapNativeToken";
import { useSecurityRestriction } from "./hooks/useSecurityRestriction";
import { getSwapEthersErrorTitle } from "./utils/getSwapEthersErrorTitle";
import { SwarpFormHeader } from "./components/SwapFormHeader";
import { SwapFormButton } from "./components/SwapFormButton";
import { SwapFormTokenInput } from "./components/SwapFormTokenInput";
import { SwapFormReverseButton } from "./components/SwapFormReverseButton";
import { SwapFormPriceSummary } from "./components/SwapFormPriceSummary";
import { SwapFormTransactionDetails } from "./components/SwapFormTransactionDetails";
import { SwapFormOptimizeForSelector } from "./components/SwapFormOptimizeForSelector";
import { SwapFormCpkLeftoversDialog } from "./components/SwapFormCpkLeftoversDialog";
import { SwapFormCpkDeploymentCostDialog } from "./components/SwapFormCpkDeploymentCostDialog";
import { SwapFormSecurityRestriction } from "./components/SwapFormSecurityRestriction";
import { SwapRecentTransactions } from "../SwapRecentTransactions";

interface Props {
  swapTokens: (SwapToken | NativeSwapToken)[] | undefined;
  cpk: CPK | null;
  connectedAddress: Address | null;
  connectedChain: Chain | null;
  connectedWalletSigner: Signer | null;
  tier: Tier | undefined;
  configuration: Configuration;
  connectWallet: () => void;
  revalidateTier: () => void;
  showFeedback: (feedbackItem: SwapTransactionResult) => void;
}

export const SwapForm: React.FC<Props> = (props) => {
  const {
    swapTokens,
    cpk,
    connectedAddress,
    connectedChain,
    connectedWalletSigner,
    tier,
    configuration,
    connectWallet,
    revalidateTier,
    showFeedback,
  } = props;

  const { isOn: isShowingRecentSwaps, toggle: toggleIsShowingRecentSwaps } =
    useToggle();

  const {
    swapFeeProtocolFeePercentage,
    tokenAmountMinimumProtocolFeePercentage,
  } = useSwarmProtocolFee(connectedChain);

  const {
    tokenIn,
    tokenOut,
    tokenInAmount,
    tokenOutAmount,
    maxTokenInAmount,
    setTokenInAddress,
    setTokenOutAddress,
    setTokenInAmount,
    setTokenOutAmount,
  } = useSwapValues(swapTokens !== undefined ? swapTokens : []);

  const {
    wrapState,
    maxNativeTokenInAmount,
    isWrappingNativeToken,
    wrapNativeToken,
  } = useWrapNativeToken(
    tokenIn,
    tokenOut,
    maxTokenInAmount,
    connectedChain,
    connectedWalletSigner,
    setTokenOutAddress
  );

  const {
    unwrapState,
    maxWrappedNativeTokenInAmount,
    isUnwrappingNativeToken,
    unwrapNativeToken,
  } = useUnwrapNativeToken(
    tokenIn,
    tokenOut,
    maxTokenInAmount,
    connectedChain,
    connectedWalletSigner,
    setTokenInAddress
  );

  const {
    minimumEurAmountSecurityRestriction,
    isSecurityRestrictionCompliant,
  } = useSecurityRestriction(tokenOut, tokenOutAmount);

  const {
    swapType,
    swapSlippageTolerance,
    swapOptimizationStrategy,
    isSmtFeeDiscountEnabled,
    isSmtFeeDiscountAvailable,
    isSmtFeeDiscountApplied,
    setSwapType,
    setIsSmtFeeDiscountEnabled,
    setSwapOptimizationStrategy,
  } = useSwapSettings(
    connectedChain,
    tokenIn,
    isWrappingNativeToken,
    isUnwrappingNativeToken
  );

  const { reverseSwap } = useSwapReverse(
    swapType,
    tokenIn,
    tokenOut,
    tokenInAmount,
    tokenOutAmount,
    setSwapType,
    setTokenInAddress,
    setTokenOutAddress,
    setTokenInAmount,
    setTokenOutAmount
  );

  const {
    state: swapState,
    startConfirmation: startSwapConfirmation,
    startExecution: startSwapExecution,
    stop: stopSwap,
  } = useTwoStepTransactionFlag();

  const {
    state: approveState,
    startConfirmation: startApproveConfirmation,
    startExecution: startApproveExecution,
    stop: stopApprove,
  } = useTwoStepTransactionFlag();

  const { swapRouter, swapRouterError } = React.useMemo(() => {
    if (isWrappingNativeToken || isUnwrappingNativeToken) {
      return { swapRouter: undefined, swapRouterError: undefined };
    }

    return getSwapRouter(tokenIn, tokenOut);
  }, [tokenIn, tokenOut, isWrappingNativeToken, isUnwrappingNativeToken]);

  const { swapDetails, swapDetailsError } = React.useMemo(() => {
    return getSwapDetails(
      swapRouter,
      swapType,
      swapOptimizationStrategy,
      tokenIn,
      tokenOut,
      tokenInAmount,
      tokenOutAmount
    );
  }, [
    swapRouter,
    swapType,
    swapOptimizationStrategy,
    tokenIn,
    tokenOut,
    tokenInAmount,
    tokenOutAmount,
  ]);

  const swapMaxTokenInAmount = React.useMemo(() => {
    return getSwapMaxTokenInAmount(
      tokenIn,
      maxTokenInAmount,
      swapRouter,
      swapFeeProtocolFeePercentage,
      tokenAmountMinimumProtocolFeePercentage,
      isSmtFeeDiscountApplied
    );
  }, [
    tokenIn,
    maxTokenInAmount,
    swapRouter,
    swapFeeProtocolFeePercentage,
    tokenAmountMinimumProtocolFeePercentage,
    isSmtFeeDiscountApplied,
  ]);

  const protocolFee = React.useMemo<BigNumber>(() => {
    return getTokenInAmountProtocolFee(
      tokenIn,
      tokenInAmount,
      swapDetails,
      swapFeeProtocolFeePercentage,
      tokenAmountMinimumProtocolFeePercentage
    );
  }, [
    tokenInAmount,
    tokenIn,
    swapDetails,
    swapFeeProtocolFeePercentage,
    tokenAmountMinimumProtocolFeePercentage,
  ]);

  const { smtProtocolFee, smtDecimals, hasEnoughSmtToPaySmtProtocolFee } =
    useSwapSmtProtocolFee(
      connectedAddress,
      connectedChain,
      tokenIn,
      protocolFee
    );

  React.useEffect(() => {
    if (isSmtFeeDiscountApplied && hasEnoughSmtToPaySmtProtocolFee === false) {
      setIsSmtFeeDiscountEnabled(false);
    }
  }, [
    isSmtFeeDiscountApplied,
    hasEnoughSmtToPaySmtProtocolFee,
    setIsSmtFeeDiscountEnabled,
  ]);

  const swapProtocolFee =
    isSmtFeeDiscountApplied && smtProtocolFee !== undefined
      ? smtProtocolFee
      : protocolFee;

  const approve = async (
    tokenAddress: Address
  ): Promise<SwapTransactionResult> => {
    if (
      cpk === null ||
      cpk.address === undefined ||
      connectedWalletSigner === null
    ) {
      return {
        title: "There was an issue. Please try again.",
        hadSuccess: false,
      };
    }

    let transactionHash: string | undefined = undefined;

    startApproveConfirmation();
    try {
      const transaction = await approveErc20(
        tokenAddress,
        cpk.address,
        connectedWalletSigner
      );

      transactionHash = transaction.hash;

      startApproveExecution();

      await transaction.wait();

      return {
        title: "Approve successful.",
        transactionHash,
        hadSuccess: true,
      };
    } catch (error) {
      captureException(error as Error);

      return {
        title: getSwapEthersErrorTitle(error as Error),
        transactionHash,
        hadSuccess: false,
      };
    } finally {
      stopApprove();
    }
  };

  const makeSwap = async (): Promise<SwapTransactionResult> => {
    if (
      connectedAddress === null ||
      connectedChain === null ||
      cpk === null ||
      tokenIn === undefined ||
      tokenOut === undefined ||
      tokenIn.xToken === undefined ||
      tokenOut.xToken === undefined ||
      swapDetails === undefined
    ) {
      return {
        title: "There was an issue. Please try again.",
        hadSuccess: false,
      };
    }

    let transactionHash: string | undefined = undefined;

    startSwapConfirmation();
    try {
      const minAmountOut = getBigNumber(tokenOutAmount).mul(
        1 - swapSlippageTolerance / 100
      );

      const maxAmountIn = getBigNumber(tokenInAmount).mul(
        1 + swapSlippageTolerance / 100
      );

      const getTransaction = (): Promise<TransactionResult> => {
        if (swapType === SwapTxType.exactIn) {
          return makeMultihopBatchSwapExactIn(
            cpk,
            connectedAddress,
            connectedChain,
            tokenIn,
            tokenOut,
            swapDetails.swaps,
            minAmountOut,
            swapProtocolFee,
            isSmtFeeDiscountApplied
          );
        }

        return makeMultihopBatchSwapExactOut(
          cpk,
          connectedAddress,
          connectedChain,
          tokenIn,
          tokenOut,
          swapDetails.swaps,
          maxAmountIn,
          swapProtocolFee.mul(1 + swapSlippageTolerance / 100),
          isSmtFeeDiscountApplied
        );
      };

      const transaction = await getTransaction();

      if (transaction.transactionResponse === undefined) {
        return {
          title: "Error creating transaction.",
          hadSuccess: false,
        };
      }

      transactionHash = transaction.hash;

      startSwapExecution();

      await transaction.transactionResponse.wait();

      return {
        title: "Swap successful.",
        transactionHash,
        hadSuccess: true,
      };
    } catch (error) {
      captureException(error as Error);

      return {
        title: getSwapEthersErrorTitle(error as Error),
        transactionHash,
        hadSuccess: false,
      };
    } finally {
      stopSwap();
    }
  };

  const curriedSetTokenInAmount = React.useCallback<(amount: string) => void>(
    (amount) => {
      setSwapType(SwapTxType.exactIn);
      setTokenInAmount(amount);

      // Propagate emptyness to the token out input as well
      if (amount === "") {
        setTokenOutAmount("");
        return;
      }
      if (getBigNumber(amount).eq(0)) {
        setTokenOutAmount("0");
        return;
      }

      // Propagate 1:1 exchange rate while wrapping or unwrapping native token
      if (isWrappingNativeToken || isUnwrappingNativeToken) {
        setTokenOutAmount(amount);
        return;
      }
    },
    [
      setSwapType,
      setTokenInAmount,
      setTokenOutAmount,
      isWrappingNativeToken,
      isUnwrappingNativeToken,
    ]
  );

  const curriedSetTokenOutAmount = React.useCallback<(amount: string) => void>(
    (amount) => {
      setSwapType(SwapTxType.exactOut);
      setTokenOutAmount(amount);

      // Propagate emptyness to the token in input as well
      if (amount === "") {
        setTokenInAmount("");
        return;
      }
      if (getBigNumber(amount).eq(0)) {
        setTokenInAmount("0");
        return;
      }

      // Propagate 1:1 exchange rate while wrapping or unwrapping native token
      if (isWrappingNativeToken || isUnwrappingNativeToken) {
        setTokenInAmount(amount);
        return;
      }
    },
    [
      setSwapType,
      setTokenInAmount,
      setTokenOutAmount,
      isWrappingNativeToken,
      isUnwrappingNativeToken,
    ]
  );

  // This effect listens to changes on the swap details and propagates the equivalent amounts
  // to the opposite token input so when you write on one input or another the other one is updated
  React.useEffect(() => {
    if (
      swapDetails === undefined ||
      tokenIn === undefined ||
      tokenOut === undefined
    ) {
      return;
    }

    if (swapType === SwapTxType.exactOut) {
      setTokenInAmount(
        getDenormalizedDecimalsNumber(
          swapDetails.totalReturn,
          tokenIn.decimals
        ).toString()
      );
    }
    if (swapType === SwapTxType.exactIn) {
      setTokenOutAmount(
        getDenormalizedDecimalsNumber(
          swapDetails.totalReturn,
          tokenOut.decimals
        ).toString()
      );
    }
  }, [
    swapType,
    swapDetails,
    tokenIn,
    tokenOut,
    setTokenInAmount,
    setTokenOutAmount,
  ]);

  const tokenInSymbol = tokenIn !== undefined ? tokenIn.symbol : undefined;

  const maxTokenInAmountApplied = isWrappingNativeToken
    ? maxNativeTokenInAmount
    : isUnwrappingNativeToken
    ? maxWrappedNativeTokenInAmount
    : swapMaxTokenInAmount;

  const cpkDeploymentCostDialogProps = useDialog();
  const cpkLeftoversDialogProps = useDialog();

  const hasCpkLeftovers = matchHasCpkLeftovers(tokenIn, tokenOut);

  return (
    <React.Fragment>
      <Flexbox
        direction="column"
        justifyContent="start"
        alignItems="start"
        sx={(theme) => {
          return {
            width: "100%",
            backgroundColor: theme.custom.colors.white,
            borderRadius: "8px",
            padding: "8px",
          };
        }}
      >
        <SwarpFormHeader
          connectedChain={connectedChain}
          toggleRecentSwapsVisibility={toggleIsShowingRecentSwaps}
        />

        <Box sx={{ height: "24px" }} />

        <SwapFormTokenInput
          selectedToken={tokenIn}
          tokens={swapTokens}
          value={tokenInAmount}
          maxAmount={maxTokenInAmountApplied}
          placeholder="Amount to swap"
          hasSetMaxButton={true}
          setValue={curriedSetTokenInAmount}
          setSelectedTokenAddress={setTokenInAddress}
        />

        <Box sx={{ height: "6px" }} />

        <SwapFormReverseButton onClick={reverseSwap} />

        <Box sx={{ height: "4px" }} />

        <SwapFormTokenInput
          selectedToken={tokenOut}
          tokens={swapTokens}
          value={tokenOutAmount}
          placeholder="Amount to receive"
          setValue={curriedSetTokenOutAmount}
          setSelectedTokenAddress={setTokenOutAddress}
        />

        {isSecurityRestrictionCompliant === false && tokenOut !== undefined ? (
          <React.Fragment>
            <Box sx={{ height: "26px" }} />

            <SwapFormSecurityRestriction
              symbol={tokenOut.symbol}
              minAmount={minimumEurAmountSecurityRestriction}
            />

            <Box sx={{ height: "8px" }} />
          </React.Fragment>
        ) : (
          <Box sx={{ height: "24px" }} />
        )}

        <SwapFormPriceSummary
          tokenASymbol={tokenIn !== undefined ? tokenIn.symbol : undefined}
          tokenBSymbol={tokenOut !== undefined ? tokenOut.symbol : undefined}
          swapUsdPrice={
            swapDetails !== undefined
              ? swapDetails.swapPrice.toString()
              : isWrappingNativeToken || isUnwrappingNativeToken
              ? "1"
              : undefined
          }
        />

        <Box sx={{ height: "16px" }} />

        <SwapFormOptimizeForSelector
          swapOptimizationStrategy={swapOptimizationStrategy}
          swapStrategiesDiff={
            swapDetails !== undefined ? swapDetails.swapStrategiesDiff : null
          }
          swapType={swapType}
          tokenIn={tokenIn}
          tokenOut={tokenOut}
          setSwapOptimizationStrategy={setSwapOptimizationStrategy}
        />

        <Box sx={{ height: "24px" }} />

        <SwapFormButton
          cpk={cpk}
          connectedAddress={connectedAddress}
          connectedChain={connectedChain}
          tier={tier}
          preOnboardingContent={configuration.preOnboardingContent}
          tokenIn={tokenIn}
          tokenInAmount={tokenInAmount}
          maxTokenInAmount={maxTokenInAmountApplied}
          smtProtocolFee={
            smtProtocolFee !== undefined ? smtProtocolFee.toString() : undefined
          }
          approveState={approveState}
          swapState={swapState}
          wrapState={wrapState}
          unwrapState={unwrapState}
          swapRouterError={swapRouterError}
          swapDetailsError={swapDetailsError}
          isSmtFeeDiscountApplied={isSmtFeeDiscountApplied}
          isWrappingNativeToken={isWrappingNativeToken}
          isUnwrappingNativeToken={isUnwrappingNativeToken}
          isSecurityRestrictionCompliant={isSecurityRestrictionCompliant}
          connectWallet={connectWallet}
          revalidateTier={revalidateTier}
          approve={async (tokenAddress) => {
            const result = await approve(tokenAddress);
            showFeedback(result);
          }}
          makeSwap={async () => {
            if (isWrappingNativeToken) {
              const result = await wrapNativeToken(tokenInAmount);
              showFeedback(result);
              return;
            }
            if (isUnwrappingNativeToken) {
              const result = await unwrapNativeToken(tokenInAmount);
              showFeedback(result);
              return;
            }

            const isCpkDeployed = cpk !== null && (await cpk.isProxyDeployed());
            if (isCpkDeployed === false) {
              cpkDeploymentCostDialogProps.open();
              return;
            }

            if (hasCpkLeftovers) {
              cpkLeftoversDialogProps.open();
              return;
            }

            const result = await makeSwap();
            showFeedback(result);
          }}
        />

        <Box sx={{ height: "24px" }} />

        <SwapFormTransactionDetails
          swapFee={
            swapDetails !== undefined && tokenIn !== undefined
              ? getDenormalizedDecimalsNumber(
                  swapDetails.totalSwapFeeAmount,
                  tokenIn.decimals
                ).toString()
              : isWrappingNativeToken || isUnwrappingNativeToken
              ? "0"
              : undefined
          }
          swapFeeTokenSymbol={tokenInSymbol}
          protocolFee={
            tokenIn !== undefined
              ? getDenormalizedDecimalsNumber(
                  swapProtocolFee,
                  isSmtFeeDiscountApplied && smtDecimals !== undefined
                    ? smtDecimals
                    : tokenIn.decimals
                ).toString()
              : undefined
          }
          protocolFeeSymbol={isSmtFeeDiscountApplied ? "SMT" : tokenInSymbol}
          isSmtFeeDiscountEnabled={isSmtFeeDiscountEnabled}
          isSmtFeeDiscountAvailable={isSmtFeeDiscountAvailable}
          setIsSmtFeeDiscountEnabled={setIsSmtFeeDiscountEnabled}
        />

        <SwapFormCpkDeploymentCostDialog
          isOpen={cpkDeploymentCostDialogProps.isOpen}
          confirm={async () => {
            cpkDeploymentCostDialogProps.close();
            const result = await makeSwap();
            showFeedback(result);
          }}
          close={cpkDeploymentCostDialogProps.close}
        />

        <SwapFormCpkLeftoversDialog
          tokenIn={tokenIn}
          tokenOut={tokenOut}
          isOpen={cpkLeftoversDialogProps.isOpen}
          confirm={async () => {
            cpkLeftoversDialogProps.close();
            const result = await makeSwap();
            showFeedback(result);
          }}
          close={cpkLeftoversDialogProps.close}
        />
      </Flexbox>

      <Collapse in={isShowingRecentSwaps}>
        {tokenIn !== undefined && tokenOut !== undefined ? (
          <React.Fragment>
            <Box sx={{ height: "16px" }} />
            <SwapPriceChange tokenIn={tokenIn} tokenOut={tokenOut} />
          </React.Fragment>
        ) : null}

        <Box sx={{ height: "16px" }} />

        <SwapRecentTransactions tokenIn={tokenIn} tokenOut={tokenOut} />
      </Collapse>
    </React.Fragment>
  );
};
