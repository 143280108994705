import type { GasPrice, Network } from "../types";

import { getEthereumGasPrice } from "./getEthereumGasPrice";
import { getPolygonGasPrice } from "./getPolygonGasPrice";

export function getNetworkGasPrice(network: Network): Promise<GasPrice> {
  if (network === "ethereum" || network === "rinkeby") {
    return getEthereumGasPrice(network);
  }

  if (network === "polygon" || network === "mumbai") {
    return getPolygonGasPrice(network);
  }

  throw new Error(`Not supported network: ${network}`);
}
