import React from "react";
import { useConnectWallet, useSetChain, useWallets } from "@web3-onboard/react";
import * as Sentry from "@sentry/react";
import { ethers } from "ethers";

import { Chain } from "@/constants/chains";
import { IS_PRODUCTION } from "@/constants/environment";
import { Address, Provider, Signer } from "@/types/web3";
import { useLastConnectedWalletLabel } from "@/state/wallet";
import { useConfiguration } from "@/state/configuration";
import { NonSupportedChain } from "@/components/NonSupportedChain";
import { captureException } from "@/services/sentry";

type Connect = () => void;

type ConnectWithOptions = (options: {
  autoSelect?: {
    label: string;
    disableModals: boolean;
  };
}) => void;

type Disconnect = () => void;

type SetChain = (options: { chainId: Chain["id"] }) => Promise<boolean>;

interface Context {
  connectedAddress: Address | null;
  connectedWalletProvider: Provider | null;
  connectedWalletSigner: Signer | null;
  connectedWallets: ReturnType<typeof useWallets>;
  connectedChain: Chain | null;
  chains: Chain[];
  isConnecting: boolean;
  isSettingChain: boolean;
  connect: Connect;
  connectWithOptions: ConnectWithOptions;
  disconnect: Disconnect;
  setChain: SetChain;
}

export const WalletContext = React.createContext<Context>({
  connectedAddress: null,
  connectedWalletProvider: null,
  connectedWalletSigner: null,
  connectedWallets: [],
  connectedChain: null,
  chains: [],
  isConnecting: false,
  isSettingChain: false,
  connect: () => {
    captureException(new Error("Can't call connect outside of WalletContext"));
  },
  connectWithOptions: () => {
    captureException(
      new Error("Can't call connectWithOptions outside of WalletContext")
    );
  },
  disconnect: () => {
    captureException(
      new Error("Can't call disconnect outside of WalletContext")
    );
  },
  setChain: async () => {
    captureException(new Error("Can't call setChain outside of WalletContext"));
    return false;
  },
});

interface WalletProviderProps {
  children: React.ReactNode;
}

export const WalletContextProvider: React.FC<WalletProviderProps> = (props) => {
  const { children } = props;

  const { configuration } = useConfiguration();

  const supportedChains = configuration.supportedChains;

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [{ connectedChain: connectedChainReference, settingChain }, setChain] =
    useSetChain();
  const connectedWallets = useWallets();

  const {
    lastConnectedWalletLabel,
    setLastConnectedWalletLabel,
    clearLastConnectedWalletLabel,
  } = useLastConnectedWalletLabel();

  React.useEffect(() => {
    if (lastConnectedWalletLabel === null || wallet !== null) {
      return;
    }

    connect({
      autoSelect: { label: lastConnectedWalletLabel, disableModals: true },
    });
  }, [lastConnectedWalletLabel, wallet, connect]);

  React.useEffect(() => {
    if (wallet === null) {
      return;
    }
    setLastConnectedWalletLabel(wallet.label);
  }, [wallet, setLastConnectedWalletLabel]);

  const curriedConnect = React.useCallback<Connect>(() => {
    connect();
  }, [connect]);

  const connectWithOptions = React.useCallback<ConnectWithOptions>(
    (options) => {
      connect(options);
    },
    [connect]
  );

  const curriedDisconnect = React.useCallback<Disconnect>(() => {
    if (wallet === null) {
      return;
    }

    clearLastConnectedWalletLabel();
    disconnect({ label: wallet.label });
  }, [wallet, clearLastConnectedWalletLabel, disconnect]);

  const curriedSetChain = React.useCallback<SetChain>(
    async (options) => {
      const hasSuccess = await setChain(options);

      if (hasSuccess === false) {
        return false;
      }

      return true;
    },
    [setChain]
  );

  const value = React.useMemo<Context>(() => {
    let connectedAddress: Address | null = null;
    if (
      connectedWallets[0] !== undefined &&
      connectedWallets[0].accounts[0] !== undefined
    ) {
      connectedAddress = connectedWallets[0].accounts[0].address;
    }

    let connectedWalletProvider: Provider | null = null;
    let connectedWalletSigner: Signer | null = null;
    if (wallet !== null) {
      connectedWalletProvider = new ethers.providers.Web3Provider(
        wallet.provider
      );
      connectedWalletSigner = connectedWalletProvider.getSigner();
    }

    let connectedChain: Chain | null = null;
    if (connectedChainReference !== null) {
      const connectedChainData = supportedChains.find((chain) => {
        return chain.id === connectedChainReference.id;
      });

      if (connectedChainData !== undefined) {
        connectedChain = connectedChainData;
      }
    }

    return {
      connectedAddress,
      connectedWalletProvider,
      connectedWalletSigner,
      connectedWallets,
      connectedChain,
      chains: supportedChains,
      isConnecting: connecting,
      isSettingChain: settingChain,
      connect: curriedConnect,
      connectWithOptions,
      disconnect: curriedDisconnect,
      setChain: curriedSetChain,
    };
  }, [
    wallet,
    connectedWallets,
    connectedChainReference,
    supportedChains,
    connecting,
    settingChain,
    curriedConnect,
    connectWithOptions,
    curriedDisconnect,
    curriedSetChain,
  ]);

  React.useEffect(() => {
    if (value.connectedChain === null) {
      return;
    }

    if (IS_PRODUCTION) {
      Sentry.setContext("network", {
        chainId: value.connectedChain.id,
        name: value.connectedChain.label,
      });
    }
  }, [value.connectedChain]);

  const isConnectedChainReferenceNotSupported =
    connectedChainReference !== null &&
    supportedChains.some((chain) => {
      return chain.id === connectedChainReference.id;
    }) === false;

  return (
    <WalletContext.Provider value={value}>
      {isConnectedChainReferenceNotSupported ? (
        <NonSupportedChain chains={value.chains} setChain={value.setChain} />
      ) : (
        children
      )}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  return React.useContext(WalletContext);
};
