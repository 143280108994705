import React from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { Flexbox } from "@/components/Flexbox";
import { Balance } from "@/components/Balance";
import type { Swap } from "@/pages/Swap/types";

const TimeFromTimestamp = React.lazy(() => {
  return import("@/components/TimeFromTimestamp").then((mod) => {
    return { default: mod.TimeFromTimestamp };
  });
});

interface Props {
  swap: Swap;
  openDetails: () => void;
}

export const SwapRecentTransactionsRow: React.FC<Props> = (props) => {
  const { swap, openDetails } = props;

  return (
    <Flexbox
      direction="column"
      justifyContent="start"
      alignItems="start"
      gap={1}
      sx={(theme) => {
        return {
          width: "100%",
          paddingY: "12px",
          borderBottom: theme.custom.borders.lightgray,
        };
      }}
    >
      <Flexbox
        direction="row"
        justifyContent="start"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Button
          variant="text"
          sx={{
            paddingX: 0,
            minWidth: 0,
            fontSize: "0.875rem",
            fontWeight: 400,
            textTransform: "none",
            "&:hover, &:focus": {
              background: "none",
              textDecoration: "underline",
            },
          }}
          onClick={openDetails}
        >
          Details
        </Button>

        <Tooltip
          placement="top"
          title={new Date(swap.timestamp).toLocaleString()}
        >
          <Typography
            component="span"
            sx={(theme) => {
              return {
                flex: 1,
                textAlign: "right",
                marginRight: 1,
                fontSize: "0.875rem",
                fontWeight: 400,
                color: theme.custom.colors.gray,
              };
            }}
          >
            <React.Suspense fallback={null}>
              <TimeFromTimestamp timestamp={swap.timestamp} />
            </React.Suspense>
          </Typography>
        </Tooltip>
      </Flexbox>

      <Flexbox
        direction="row"
        justifyContent="start"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Tooltip
          placement="top"
          title={`${swap.tokenInAmount} ${swap.tokenInSymbol}`}
        >
          <Typography sx={{ flex: 1, paddingLeft: 3, textAlign: "left" }}>
            <Balance value={swap.tokenInAmount} decimals={4} />{" "}
            <b>{swap.tokenInSymbol}</b>
          </Typography>
        </Tooltip>

        <PlayArrowIcon
          sx={(theme) => {
            return {
              flexShrink: 0,
              marginX: 1,
              color: theme.custom.colors.gray,
            };
          }}
        />

        <Tooltip
          placement="top"
          title={`${swap.tokenOutAmount} ${swap.tokenOutSymbol}`}
        >
          <Typography sx={{ flex: 1, paddingRight: 3, textAlign: "right" }}>
            <Balance value={swap.tokenOutAmount} decimals={4} />{" "}
            <b>{swap.tokenOutSymbol}</b>
          </Typography>
        </Tooltip>
      </Flexbox>
    </Flexbox>
  );
};
