import React from "react";
import Blockies from "react-blockies";
import Box from "@mui/material/Box";

import type { Address } from "@/types/web3";
import { getMergedSxProps } from "@/utils/getMergedSxProps";

interface Props extends React.ComponentProps<typeof Box> {
  address: Address;
  size?: number;
  isRounded?: boolean;
}

const SCALE = 4;

export const Avatar: React.FC<Props> = (props) => {
  const {
    address,
    size = 8,
    isRounded = false,
    sx: boxSx,
    ...boxProps
  } = props;

  return (
    <Box
      {...boxProps}
      sx={getMergedSxProps(boxSx, {
        width: size * SCALE,
        height: size * SCALE,
        borderRadius: isRounded ? "50%" : 0,
        overflow: "hidden",
      })}
    >
      <Blockies seed={address} size={size} scale={SCALE} />
    </Box>
  );
};
