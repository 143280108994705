import { gql } from "urql";

import type { ListedTokensQuery, ListedTokensQueryVariables } from "../codegen";

import { makeSubgraphRequest } from "../makeSubgraphRequest";
import { useSubgraphRequest } from "../useSubgraphRequest";

export interface ListedToken {
  name: string;
  symbol: string;
  decimals: number;
  address: string;
  xToken: {
    symbol: string;
    decimals: number;
    paused: boolean;
    address: string;
    poolTokens: Array<{
      decimals: number;
      balance: any;
      denormWeight: any;
      address: string;
      poolId: {
        publicSwap: boolean;
        swapFee: any;
        tokensList: Array<any>;
        totalWeight: any;
        address: string;
        tokens: Array<{
          decimals: number;
          balance: any;
          denormWeight: any;
          address: string;
        }>;
      };
    }>;
  };
}

export async function getListedTokens(
  subgraphUrl: string
): Promise<ListedToken[]> {
  const response = await makeSubgraphRequest(subgraphUrl, query, {
    filter: { symbol_not: "SPT", paused: false },
  });

  return response.tokens.map((token) => {
    let poolTokens =
      token.xToken.poolTokens === undefined || token.xToken.poolTokens === null
        ? []
        : token.xToken.poolTokens.map((poolToken) => {
            const tokens =
              poolToken.poolId.tokens === undefined ||
              poolToken.poolId.tokens === null
                ? []
                : poolToken.poolId.tokens;

            return {
              ...poolToken,
              poolId: {
                ...poolToken.poolId,
                tokens,
              },
            };
          });

    return {
      ...token,
      xToken: {
        ...token.xToken,
        poolTokens,
      },
    };
  });
}

export function useListedTokens(subgraphUrl: string | undefined) {
  return useSubgraphRequest(
    subgraphUrl !== undefined ? ["useListedTokens", subgraphUrl] : null,
    getListedTokens
  );
}

const query = gql<ListedTokensQuery, ListedTokensQueryVariables>`
  query ListedTokens($filter: Token_filter) {
    tokens(where: $filter, orderBy: name) {
      name
      symbol
      address: id
      decimals
      xToken {
        symbol
        address: id
        decimals
        paused
        poolTokens {
          address: id
          decimals
          balance
          denormWeight
          poolId {
            address: id
            publicSwap
            swapFee
            tokensList
            totalWeight
            tokens {
              address: id
              decimals
              balance
              denormWeight
            }
          }
        }
      }
    }
  }
`;
