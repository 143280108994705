import { ErrorResponse, ResponseError } from "../types";

export function matchIsErrorResponse(
  response: ErrorResponse | unknown
): response is ErrorResponse {
  const casted = response as ErrorResponse;
  return (
    casted.errors !== undefined &&
    casted.errors.length > 0 &&
    casted.errors.every(matchIsError)
  );
}

function matchIsError(error: ResponseError | unknown): error is Error {
  const casted = error as ResponseError;
  return (
    Boolean(casted.title) && Boolean(casted.code) && Boolean(casted.status)
  );
}
