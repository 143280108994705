import React from "react";
import { styled } from "@mui/material/styles";
import MUIDialog from "@mui/material/Dialog";
import MUIDialogTitle from "@mui/material/DialogTitle";
import MUIDialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

import { useContainerObserver } from "@/contexts/ContainerObserverContext";
import { getMinContainerWidthCSS } from "@/utils/getContainerWidthCSSMediaQuery";
import { Breakpoint } from "@/components/Breakpoint";
import { Flexbox } from "@/components/Flexbox";

interface Props {
  title: string;
  children: React.ReactNode;
  postTitle?: React.ReactNode;
  isOpen: boolean;
  hasDividers?: boolean;
  close: () => void;
}

export const Dialog: React.FC<Props> = (props) => {
  const {
    title,
    children,
    postTitle,
    isOpen,
    hasDividers = false,
    close,
  } = props;

  const { containerWidth } = useContainerObserver();

  const titleId = `dialog-${title}`;

  const content = (
    <React.Fragment>
      <StyledDialogTitle id={titleId}>
        <Flexbox direction="row" justifyContent="start" alignItems="center">
          <span style={{ flex: 1 }}>{title}</span>
          <IconButton
            sx={(theme) => {
              return {
                flexShrink: 0,
                position: "relative",
                right: "-12px", // visual alignment
                color: theme.custom.colors.gray,
              };
            }}
            onClick={close}
          >
            <CloseIcon />
          </IconButton>
        </Flexbox>

        {postTitle !== undefined ? (
          <Box sx={{ width: "100%", marginTop: 3 }}>{postTitle}</Box>
        ) : null}
      </StyledDialogTitle>

      <MUIDialogContent dividers={hasDividers}>{children}</MUIDialogContent>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Breakpoint until="laptop">
        <StyledDialog
          $containerWidth={containerWidth}
          scroll="paper"
          open={isOpen}
          onClose={close}
          keepMounted={true}
          TransitionComponent={Transition}
          aria-labelledby={titleId}
          container={() => {
            return document.getElementById("dialog-container");
          }}
        >
          {content}
        </StyledDialog>
      </Breakpoint>

      <Breakpoint from="laptop">
        <StyledDialog
          $containerWidth={containerWidth}
          scroll="paper"
          open={isOpen}
          onClose={close}
          aria-labelledby={titleId}
          container={() => {
            return document.getElementById("dialog-container");
          }}
        >
          {content}
        </StyledDialog>
      </Breakpoint>
    </React.Fragment>
  );
};

interface StyledDialogProps {
  $containerWidth: number;
}
const StyledDialog = styled(MUIDialog)<StyledDialogProps>((props) => {
  return {
    "& .MuiDialog-container": {
      alignItems: "flex-end",
      ...getMinContainerWidthCSS(
        props.$containerWidth,
        props.theme.breakpoints.values["laptop"],
        {
          alignItems: "center",
        }
      ),
    },
    "& .MuiDialog-paper": {
      width: "100%",
      height: "90%",
      maxHeight: "90%",
      margin: 0,
      borderRadius: "16px 16px 0 0",
      ...getMinContainerWidthCSS(
        props.$containerWidth,
        props.theme.breakpoints.values["laptop"],
        {
          width: "460px",
          height: "auto",
          maxHeight: "80%",
          borderRadius: "4px",
        }
      ),
    },
    "& .MuiDialogContent-dividers": {
      paddingTop: "16px",
    },
  };
});

const StyledDialogTitle = styled(MUIDialogTitle)({
  fontWeight: 700,
});

interface TransitionProps {
  children: React.ReactElement;
}

const Transition = React.forwardRef<HTMLDivElement, TransitionProps>(
  (props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);
