import invariant from "invariant";
import * as Sentry from "@sentry/react";

import { IS_PRODUCTION } from "@/constants/environment";

export function captureException(
  error: Error,
  context: Record<string, unknown> = {}
) {
  if (IS_PRODUCTION === false) {
    invariant(false, error.message, context);
    return; // required for mocking `invariant` module
  }

  Sentry.captureException(error, {
    extra: context,
  });
}
