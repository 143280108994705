import { Call } from "../types";

export function getHasAssetTokenSecurityToken(
  permissionManagerContractAddress: string,
  connectedAddress: string,
  assetTokenSecurityTokenId: string,
  options: { label?: string } = {}
): Call {
  const { label = "hasAssetTokenSecurityToken" } = options;

  return {
    target: permissionManagerContractAddress,
    call: [
      "hasSecurityToken(address,uint256)(bool)",
      connectedAddress,
      assetTokenSecurityTokenId,
    ],
    label,
  };
}
