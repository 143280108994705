import { SwapValidationError } from '../enums/swap-validation-error'
import { TokenModel } from '../types/token.model'
import { unifyAddress } from '../utils/unify-address'
import { Pool } from './pool'
import { SwapError } from './swap-error'
import { XToken } from './x-token'

export class Token {
  public readonly id: string

  public readonly decimals: number

  public readonly xToken: XToken

  constructor(token: TokenModel) {
    this.id = unifyAddress(token.id)
    this.decimals = Number(token?.decimals || 0)
    this.xToken = new XToken(token.xToken)

    this.selfValidate()
  }

  private selfValidate = (): void => {
    if (!this.xToken || this.xToken.paused) {
      throw new SwapError(SwapValidationError.TOKEN_IS_NOT_ALLOWED, { token: this.id })
    }

    if (this.xToken.poolTokens.length === 0) {
      throw new SwapError(SwapValidationError.TOKEN_IS_NOT_SWAPPABLE, { token: this.id })
    }
  }

  static getInvolvedPools(token: Token): Pool[] {
    return token.xToken.poolTokens.map(({ pool }) => pool as Pool)
  }
}
