import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { Dialog } from "@/components/Dialog";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";
import { matchIsSwapToken } from "@/pages/Swap/utils/matchIsSwapToken";
import { getBigNumber } from "@/utils/getBigNumber";
import { Flexbox } from "@/components/Flexbox";
import { Balance } from "@/components/Balance";

interface Props {
  tokenIn: SwapToken | NativeSwapToken | undefined;
  tokenOut: SwapToken | NativeSwapToken | undefined;
  isOpen: boolean;
  confirm: () => void;
  close: () => void;
}

export const SwapFormCpkLeftoversDialog: React.FC<Props> = (props) => {
  const { tokenIn, tokenOut, isOpen, confirm, close } = props;

  const getSwapTokenXTokenCpkBalance = (
    token: SwapToken | NativeSwapToken | undefined
  ) => {
    return token !== undefined && matchIsSwapToken(token)
      ? token.xToken.cpkBalance
      : undefined;
  };

  const tokenInXTokenCpkBalance = getSwapTokenXTokenCpkBalance(tokenIn);
  const tokenOutXTokenCpkBalance = getSwapTokenXTokenCpkBalance(tokenOut);

  const getParagraph = (): React.ReactNode => {
    if (
      tokenIn !== undefined &&
      tokenInXTokenCpkBalance !== undefined &&
      getBigNumber(tokenInXTokenCpkBalance).gt(0)
    ) {
      if (
        tokenOut !== undefined &&
        tokenOutXTokenCpkBalance !== undefined &&
        getBigNumber(tokenOutXTokenCpkBalance).gt(0)
      ) {
        return (
          <React.Fragment>
            This transaction will spend{" "}
            <Balance
              value={tokenInXTokenCpkBalance}
              decimals={4}
              hasFixedDecimals={true}
            />{" "}
            {tokenIn.symbol} and return to you an additional{" "}
            <Balance
              value={tokenOutXTokenCpkBalance}
              decimals={4}
              hasFixedDecimals={true}
            />{" "}
            {tokenOut.symbol} that remained in your proxy wallet from previous
            transactions.
            <br />
            This saves you gas and means you spend less of your wallet balance.
          </React.Fragment>
        );
      }

      return (
        <React.Fragment>
          This transaction will spend{" "}
          <Balance
            value={tokenInXTokenCpkBalance}
            decimals={4}
            hasFixedDecimals={true}
          />{" "}
          {tokenIn.symbol} that remained in your proxy wallet from previous
          transactions. This saves you gas and means you spend less of your
          wallet balance.
        </React.Fragment>
      );
    }

    if (
      tokenOut !== undefined &&
      tokenOutXTokenCpkBalance !== undefined &&
      getBigNumber(tokenOutXTokenCpkBalance).gt(0)
    ) {
      return (
        <React.Fragment>
          As part of this transaction, you will receive an additional{" "}
          <Balance
            value={tokenOutXTokenCpkBalance}
            decimals={4}
            hasFixedDecimals={true}
          />{" "}
          {tokenOut.symbol} that remained in your proxy wallet from previous
          transactions.
        </React.Fragment>
      );
    }

    return "This transaction will spend token balances that remained in your proxy wallet from previous transactions. This saves you gas and means you spend less of your wallet balance.";
  };

  return (
    <Dialog title="Please note" isOpen={isOpen} close={close}>
      <Flexbox
        direction="column"
        justifyContent="start"
        alignItems="start"
        spacing={3}
      >
        <Typography fontSize="0.875rem">{getParagraph()}</Typography>

        <Flexbox
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={(theme) => {
              return {
                flex: 1,
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 600,
                boxShadow: theme.custom.shadows.levelOne,
                "&:hover": {
                  boxShadow: theme.custom.shadows.levelTwo,
                },
              };
            }}
            onClick={close}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={(theme) => {
              return {
                flex: 1,
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 600,
                boxShadow: theme.custom.shadows.levelOne,
                "&:hover": {
                  boxShadow: theme.custom.shadows.levelTwo,
                },
              };
            }}
            onClick={confirm}
          >
            Continue
          </Button>
        </Flexbox>
      </Flexbox>
    </Dialog>
  );
};
