import { BigSource } from 'big.js'

import { SwapTxType } from './enums/swap-tx-type'
import { SwapValidationError } from './enums/swap-validation-error'
import { PathUtils } from './helpers/path-utils'
import { Pool } from './helpers/pool'
import { SwapError } from './helpers/swap-error'
import { Token } from './helpers/token'
import { OptimizedRoutes } from './types/optimized-routes'
import { SwapRoute, SwapRoutesDiff } from './types/swap-route'
import { TokenModel } from './types/token.model'
import big, { reduce, safeDiv } from './utils/big'
import log from './utils/log'

const DEFAULT_NUMBER_OF_POOLS = 5

export class SwapRouter {
  private readonly tokenIn: Token

  private readonly tokenOut: Token

  // sorted by the spot price
  private readonly allDirectPools: Pool[]

  // array of addresses
  public readonly hopTokens: string[]

  private readonly multihopInvolvedPools: Record<'in' | 'out', Pool[]>

  /**
   * @param _tokenIn - token to sell
   * @param _tokenOut - token to receive
   * @param [maxPools=5] - the maximum number of pools to split the swap amount
   */
  constructor(
    _tokenIn: TokenModel,
    _tokenOut: TokenModel,
    private maxPools: number = DEFAULT_NUMBER_OF_POOLS,
  ) {
    this.tokenIn = new Token(_tokenIn)
    this.tokenOut = new Token(_tokenOut)

    const tokenInPools = Token.getInvolvedPools(this.tokenIn)
    const tokenOutPools = Token.getInvolvedPools(this.tokenOut)

    this.allDirectPools = PathUtils.getDirectPools(tokenInPools, tokenOutPools)

    this.hopTokens = PathUtils.getHopTokens(tokenInPools, tokenOutPools).filter(
      tokenId => !this.allDirectPools.some(directPool => directPool.hasOwnToken(tokenId)),
    )

    if (this.allDirectPools.length <= 0 && this.hopTokens.length <= 0) {
      throw new SwapError(SwapValidationError.TOKEN_PAIR_IS_NOT_SWAPPABLE)
    }

    this.multihopInvolvedPools = {
      in: tokenInPools.filter(poolIn =>
        this.hopTokens.some(hopTokenId => poolIn.hasOwnToken(hopTokenId)),
      ),
      out: tokenOutPools.filter(poolOut =>
        this.hopTokens.some(hopTokenId => poolOut.hasOwnToken(hopTokenId)),
      ),
    }
  }

  /**
   * @param swapType - exactIn or exactOut
   * @param _swapAmount - swapAmount is expressed in tokenIn if swapType is exactIn, if swapType is exactOut swapAmount is expressed in tokenOut
   */
  public getOptimizedRoutes(swapType: SwapTxType, _swapAmount: BigSource): OptimizedRoutes {
    const poolsRange = Array(this.maxPools)
      .fill(0)
      .map((_, index) => ++index)

    const swapErrorExceptionHash = SwapError.messageToHash(
      SwapValidationError.SWAP_AMOUNT_ABOVE_LIMIT,
    )

    const allAvailableRoutes = poolsRange
      .map(nPools => {
        try {
          const route = this.getSwaps(swapType, _swapAmount, nPools)
          const routePoolsCount = route.swaps.reduce(
            (total, sequence) => total + sequence.length,
            0,
          )

          return routePoolsCount === nPools ? route : null
        } catch (e) {
          if (e instanceof SwapError && swapErrorExceptionHash === e.hash) {
            return null
          }

          throw e // re-throw the error unchanged
        }
      })
      .filter(route => route !== null) as SwapRoute[]

    if (allAvailableRoutes.length === 0) {
      throw new SwapError(SwapValidationError.SWAP_AMOUNT_ABOVE_LIMIT)
    }

    log.table('All available routes', allAvailableRoutes)

    const shortestRoute = allAvailableRoutes[0]

    const bestSpotPriceRoute = allAvailableRoutes.reduce((bestRoute, route) => {
      return big(bestRoute.swapPrice).gt(route.swapPrice) ? bestRoute : route
    }, shortestRoute)

    return {
      shortestRoute,
      bestSpotPriceRoute,
      routesDiff: this.getRoutesDiff(shortestRoute, bestSpotPriceRoute),
    }
  }

  public getExactInLimits(
    maxAvailableAmountIn?: BigSource,
  ): Pick<SwapRoute, 'totalSwapLimitInput' | 'totalSwapFeeAmount'> {
    const { totalSwapLimitInput: maxInAmount } = this.getSwaps(SwapTxType.exactIn, 0, this.maxPools)

    if (maxAvailableAmountIn === undefined || big(maxAvailableAmountIn).gte(maxInAmount)) {
      const { totalSwapFeeAmount } = this.getSwaps(SwapTxType.exactIn, maxInAmount, this.maxPools)
      return { totalSwapLimitInput: maxInAmount, totalSwapFeeAmount }
    }

    const { totalSwapFeeAmount } = this.getSwaps(
      SwapTxType.exactIn,
      maxAvailableAmountIn,
      this.maxPools,
    )

    return { totalSwapLimitInput: maxAvailableAmountIn, totalSwapFeeAmount }
  }

  public getSwaps(swapType: SwapTxType, _swapAmount: BigSource, nPools: number): SwapRoute {
    const [xTokenInAddress, xTokenOutAddress] = [this.tokenIn.xToken.id, this.tokenOut.xToken.id]
    const swapAmount = big(_swapAmount)

    const allDirectPaths = PathUtils.getDirectPaths(
      xTokenInAddress,
      xTokenOutAddress,
      swapType,
      this.allDirectPools,
    )

    const allMultihopPaths = PathUtils.getMultihopPaths(
      xTokenInAddress,
      xTokenOutAddress,
      swapType,
      this.multihopInvolvedPools.in,
      this.multihopInvolvedPools.out,
      this.hopTokens,
    )

    const sortedPaths = [...allDirectPaths, ...allMultihopPaths]
      .sort(PathUtils.sortBySpotPrice)
      .reduce(PathUtils.takeFirst(nPools), [])
      .sort(PathUtils.sortBySwapLimit)

    const totalSwapLimitInput = PathUtils.getTotalLimitInput(sortedPaths).toFixed(0)

    if (swapAmount.lte(totalSwapLimitInput)) {
      const paths = PathUtils.splitSwapAmount(sortedPaths, swapAmount)
      const swaps = paths.map(path => path.swapSequence)
      const totalSwapFeeAmount = PathUtils.getTotalSwapFeeAmount(paths).toFixed(0)
      const totalReturn = PathUtils.getTotalReturnAmount(paths)
      const totalAvailableReturnAmount = PathUtils.getTotalAvailableOutput(paths).toFixed(0)

      const humanReadableAmounts = {
        tokenIn: reduce(
          swapType === SwapTxType.exactIn ? swapAmount : totalReturn,
          this.tokenIn.decimals,
        ),
        tokenOut: reduce(
          swapType === SwapTxType.exactIn ? totalReturn : swapAmount,
          this.tokenOut.decimals,
        ),
      }

      const swapPrice = safeDiv(humanReadableAmounts.tokenOut, humanReadableAmounts.tokenIn)

      return {
        totalSwapLimitInput,
        totalSwapFeeAmount,
        swaps,
        totalReturn: totalReturn.toFixed(0),
        totalAvailableReturnAmount,
        swapPrice: swapPrice.toString(),
      }
    }

    throw new SwapError(SwapValidationError.SWAP_AMOUNT_ABOVE_LIMIT)
  }

  private getRoutesDiff = (route1: SwapRoute, route2: SwapRoute): SwapRoutesDiff | null => {
    if (big(route1.swapPrice).eq(route2.swapPrice)) {
      return null
    }

    return {
      totalAvailableReturnAmount: big(route2.totalAvailableReturnAmount)
        .minus(route1.totalAvailableReturnAmount)
        .toFixed(0),
      totalReturn: big(route2.totalReturn)
        .minus(route1.totalReturn)
        .toFixed(0),
      totalSwapFeeAmount: big(route2.totalSwapFeeAmount)
        .minus(route1.totalSwapFeeAmount)
        .toFixed(0),
      totalSwapLimitInput: big(route2.totalSwapLimitInput)
        .minus(route1.totalSwapLimitInput)
        .toFixed(0),
    }
  }
}
