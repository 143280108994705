import { init } from "@web3-onboard/react";
import injectedModule from "@web3-onboard/injected-wallets";
import walletConnectModule from "@web3-onboard/walletconnect";
import ledgerModule from "@web3-onboard/ledger";
import trezorModule from "@web3-onboard/trezor";

import { Chain, ACTIVE_CHAINS } from "@/constants/chains";

export function setupWeb3Onboard(
  rootElement: HTMLElement,
  supportedChains?: Chain[]
): void {
  const injected = injectedModule();
  const walletConnect = walletConnectModule();
  const ledger = ledgerModule();
  const trezor = trezorModule({
    email: "swarm@swarm.com",
    appUrl: document.location.host,
  });

  const activeChainsWithAssets =
    supportedChains !== undefined ? supportedChains : ACTIVE_CHAINS;

  init({
    wallets: [injected, walletConnect, ledger, trezor],
    accountCenter: {
      desktop: {
        enabled: false,
        containerElement: `#${rootElement.id}`,
      },
      mobile: {
        enabled: false,
        containerElement: `#${rootElement.id}`,
      },
    },
    chains: Object.values(activeChainsWithAssets).map((chain) => {
      return {
        id: chain.id,
        token: chain.nativeToken.symbol,
        label: chain.label,
        rpcUrl: chain.rpcUrl,
      };
    }),
    appMetadata: {
      name: "Swarm Markets - Trade Widget",
      icon: "<svg><svg/>",
      description: "Widget for interacting with Swarm Markets",
      recommendedInjectedWallets: [
        { name: "MetaMask", url: "https://metamask.io" },
        { name: "Coinbase", url: "https://wallet.coinbase.com/" },
      ],
    },
  });
}
