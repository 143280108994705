import React from "react";
import Typography from "@mui/material/Typography";

import { Flexbox } from "@/components/Flexbox";

interface Props {}

export const NonValidWidgetKey: React.FC<Props> = (props) => {
  return (
    <Flexbox
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={(theme) => {
        return {
          width: "100%",
          height: "100vh",
          backgroundColor: theme.custom.colors.background.default,
        };
      }}
    >
      <Flexbox
        direction="column"
        justifyContent="start"
        alignItems="center"
        sx={(theme) => {
          return {
            width: "500px",
            maxWidth: "90%",
            backgroundColor: theme.custom.colors.white,
            padding: 4,
            borderRadius: 2,
            boxShadow: theme.custom.shadows.levelTwo,
          };
        }}
      >
        <Typography variant="h5" marginBottom={2}>
          Invalid widget key
        </Typography>
        <Typography
          variant="subtitle1"
          marginBottom={2}
          sx={(theme) => {
            return {
              color: theme.custom.colors.gray,
              textAlign: "center",
            };
          }}
        >
          The specified widget key is not valid for this domain.
          <br />
          If you are the website owner, please reach out to us.
        </Typography>
      </Flexbox>
    </Flexbox>
  );
};
