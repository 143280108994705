import React from "react";
import Typography from "@mui/material/Typography";

import { Flexbox } from "@/components/Flexbox";
import { TokenIcon } from "@/components/TokenIcon";

interface Props {
  symbol: string;
  name: string;
  rightLabel?: React.ReactNode;
}

export const TokenListItem: React.FC<Props> = (props) => {
  const { symbol, name, rightLabel } = props;

  return (
    <Flexbox
      direction="row"
      justifyContent="start"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <Flexbox
        direction="row"
        justifyContent="start"
        alignItems="center"
        sx={{ flex: 1 }}
        spacing={2}
      >
        <TokenIcon symbol={symbol} width="24px" height="24px" name={name} />
        <Typography sx={{ fontWeight: 700 }}>
          {symbol} <span style={{ fontWeight: 400 }}>({name})</span>
        </Typography>
      </Flexbox>

      {rightLabel !== undefined ? (
        <Flexbox
          direction="row"
          justifyContent="start"
          alignItems="center"
          sx={{ flexShrink: 0 }}
        >
          <Typography
            sx={(theme) => {
              return { color: theme.custom.colors.gray };
            }}
          >
            {rightLabel}
          </Typography>
        </Flexbox>
      ) : null}
    </Flexbox>
  );
};
