import React from "react";

export type State = "idle" | "confirming" | "executing";

interface ReturnValue {
  state: State;
  startConfirmation: () => void;
  startExecution: () => void;
  stop: () => void;
}

export function useTwoStepTransactionFlag(): ReturnValue {
  const [state, setState] = React.useState<State>("idle");

  const startConfirmation = React.useCallback(() => {
    setState("confirming");
  }, []);

  const startExecution = React.useCallback(() => {
    setState("executing");
  }, []);

  const stop = React.useCallback(() => {
    setState("idle");
  }, []);

  const value = React.useMemo<ReturnValue>(() => {
    return {
      state,
      startConfirmation,
      startExecution,
      stop,
    };
  }, [state, startConfirmation, startExecution, stop]);

  return value;
}
