import { hexlify } from '@ethersproject/bytes'
import { toUtf8Bytes } from '@ethersproject/strings'

import { SwapValidationError } from '../enums/swap-validation-error'

export class SwapError<
  TDetails extends undefined | Record<string, unknown> = undefined
> extends Error {
  public readonly hash: string

  constructor(message: SwapValidationError, public details?: TDetails) {
    super(message)
    this.hash = SwapError.messageToHash(message)
  }

  toString = (): string => JSON.stringify({ ...this, message: this.message })

  static messageToHash = (message: SwapValidationError): string => hexlify(toUtf8Bytes(message))
}
