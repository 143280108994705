import { ethers } from "ethers";

import type { Signer } from "@/types/web3";

import type { NativeTokenWrapper } from "../typechain";
import NativeTokenWrapperAbi from "../interfaces/NativeTokenWrapper.json";

export function getNativeTokenWrapperContractInstance(
  nativeTokenWrapperAddress: string,
  signer: Signer
): NativeTokenWrapper {
  return new ethers.Contract(
    nativeTokenWrapperAddress,
    NativeTokenWrapperAbi,
    signer
  ) as NativeTokenWrapper;
}
