import { SwapTxType, SwapRoutesDiff } from "@swarmmarkets/smart-order-router";

import type { BigNumber } from "@/types/web3";
import { getBigNumber } from "@/utils/getBigNumber";
import { getDenormalizedDecimalsNumber } from "@/utils/getDenormalizedDecimalsNumber";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";

export function getSwapOptimisationPriceDifference(
  swapType: SwapTxType,
  swapStrategiesDiff: SwapRoutesDiff,
  tokenIn: SwapToken | NativeSwapToken,
  tokenOut: SwapToken | NativeSwapToken
): BigNumber {
  const totalReturnDiff =
    swapType === SwapTxType.exactIn
      ? getBigNumber(swapStrategiesDiff.totalReturn)
      : getBigNumber(swapStrategiesDiff.totalReturn).times(-1);

  return getDenormalizedDecimalsNumber(
    totalReturnDiff,
    swapType === SwapTxType.exactIn ? tokenOut.decimals : tokenIn.decimals
  );
}
