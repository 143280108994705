import React from "react";

import { LocalStorageService } from "./instance";

type SetValue<Value> = (
  newValue: Value | ((prevValue: Value) => Value)
) => void;

type ReturnValue<Value> = {
  value: Value;
  setValue: SetValue<Value>;
  removeValue: () => void;
};

export function useLocalStorage<Value>(
  serviceInstance: LocalStorageService<Value>
): ReturnValue<Value> {
  const [value, setValue] = React.useState<Value>(serviceInstance.get());

  // Reset state when the local storage service instance changes
  React.useEffect(() => {
    setValue(serviceInstance.get());
  }, [serviceInstance]);

  // Sync state when some other hook pointing to the same local storage key changes it
  React.useEffect(() => {
    const { unsubscribe } = serviceInstance.subscribe(setValue);

    return () => {
      unsubscribe();
    };
  }, [serviceInstance]);

  // Sync state when local storage key changes on other documents
  React.useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key !== serviceInstance.key) {
        return;
      }

      setValue(serviceInstance.decode(event.newValue));
    };

    window.addEventListener("storage", listener);

    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [serviceInstance]);

  const storeValue = React.useCallback<SetValue<Value>>(
    (valueOrFunctionToStore) => {
      if (valueOrFunctionToStore instanceof Function) {
        const valueToStore = valueOrFunctionToStore(value);
        serviceInstance.set(valueToStore);
        return;
      }

      const valueToStore = valueOrFunctionToStore;
      serviceInstance.set(valueToStore);
    },
    [serviceInstance, value]
  );

  const removeValue = React.useCallback<() => void>(() => {
    serviceInstance.remove();
  }, [serviceInstance]);

  const returnValue = React.useMemo(() => {
    return {
      value,
      setValue: storeValue,
      removeValue,
    };
  }, [value, storeValue, removeValue]);

  return returnValue;
}
