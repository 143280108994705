import { MaxUint256 } from '@ethersproject/constants'
import Big, { BigSource } from 'big.js'

import { SwapTxType } from '../enums/swap-tx-type'
import { SwapPath } from '../types/swap-path'
import { SwapSequence } from '../types/swap-sequence'
import { ZERO } from '../utils/big'
import { Pool } from './pool'

const minAmountOut: BigSource = '0'
const maxAmountIn: BigSource = MaxUint256.toString()
const maxPrice: BigSource = MaxUint256.toString()

export class SingleSwapPath implements SwapPath {
  private _swapAmount: Big = ZERO

  private _availableAmountForSwap: Big

  private _fulfilled = false

  constructor(
    public tokenInAddress: string,
    public tokenOutAddress: string,
    public swapType: SwapTxType,
    public pool: Pool,
  ) {
    this._availableAmountForSwap = this.swapLimit

    if (this._availableAmountForSwap.eq(0)) {
      this._fulfilled = true
    }
  }

  public increaseSwapAmount(amountToAdd: Big): void {
    this.swapAmount = this.swapAmount.plus(amountToAdd)
  }

  public decreaseSwapAmount(amountToReduce: Big): void {
    this.swapAmount = this.swapAmount.minus(amountToReduce)
  }

  get swapAmount(): Big {
    return this._swapAmount
  }

  set swapAmount(newAmount: Big) {
    if (newAmount.gte(this.availableAmountForSwap)) {
      this._swapAmount = this.swapLimit
      this._availableAmountForSwap = ZERO
      this._fulfilled = true
    } else {
      this._swapAmount = newAmount
      this._availableAmountForSwap = this.swapLimit.minus(newAmount)
    }
  }

  get returnAmount(): Big {
    if (this.swapType === SwapTxType.exactIn) {
      return this.pool.calcOutGivenInPerTokenPair(
        this.tokenInAddress,
        this.tokenOutAddress,
        this.swapAmount,
      )
    }
    return this.pool.calcInGivenOutPerTokenPair(
      this.tokenInAddress,
      this.tokenOutAddress,
      this.swapAmount,
    )
  }

  get maxReturnAmount(): Big {
    if (this.swapType === SwapTxType.exactIn) {
      return this.pool.calcOutGivenInPerTokenPair(
        this.tokenInAddress,
        this.tokenOutAddress,
        this.swapLimit,
      )
    }
    return this.pool.calcInGivenOutPerTokenPair(
      this.tokenInAddress,
      this.tokenOutAddress,
      this.swapLimit,
    )
  }

  get swapLimit(): Big {
    return this.pool.getSwapLimitAmount(this.tokenInAddress, this.tokenOutAddress, this.swapType)
  }

  get fulfilled(): boolean {
    return this._fulfilled
  }

  get swapFeeAmount(): Big {
    if (this.swapType === SwapTxType.exactIn) {
      return this.swapAmount.times(this.pool.swapFee)
    }

    return this.returnAmount.times(this.pool.swapFee)
  }

  // abstract price that can be used only to compare swap path with another one
  get spotPrice(): Big {
    return this.pool.getSpotPricePerTokenPair(this.tokenInAddress, this.tokenOutAddress)
  }

  get availableAmountForSwap(): Big {
    return this._availableAmountForSwap
  }

  get swapSequence(): SwapSequence {
    return [
      {
        pool: this.pool.id,
        tokenIn: this.tokenInAddress,
        tokenOut: this.tokenOutAddress,
        swapAmount: this.swapAmount.toFixed(0),
        limitReturnAmount: this.swapType === SwapTxType.exactIn ? minAmountOut : maxAmountIn,
        maxPrice,
      },
    ]
  }
}
