import { getBigNumber } from "@/utils/getBigNumber";
import type { Swap } from "@/pages/Swap/types";

interface GraphDataPoint {
  name: string;
  value: number;
}

export type GraphData = GraphDataPoint[];

const STABLE_GRAPH_DATA: GraphData = [
  { name: "price", value: 1 },
  { name: "price", value: 1 },
];

export function getSwapsHistoryGraphData(swapsHistory: Swap[]): GraphData {
  if (swapsHistory.length === 0) {
    return STABLE_GRAPH_DATA;
  }

  const chronologicalSwapsHistory = swapsHistory.slice().reverse();

  const graphDatapoints = chronologicalSwapsHistory.map((swap) => {
    return {
      name: "price",
      value: getBigNumber(swap.priceRatio).toNumber(),
    };
  });

  if (graphDatapoints.length === 1 && graphDatapoints[0] !== undefined) {
    return [graphDatapoints[0], graphDatapoints[0]];
  }

  return graphDatapoints;
}
