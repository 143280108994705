import React from "react";
import Clipboard from "clipboard";

import { useInterval } from "./useInterval";

const DEFAULT_FEEDBACK_DURATION_MILLISECONDS = 3000;

type Ref = (element: HTMLElement | null) => void;

interface ReturnValue {
  buttonProps: {
    ref: Ref;
    "data-clipboard-text": string;
  };
  hasJustCopied: boolean;
}

export function useCopyToClipboard(
  text: string | undefined,
  options: { feedBackDurationMilliseconds?: number } = {}
): ReturnValue {
  const {
    feedBackDurationMilliseconds = DEFAULT_FEEDBACK_DURATION_MILLISECONDS,
  } = options;

  const clipboardRef = React.useRef<Clipboard | null>(null);

  const [hasJustCopied, setHasJustCopied] = React.useState<boolean>(false);

  const setHasJustCopiedToFalse = React.useCallback(() => {
    setHasJustCopied(false);
  }, []);

  useInterval(
    setHasJustCopiedToFalse,
    hasJustCopied ? feedBackDurationMilliseconds : undefined
  );

  const ref = React.useCallback<Ref>((element) => {
    if (element !== null) {
      clipboardRef.current = new Clipboard(element, {
        container: element,
      });
      clipboardRef.current.on("success", () => {
        setHasJustCopied(true);
      });
      return;
    }

    if (element === null && clipboardRef.current !== null) {
      clipboardRef.current.destroy();
    }
  }, []);

  const value = React.useMemo<ReturnValue>(() => {
    return {
      buttonProps: {
        ref,
        "data-clipboard-text": text !== undefined ? text : "",
      },
      hasJustCopied,
    };
  }, [ref, text, hasJustCopied]);

  return value;
}
