import { AUTHORIZATION_HEADER, WIDGET_KEY_HEADER } from "./constants";
import { baseUrl, widgetKey, accessToken } from "./state";
import { makeAuthorizationHeader } from "./utils/makeAuthorizationHeader";
import { matchIsErrorResponse } from "./utils/matchIsErrorResponse";

interface HTTPRequestOptions<RequestBody = Record<string, unknown>> {
  method: "GET" | "POST" | "PUT" | "DELETE";
  headers?: Record<string, string>;
  body?: RequestBody;
  searchParams?: Record<string, unknown>;
  isAuthorizationRequired: boolean;
}

export async function makeApiFetchRequest<
  Data = unknown,
  RequestBody = unknown
>(apiPath: string, options: HTTPRequestOptions<RequestBody>): Promise<Data> {
  const headers: Record<string, string> = {
    ...options.headers,
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  // Include access token in headers if required
  if (options.isAuthorizationRequired) {
    if (accessToken === null) {
      throw new Error("ACCESS_TOKEN_NOT_SET");
    }

    headers[AUTHORIZATION_HEADER] = makeAuthorizationHeader(accessToken);
  }

  // Include widget key in custom header
  if (widgetKey === null) {
    throw new Error("WIDGET_KEY_NOT_SET");
  }
  headers[WIDGET_KEY_HEADER] = widgetKey;

  // Add search params
  if (baseUrl === null) {
    throw new Error("BASE_URL_NOT_SET");
  }
  const url = new URL(apiPath, baseUrl);
  if (options.searchParams !== undefined) {
    Object.entries(options.searchParams).forEach(([key, value]) => {
      if (value !== undefined) {
        url.searchParams.append(key, String(value));
      }
    });
  }

  const requestOptions: RequestInit = {
    method: options.method,
    headers,
  };

  // Add body if any
  if (options.body !== undefined) {
    requestOptions.body = JSON.stringify(options.body);
  }

  const response = await fetch(url.toString(), requestOptions);

  // Handle non 200 status codes
  if (response.ok === false) {
    if (response.status === 401) {
      throw new Error("UNAUTHORIZED");
    }

    const responseJSON = await response.json();

    if (matchIsErrorResponse(responseJSON) === false) {
      throw new Error(response.statusText);
    }

    const firstError = responseJSON.errors[0];
    if (firstError === undefined) {
      throw new Error(response.statusText);
    }

    throw new Error(firstError.detail);
  }

  // Handle 204 status code
  if (response.status === 204) {
    // Caller should have expected an empty response
    return undefined as unknown as Data;
  }

  const responseJSON: { data: Data } = await response.json();

  return responseJSON.data;
}
