import React from "react";

import { useIconsCdnManifest } from "@/hooks/useIconsCdnManifest";
import { Image } from "@/components/Image";

import { DEFAULT_SIZE, GENERIC_ICON_SRC } from "./TokenIcon.constants";
import { getIconSrc } from "./TokenIcon.utils";

interface Props {
  symbol: string;
  width?: string;
  height?: string;
  name?: string;
  isDisabled?: boolean;
  hasEmptyAlt?: boolean;
}

export const TokenIcon: React.FC<Props> = (props) => {
  const {
    symbol,
    width = DEFAULT_SIZE,
    height = DEFAULT_SIZE,
    name,
    isDisabled = false,
    hasEmptyAlt = false,
  } = props;

  const { manifest } = useIconsCdnManifest();

  const title = `${name || symbol} token icon`;

  const iconSrc = getIconSrc(symbol, manifest);

  return (
    <Image
      src={iconSrc}
      width={width}
      height={height}
      fallback={GENERIC_ICON_SRC}
      alt={hasEmptyAlt ? "" : title}
      title={title}
      isDisabled={isDisabled}
    />
  );
};
