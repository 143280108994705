import React from "react";

import { Swap } from "./pages/Swap";

interface Props {}

export const App: React.FC<Props> = (props) => {
  return (
    <div
      id="popup-container"
      style={{
        width: "100%",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <div
        id="dialog-container"
        style={{
          width: "100%",
          minHeight: "100vh",
          transform: "translateZ(0)",
        }}
      >
        <Swap />
      </div>
    </div>
  );
};
