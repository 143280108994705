import Big from "big.js";

export function setupBigJs() {
  // eslint-disable-next-line id-length
  Big.DP = 30;
  // eslint-disable-next-line id-length
  Big.RM = 0;
  // eslint-disable-next-line id-length
  Big.NE = -30;
  // eslint-disable-next-line id-length
  Big.PE = 30;
}
