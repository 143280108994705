import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import { SxPropArgument, getMergedSxProps } from "@/utils/getMergedSxProps";
import { useContainerObserver } from "@/contexts/ContainerObserverContext";

type Direction = "row" | "column";
type AxisAlignment = "start" | "end" | "center" | "stretch";

interface Props extends React.ComponentProps<typeof Box> {
  direction: Direction | Direction[];
  justifyContent: AxisAlignment | AxisAlignment[];
  alignItems: AxisAlignment | AxisAlignment[];
  wrap?: boolean | boolean[];
  spacing?: number | number[];
}

export const Flexbox: React.FC<Props> = (props) => {
  const {
    direction,
    justifyContent,
    alignItems,
    wrap = false,
    spacing,
    sx: boxSx,
    ...boxProps
  } = props;

  const theme = useTheme();

  const { containerWidth } = useContainerObserver();

  const getSpacingSxProp = (): SxPropArgument => {
    if (spacing === undefined) {
      return undefined;
    }

    if (typeof spacing === "number") {
      return {
        gap: spacing,
      };
    }

    if (spacing[0] === undefined) {
      return undefined;
    }

    const baseStyles: SxPropArgument = { "": {} };

    Object.values(theme.breakpoints.values).forEach((breakpoint, index) => {
      const breakpointSpacing = spacing[index];
      if (breakpointSpacing === undefined) {
        return;
      }

      if (containerWidth < breakpoint) {
        return;
      }

      baseStyles.gap = breakpointSpacing;
    });

    return baseStyles;
  };

  const getFlexboxSxProps = (): SxPropArgument => {
    if (
      Array.isArray(direction) === false &&
      Array.isArray(wrap) === false &&
      Array.isArray(justifyContent) === false &&
      Array.isArray(alignItems) === false
    ) {
      return {
        display: "flex",
        flexDirection: direction,
        flexWrap: wrap ? "wrap" : "nowrap",
        justifyContent,
        alignItems,
      };
    }

    const baseStyles: Record<string, any> = {
      display: "flex",
    };

    if (Array.isArray(direction) === false) {
      baseStyles.flexDirection = direction;
    }
    if (Array.isArray(wrap) === false) {
      baseStyles.flexWrap = wrap ? "wrap" : "nowrap";
    }
    if (Array.isArray(justifyContent) === false) {
      baseStyles.justifyContent = justifyContent;
    }
    if (Array.isArray(alignItems) === false) {
      baseStyles.alignItems = alignItems;
    }

    Object.values(theme.breakpoints.values).forEach((breakpoint, index) => {
      if (containerWidth < breakpoint) {
        return;
      }

      if (Array.isArray(direction) && direction[index] !== undefined) {
        baseStyles.flexDirection = direction[index];
      }
      if (Array.isArray(wrap) && wrap[index] !== undefined) {
        baseStyles.flexWrap = wrap[index] ? "wrap" : "nowrap";
      }
      if (
        Array.isArray(justifyContent) &&
        justifyContent[index] !== undefined
      ) {
        baseStyles.justifyContent = justifyContent[index];
      }
      if (Array.isArray(alignItems) && alignItems[index] !== undefined) {
        baseStyles.alignItems = alignItems[index];
      }
    });

    return baseStyles;
  };

  return (
    <Box
      {...boxProps}
      sx={getMergedSxProps(getSpacingSxProp(), boxSx, getFlexboxSxProps())}
    />
  );
};
