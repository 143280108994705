import React from "react";

import { Flexbox } from "@/components/Flexbox";

import { SmallLogo } from "./components/SmallLogo";
import { LargeLogo } from "./components/LargeLogo";
import { Breakpoint } from "../Breakpoint";

interface Props {
  smallLogoUrl?: string;
  largeLogoUrl?: string;
}

export const Logo: React.FC<Props> = (props) => {
  const { smallLogoUrl, largeLogoUrl } = props;

  return (
    <React.Fragment>
      <Breakpoint until="tablet">
        <Flexbox
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "42px" }}
        >
          {smallLogoUrl !== undefined ? (
            <img src={smallLogoUrl} alt="" />
          ) : (
            <SmallLogo />
          )}
        </Flexbox>
      </Breakpoint>

      <Breakpoint from="tablet">
        <Flexbox
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "150px" }}
        >
          {largeLogoUrl !== undefined ? (
            <img src={largeLogoUrl} alt="" />
          ) : (
            <LargeLogo />
          )}
        </Flexbox>
      </Breakpoint>
    </React.Fragment>
  );
};
