import React from "react";
import Box from "@mui/material/Box";

import { useWallet } from "@/contexts/WalletContext";
import { useContainerObserver } from "@/contexts/ContainerObserverContext";
import { useTier } from "@/services/api";
import { useCPK } from "@/hooks/useCPK";
import { getMinContainerWidthCSS } from "@/utils/getContainerWidthCSSMediaQuery";
import { Layout } from "@/components/Layout";
import { TransactionFeedback } from "@/components/TransactionFeedback";
import { useConfiguration } from "@/state/configuration";

import { useSwapTokens } from "./hooks/useSwapTokens";
import { useSwapTransactionFeedback } from "./hooks/useSwapTransactionFeedback";
import { SwapForm } from "./components/SwapForm";

interface Props {}

export const Swap: React.FC<Props> = (props) => {
  const {
    connectedAddress,
    connectedChain,
    connectedWalletSigner,
    chains,
    connect,
    disconnect,
    setChain,
  } = useWallet();

  const { containerWidth } = useContainerObserver();

  const cpk = useCPK();

  const { data: tier, revalidate: revalidateTier } = useTier(connectedAddress);

  const { configuration } = useConfiguration();

  const swapTokens = useSwapTokens();

  const { feedbackItem, showFeedback, hideFeedback } =
    useSwapTransactionFeedback();

  return (
    <Layout
      connectedAddress={connectedAddress}
      chains={chains}
      connectedChain={connectedChain}
      configuration={configuration}
      setChain={setChain}
      connect={connect}
      disconnect={disconnect}
    >
      <Box
        sx={(theme) => {
          return {
            width: "100%",
            ...getMinContainerWidthCSS(
              containerWidth,
              theme.breakpoints.values["tablet"],
              {
                maxWidth: "600px",
                margin: "0 auto",
              }
            ),
          };
        }}
      >
        {feedbackItem !== undefined ? (
          <Box sx={{ marginBottom: 2 }}>
            <TransactionFeedback
              title={feedbackItem.title}
              explorerUrl={feedbackItem.explorerUrl}
              hadSuccess={feedbackItem.hadSuccess}
              dismiss={hideFeedback}
            />
          </Box>
        ) : null}

        <SwapForm
          swapTokens={swapTokens}
          cpk={cpk}
          connectedAddress={connectedAddress}
          connectedChain={connectedChain}
          connectedWalletSigner={connectedWalletSigner}
          tier={tier}
          configuration={configuration}
          connectWallet={connect}
          revalidateTier={revalidateTier}
          showFeedback={showFeedback}
        />
      </Box>
    </Layout>
  );
};
