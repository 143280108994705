import { Call } from "../types";

export function getAssetTokenSecurityTokenId(
  permissionManagerContractAddress: string,
  assetTokenAddress: string,
  options: { label?: string } = {}
): Call {
  const { label = "assetTokenSecurityTokenId" } = options;

  return {
    target: permissionManagerContractAddress,
    call: ["getSecurityTokenId(address)(uint256)", assetTokenAddress],
    label,
  };
}
