import React from "react";
import useSWR from "swr";

interface ReturnValue<Data> {
  data: Data | undefined;
  error: Error | undefined;
}

export function useSubgraphRequest<
  Data,
  EndpointFetcherArguments extends any[]
>(
  key: [key: string, ...args: EndpointFetcherArguments] | null,
  endpointPromise: (...args: EndpointFetcherArguments) => Promise<Data>
): ReturnValue<Data> {
  const { data, error } = useSWR<Data, Error>(key, (_key, ...args) => {
    return endpointPromise(...(args as EndpointFetcherArguments));
  });

  const value = React.useMemo<ReturnValue<Data>>(() => {
    return {
      data,
      error,
    };
  }, [data, error]);

  return value;
}
