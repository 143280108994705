import type { EthereumNetwork, GasPrice } from "../types";
import { getGweiEthers } from "../utils/getGweiEthers";

interface ResponseEthereumGasPrice {
  result: {
    FastGasPrice: number;
  };
}

export const FALLBACK_GWEI_FEE = 80;

const GAS_STATION_URL_BY_NETWORK: Record<EthereumNetwork, string> = {
  ethereum: "https://api.etherscan.io/api?module=gastracker&action=gasoracle",
  goerli:
    "https://api-goerli.etherscan.io/api?module=gastracker&action=gasoracle",
  rinkeby:
    "https://api-rinkeby.etherscan.io/api?module=gastracker&action=gasoracle",
};

export async function getEthereumGasPrice(
  network: EthereumNetwork
): Promise<GasPrice> {
  try {
    // Note this API is rate limited and we currently don't pass any API key, thus using the "Free" plan
    // More info at https://docs.etherscan.io/support/rate-limits
    const responseEthereumGasPrice = await fetch(
      GAS_STATION_URL_BY_NETWORK[network]
    ).then<ResponseEthereumGasPrice>((response) => {
      return response.json();
    });

    const maxPriorityFeePerGas = getGweiEthers(
      responseEthereumGasPrice.result.FastGasPrice
    );

    const maxFeePerGas = getGweiEthers(
      responseEthereumGasPrice.result.FastGasPrice
    );

    return {
      maxPriorityFeePerGas,
      maxFeePerGas,
    };
  } catch (error) {
    return {
      maxPriorityFeePerGas: getGweiEthers(FALLBACK_GWEI_FEE),
      maxFeePerGas: getGweiEthers(FALLBACK_GWEI_FEE),
    };
  }
}
