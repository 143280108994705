import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";

import { ROUTES } from "@/routes";
import { Dialog } from "@/components/Dialog";
import { Flexbox } from "@/components/Flexbox";

interface Props {
  isOpen: boolean;
  confirm: () => void;
  close: () => void;
}

export const SwapFormCpkDeploymentCostDialog: React.FC<Props> = (props) => {
  const { isOpen, confirm, close } = props;

  return (
    <Dialog title="Set up your proxy contract" isOpen={isOpen} close={close}>
      <Flexbox
        direction="column"
        justifyContent="start"
        alignItems="start"
        spacing={3}
      >
        <Alert severity="info" sx={{ width: "100%" }}>
          A one-time network fee will be applied
        </Alert>

        <Typography fontSize="0.875rem">
          Your first transaction will include a network fee to deploy your
          personal proxy contract. This is a one-time action that helps you
          transact more easily, saves you fees in the long run, and can be used
          with other Gnosis Safe applications.{" "}
          <Link
            href={ROUTES.DOCS_SWAPS}
            target="_blank"
            rel="noopener"
            color="primary"
          >
            Learn more about proxy contracts.
          </Link>
        </Typography>

        <Flexbox
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={(theme) => {
              return {
                flex: 1,
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 600,
                boxShadow: theme.custom.shadows.levelOne,
                "&:hover": {
                  boxShadow: theme.custom.shadows.levelTwo,
                },
              };
            }}
            onClick={close}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={(theme) => {
              return {
                flex: 1,
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 600,
                boxShadow: theme.custom.shadows.levelOne,
                "&:hover": {
                  boxShadow: theme.custom.shadows.levelTwo,
                },
              };
            }}
            onClick={confirm}
          >
            Continue
          </Button>
        </Flexbox>
      </Flexbox>
    </Dialog>
  );
};
