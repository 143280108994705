import React from "react";

import type { Address } from "@/types/web3";
import { matchIsSameEthereumAddress } from "@/utils/matchIsSameEthereumAddress";
import type { SwapToken, NativeSwapToken } from "@/pages/Swap/types";

interface ReturnValue {
  tokenIn: SwapToken | NativeSwapToken | undefined;
  tokenOut: SwapToken | NativeSwapToken | undefined;
  tokenInAmount: string;
  tokenOutAmount: string;
  maxTokenInAmount: string | undefined;
  setTokenInAddress: React.Dispatch<React.SetStateAction<Address | undefined>>;
  setTokenOutAddress: React.Dispatch<React.SetStateAction<Address | undefined>>;
  setTokenInAmount: React.Dispatch<React.SetStateAction<string>>;
  setTokenOutAmount: React.Dispatch<React.SetStateAction<string>>;
}

export function useSwapValues(
  swapTokens: (SwapToken | NativeSwapToken)[]
): ReturnValue {
  const [tokenInAddress, setTokenInAddress] = React.useState<
    Address | undefined
  >(undefined);
  const [tokenOutAddress, setTokenOutAddress] = React.useState<
    Address | undefined
  >(undefined);

  const [tokenInAmount, setTokenInAmount] = React.useState<string>("");
  const [tokenOutAmount, setTokenOutAmount] = React.useState<string>("");

  const tokenIn =
    tokenInAddress !== undefined
      ? swapTokens.find((token) => {
          return matchIsSameEthereumAddress(token.address, tokenInAddress);
        })
      : undefined;

  const tokenOut =
    tokenOutAddress !== undefined
      ? swapTokens.find((token) => {
          return matchIsSameEthereumAddress(token.address, tokenOutAddress);
        })
      : undefined;

  // Auto select first 2 tokens
  React.useEffect(() => {
    if (swapTokens[0] !== undefined) {
      setTokenInAddress(swapTokens[0].address);
    }
    if (swapTokens[1] !== undefined) {
      setTokenOutAddress(swapTokens[1].address);
    }
  }, [swapTokens]);

  const maxTokenInAmount = tokenIn !== undefined ? tokenIn.balance : undefined;

  const value = React.useMemo<ReturnValue>(() => {
    return {
      tokenIn,
      tokenOut,
      tokenInAmount,
      tokenOutAmount,
      maxTokenInAmount,
      setTokenInAddress,
      setTokenOutAddress,
      setTokenInAmount,
      setTokenOutAmount,
    };
  }, [
    tokenIn,
    tokenOut,
    tokenInAmount,
    tokenOutAmount,
    maxTokenInAmount,
    setTokenInAddress,
    setTokenOutAddress,
    setTokenInAmount,
    setTokenOutAmount,
  ]);

  return value;
}
