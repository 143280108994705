import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import type { Provider } from "@/types/web3";
import { Chain } from "@/constants/chains";
import { getExplorerTransactionUrl } from "@/utils/getExplorerTransactionUrl";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { Dialog } from "@/components/Dialog";
import { Flexbox } from "@/components/Flexbox";
import { Balance } from "@/components/Balance";

interface SwapDetails {
  id: string;
  tokenInSymbol: string;
  tokenOutSymbol: string;
  tokenInAmount: string;
  tokenOutAmount: string;
  timestamp: number;
  priceRatio: string;
}

interface Props {
  swapDetails: SwapDetails | undefined;
  connectedChain: Chain | null;
  connectedWalletProvider: Provider | null;
  close: () => void;
}

export const SwapRecentTransactionsDetailsDialog: React.FC<Props> = (props) => {
  const { swapDetails, connectedChain, connectedWalletProvider, close } = props;

  const transactionHash =
    swapDetails !== undefined
      ? swapDetails.id.slice(0, swapDetails.id.indexOf("-"))
      : undefined;

  const transactionHashCopyToClipboard = useCopyToClipboard(transactionHash);

  const [transaction, setTransaction] = React.useState<
    { confirmations: number } | undefined
  >(undefined);

  React.useEffect(() => {
    if (transactionHash === undefined || connectedWalletProvider === null) {
      return;
    }

    const loadTransaction = async () => {
      const transaction = await connectedWalletProvider.getTransaction(
        transactionHash
      );
      setTransaction(transaction);
    };

    loadTransaction();
  }, [transactionHash, connectedWalletProvider]);

  // 2. Token in symbol & amount + token out symbol + amount
  // 3. Timestamp (locale date string) + price ratio
  // 4. Network confirmations
  // 5. Transaction hash & copy & open in explorer

  return (
    <Dialog
      title="Swap details"
      isOpen={swapDetails !== undefined}
      close={close}
    >
      <Flexbox
        direction="column"
        justifyContent="start"
        alignItems="start"
        spacing={2}
      >
        <Section title="Status">
          <Typography
            component="span"
            sx={(theme) => {
              return {
                fontWeight: 600,
                color: theme.palette.success.light,
              };
            }}
          >
            Completed
          </Typography>
        </Section>

        <Flexbox
          direction="row"
          justifyContent="start"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Section title="Swapped from">
            {swapDetails !== undefined ? (
              <React.Fragment>
                <Balance value={swapDetails.tokenInAmount} decimals={6} />{" "}
                {swapDetails.tokenInSymbol}
              </React.Fragment>
            ) : (
              "-"
            )}
          </Section>
          <Section title="Swapped to">
            {swapDetails !== undefined ? (
              <React.Fragment>
                <Balance value={swapDetails.tokenOutAmount} decimals={6} />{" "}
                {swapDetails.tokenOutSymbol}
              </React.Fragment>
            ) : (
              "-"
            )}
          </Section>
        </Flexbox>

        <Flexbox
          direction="row"
          justifyContent="start"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Section title="Transaction time">
            {swapDetails !== undefined
              ? new Date(swapDetails.timestamp).toLocaleString()
              : "-"}
          </Section>
          <Section title="Price at transaction">
            {swapDetails !== undefined ? (
              <React.Fragment>
                <Balance value={swapDetails.tokenInAmount} decimals={6} />{" "}
                {swapDetails.tokenInSymbol} ={" "}
                <Balance value={swapDetails.tokenOutAmount} decimals={6} />{" "}
                {swapDetails.tokenOutSymbol}
              </React.Fragment>
            ) : (
              "-"
            )}
          </Section>
        </Flexbox>

        <Section title="Network confirmations">
          {transaction !== undefined ? (
            <Typography
              component="span"
              sx={(theme) => {
                return {
                  fontWeight: 600,
                  color: theme.custom.colors.black,
                };
              }}
            >
              {transaction.confirmations}
            </Typography>
          ) : (
            "-"
          )}
        </Section>

        <Section title="Transaction hash">
          {transactionHash !== undefined ? (
            <Flexbox
              direction="column"
              justifyContent="start"
              alignItems="start"
            >
              <Typography
                component="span"
                sx={(theme) => {
                  return {
                    fontWeight: 600,
                    color: theme.custom.colors.black,
                    wordBreak: "break-all",
                    whiteSpace: "pre-wrap",
                  };
                }}
              >
                {transactionHash}
              </Typography>
              <Flexbox
                direction="row"
                justifyContent="start"
                alignItems="center"
                gap={2}
              >
                <Button
                  {...transactionHashCopyToClipboard.buttonProps}
                  variant="text"
                  endIcon={
                    transactionHashCopyToClipboard.hasJustCopied ? (
                      <CheckIcon
                        sx={(theme) => {
                          return {
                            color: theme.palette.success.light,
                          };
                        }}
                      />
                    ) : undefined
                  }
                  sx={{
                    textTransform: "none",
                    paddingX: 0,
                    minWidth: 0,
                    "&:hover, &:focus": {
                      textDecoration: "underline",
                      background: "none",
                    },
                  }}
                >
                  Copy
                </Button>
                <Button
                  variant="text"
                  href={
                    transactionHash !== undefined && connectedChain !== null
                      ? getExplorerTransactionUrl(
                          connectedChain.key,
                          transactionHash
                        )
                      : "#"
                  }
                  target="_blank"
                  rel="noreferer noopener"
                  endIcon={<OpenInNewIcon />}
                  sx={{
                    textTransform: "none",
                    paddingX: 0,
                    minWidth: 0,
                    "&:hover, &:focus": {
                      textDecoration: "underline",
                      background: "none",
                    },
                  }}
                >
                  View on explorer
                </Button>
              </Flexbox>
            </Flexbox>
          ) : (
            "-"
          )}
        </Section>

        <Button
          variant="outlined"
          fullWidth={true}
          onClick={close}
          sx={{ textTransform: "none" }}
        >
          Close
        </Button>
      </Flexbox>
    </Dialog>
  );
};

interface SectionProps {
  title: string;
  children: React.ReactNode;
}
const Section: React.FC<SectionProps> = (props) => {
  const { title, children } = props;

  return (
    <Flexbox
      direction="column"
      justifyContent="start"
      alignItems="start"
      spacing={0.5}
      sx={{ width: "100%" }}
    >
      <Typography
        sx={(theme) => {
          return {
            fontSize: "0.875rem",
            fontWeight: 600,
            color: theme.custom.colors.gray,
          };
        }}
      >
        {title}
      </Typography>
      <Typography
        component="div"
        sx={(theme) => {
          return {
            fontSize: "1rem",
            color: theme.custom.colors.black,
          };
        }}
      >
        {children}
      </Typography>
    </Flexbox>
  );
};
