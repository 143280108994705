import { Address } from "@/types/web3";
import { getHexValue } from "@/utils/getHexValue";

import {
  ENVIRONMENT,
  GOERLI_RPC_URL,
  RINKEBY_RPC_URL,
  MUMBAI_RPC_URL,
  ETHEREUM_RPC_URL,
  POLYGON_RPC_URL,
  GOERLI_MULTICALL_ADDRESS,
  RINKEBY_MULTICALL_ADDRESS,
  MUMBAI_MULTICALL_ADDRESS,
  ETHEREUM_MULTICALL_ADDRESS,
  POLYGON_MULTICALL_ADDRESS,
  GOERLI_SUBGRAPH_URL,
  RINKEBY_SUBGRAPH_URL,
  MUMBAI_SUBGRAPH_URL,
  ETHEREUM_SUBGRAPH_URL,
  POLYGON_SUBGRAPH_URL,
} from "./environment";

export type TestnetChainKey = "goerli" | "rinkeby" | "mumbai";
export type MainnetChainKey = "ethereum" | "polygon";
export type ChainKey = TestnetChainKey | MainnetChainKey;

export interface Chain {
  id: string;
  key: ChainKey;
  label: string;
  rpcUrl: string;
  explorerUrl: string;
  subgraphUrl: string;
  multicallAddress: string;
  contractsAddresses: {
    smt: string;
    smtPriceFeed: string;
    xTokenWrapper: string;
    bpoolProxy: string;
    protocolFee: string;
    permissionManager: string;
  };
  nativeToken: {
    name: string;
    symbol: string;
    address: Address;
    decimals: number;
  };
  wrappedNativeToken: {
    name: string;
    symbol: string;
    address: Address;
    decimals: number;
  };
}

export const NATIVE_TOKEN_ADDRESS =
  "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";

export const CHAINS: Record<ChainKey, Chain> = {
  goerli: {
    id: getHexValue(5),
    key: "goerli",
    label: "Goerli",
    rpcUrl: GOERLI_RPC_URL,
    explorerUrl: "https://goerli.etherscan.io",
    subgraphUrl: GOERLI_SUBGRAPH_URL,
    multicallAddress: GOERLI_MULTICALL_ADDRESS,
    contractsAddresses: {
      smt: "0x8FB7556388Dda82507c4Bc63fD787370070a5b5E",
      smtPriceFeed: "0xA484887C06132691887e6340e795A90DAf5da37f",
      xTokenWrapper: "0x6124244f5F614C791ccD202E653112Ec4e2064de",
      bpoolProxy: "0xb9bFeECde2c7C2E3AD6A3372Acf46fA70000dc63",
      protocolFee: "0x4EE9e0BD3eA6ad6bd784D66cb1aE91da94E8941a",
      permissionManager: "0x1c18a360a762be9a8f181f81FB8Dcc02524e47e8",
    },
    nativeToken: {
      name: "Ethereum",
      symbol: "ETH",
      address: NATIVE_TOKEN_ADDRESS,
      decimals: 18,
    },
    wrappedNativeToken: {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      address: "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6",
      decimals: 18,
    },
  },
  rinkeby: {
    id: getHexValue(4),
    key: "rinkeby",
    label: "Rinkeby",
    rpcUrl: RINKEBY_RPC_URL,
    explorerUrl: "https://rinkeby.etherscan.io",
    subgraphUrl: RINKEBY_SUBGRAPH_URL,
    multicallAddress: RINKEBY_MULTICALL_ADDRESS,
    contractsAddresses: {
      smt: "0x199eeb1de08fcc9371249d88b710369d0c6ff255",
      smtPriceFeed: "0x651c04abB6C820ee0669a062c31A8796E4Ea39a2",
      xTokenWrapper: "0x5c2BDE960cdF557B882Ba293F5e036E1A1316aa0",
      bpoolProxy: "0x70575B99bAa084d279221249f5376Ea0556Aa8f2",
      protocolFee: "0xf72DB0A627C62368D1A86DaA179568bCBAeE01EC",
      permissionManager: "0xA4543a4149a0a194bA16534d84a8212Be94d54FD",
    },
    nativeToken: {
      name: "Ethereum",
      symbol: "ETH",
      address: NATIVE_TOKEN_ADDRESS,
      decimals: 18,
    },
    wrappedNativeToken: {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      address: "0xc778417e063141139fce010982780140aa0cd5ab",
      decimals: 18,
    },
  },
  mumbai: {
    id: getHexValue(80001),
    key: "mumbai",
    label: "Mumbai",
    rpcUrl: MUMBAI_RPC_URL,
    explorerUrl: "https://mumbai.polygonscan.com",
    subgraphUrl: MUMBAI_SUBGRAPH_URL,
    multicallAddress: MUMBAI_MULTICALL_ADDRESS,
    contractsAddresses: {
      smt: "0x20e237b96f4be16e660a943a42516123065f3d65",
      smtPriceFeed: "0x50D7687b64b0795eA01c6C68D05a187958370c9e",
      xTokenWrapper: "0x9ED7C0Cd38a4A15A34A4093Fb1038ff867C9476B",
      bpoolProxy: "0x78539816a7615818Fbf8529e1a90bDbb511C35f5",
      protocolFee: "0x5976af899f30FAb537E22E517256807BF3000d27",
      permissionManager: "0xE03331388a20dD77f370dfb2f8AbAe1474B02ea0",
    },
    nativeToken: {
      name: "Matic",
      symbol: "MATIC",
      address: NATIVE_TOKEN_ADDRESS,
      decimals: 18,
    },
    wrappedNativeToken: {
      name: "Wrapped MATIC",
      symbol: "WMATIC",
      address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
      decimals: 18,
    },
  },
  ethereum: {
    id: getHexValue(1),
    key: "ethereum",
    label: "Ethereum",
    rpcUrl: ETHEREUM_RPC_URL,
    explorerUrl: "https://etherscan.io",
    subgraphUrl: ETHEREUM_SUBGRAPH_URL,
    multicallAddress: ETHEREUM_MULTICALL_ADDRESS,
    contractsAddresses: {
      smt: "0xb17548c7b510427baac4e267bea62e800b247173",
      smtPriceFeed: "0x1606FA059b8C4Ed1b4297e3530922e2A3C145eA7",
      xTokenWrapper: "0x2b9dc65253c035Eb21778cB3898eab5A0AdA0cCe",
      bpoolProxy: "0x5321647F3c3769bc7bb9e10aB10d7F5C2E402c56",
      protocolFee: "0x9516CAc65d54cC2fe4c070978223f930894ba072",
      permissionManager: "0xE214d97ba7fF83144699737f73D271C006013d91",
    },
    nativeToken: {
      name: "Ethereum",
      symbol: "ETH",
      address: NATIVE_TOKEN_ADDRESS,
      decimals: 18,
    },
    wrappedNativeToken: {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      decimals: 18,
    },
  },
  polygon: {
    id: getHexValue(137),
    key: "polygon",
    label: "Polygon",
    rpcUrl: POLYGON_RPC_URL,
    explorerUrl: "https://polygonscan.com/",
    subgraphUrl: POLYGON_SUBGRAPH_URL,
    multicallAddress: POLYGON_MULTICALL_ADDRESS,
    contractsAddresses: {
      smt: "0xe631dabef60c37a37d70d3b4f812871df663226f",
      smtPriceFeed: "0xfBf3FAAd4ddaE624b8780D6d905Dff2bC97752B7",
      xTokenWrapper: "0x6cDDe4eD9165405c1914b229d3cD4Ac9C354C331",
      bpoolProxy: "0x174Ac59f7071e1264b6B21Cb7FdC8FA00ae1ef3C",
      protocolFee: "0xe5334D8A619C5BA2D64D9068Bd57702626E8ACAB",
      permissionManager: "0x2dDe22CbF81844492b5f29a2938ab075a8224Ef5",
    },
    nativeToken: {
      name: "Matic",
      symbol: "MATIC",
      address: NATIVE_TOKEN_ADDRESS,
      decimals: 18,
    },
    wrappedNativeToken: {
      name: "Wrapped MATIC",
      symbol: "WMATIC",
      address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
      decimals: 18,
    },
  },
};

const MAINNET_CHAINS = [CHAINS.ethereum, CHAINS.polygon];
const TESTNET_CHAINS = [CHAINS.goerli, CHAINS.rinkeby, CHAINS.mumbai];

export const ACTIVE_CHAINS =
  ENVIRONMENT === "mainnet" ? MAINNET_CHAINS : TESTNET_CHAINS;
