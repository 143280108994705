import { ethers } from "ethers";

import type { Address, Signer } from "@/types/web3";

import { getErc20ContractInstance } from "./getErc20ContractInstance";

export async function approveErc20(
  tokenAddress: Address,
  spenderAddress: Address,
  signer: Signer
): Promise<ethers.ContractTransaction> {
  const contractInstance = getErc20ContractInstance(tokenAddress, signer);

  return contractInstance.approve(spenderAddress, ethers.constants.MaxUint256);
}
