import Big from 'big.js'

import { SwapTxType } from '../enums/swap-tx-type'
import { SwapPath } from '../types/swap-path'
import { ZERO } from '../utils/big'
import { isSameAddress } from '../utils/is-same-address'
import { MultihopSwapPath } from './multihop-swap-path'
import { Pool } from './pool'
import { SingleSwapPath } from './single-swap-path'

export class PathUtils {
  static getDirectPools<P extends Pool>(tokenInPools: P[], tokenOutPools: P[]): P[] {
    if (tokenInPools.length <= 0 || tokenOutPools.length <= 0) {
      return []
    }

    return tokenInPools.filter(poolIn =>
      tokenOutPools.some(poolOut => isSameAddress(poolIn.id, poolOut.id)),
    )
  }

  static getDirectPaths(
    xTokenInAddress: string,
    xTokenOutAddress: string,
    swapType: SwapTxType,
    involvedPools: Pool[],
  ): SingleSwapPath[] {
    if (involvedPools.length <= 0) {
      return []
    }

    return involvedPools.map(
      pool => new SingleSwapPath(xTokenInAddress, xTokenOutAddress, swapType, pool),
    )
  }

  static getHopTokens(poolsIn?: Pool[], poolsOut?: Pool[]): string[] {
    if (!poolsIn || poolsIn.length <= 0 || !poolsOut || poolsOut.length <= 0) {
      return []
    }

    return poolsIn
      .reduce<string[]>((acc, { tokensList }) => Array.from(new Set([...acc, ...tokensList])), [])
      .filter(tokenId => poolsOut.some(pool => pool.hasOwnToken(tokenId)))
  }

  static getMultihopPaths(
    tokenInAddress: string,
    tokenOutAddress: string,
    swapType: SwapTxType,
    poolsTokenIn: Pool[],
    poolsTokenOut: Pool[],
    hopTokens: string[],
  ): MultihopSwapPath[] {
    if (hopTokens.length <= 0 || poolsTokenIn.length <= 0 || poolsTokenOut.length <= 0) {
      return []
    }

    return hopTokens.map(hopTokenId => {
      const currentPathPoolsTokenIn = poolsTokenIn.filter(pool => pool.hasOwnToken(hopTokenId))
      const currentPathPoolsTokenOut = poolsTokenOut.filter(pool => pool.hasOwnToken(hopTokenId))

      const currentHopTokenPaths = currentPathPoolsTokenIn.reduce<MultihopSwapPath[]>(
        (acc, pIn) => {
          const paths = currentPathPoolsTokenOut.map(pOut => {
            return new MultihopSwapPath(
              tokenInAddress,
              tokenOutAddress,
              hopTokenId,
              pIn,
              pOut,
              swapType,
            )
          })

          return [...acc, ...paths]
        },
        [],
      )

      return currentHopTokenPaths.sort(PathUtils.sortBySpotPrice)[0]
    })
  }

  // use this util as callback for reduce method
  static takeFirst<T extends SwapPath>(nPools: number) {
    return (result: T[] = [], path: T): T[] => {
      const numberOfInvolvedPools = result.reduce(
        (acc, { swapSequence }) => acc + swapSequence.length,
        0,
      )

      if (nPools === 1) {
        if (result.length === 0 && path.swapSequence.length === 1) {
          return [path]
        } else {
          return result
        }
      } else if (numberOfInvolvedPools < nPools) {
        const newNumberOfInvolvedPools = numberOfInvolvedPools + path.swapSequence.length
        return newNumberOfInvolvedPools <= nPools ? [...result, path] : result
      } else {
        return result
      }
    }
  }

  static splitSwapAmount(paths: SwapPath[], swapAmount: Big): SwapPath[] {
    const totalSwapLimit = paths.reduce((total, path) => total.plus(path.swapLimit), ZERO)

    if (totalSwapLimit.eq(ZERO)) return []

    let buffer = ZERO

    const filledPaths = paths.map(path => {
      const relativeAmount = swapAmount.times(path.swapLimit.div(totalSwapLimit))

      if (relativeAmount.lt(path.availableAmountForSwap)) {
        path.swapAmount = relativeAmount
        return path
      }

      const computedBuffer = relativeAmount.minus(path.availableAmountForSwap)
      buffer = buffer.plus(computedBuffer)

      path.swapAmount = path.swapLimit

      return path
    })

    if (!buffer.eq(0)) {
      filledPaths.forEach(path => {
        if (!path.fulfilled && buffer.gt(0)) {
          const amountToAdd = path.availableAmountForSwap.gt(buffer)
            ? buffer
            : path.availableAmountForSwap

          path.increaseSwapAmount(amountToAdd)

          buffer = buffer.minus(amountToAdd)
        }
      })
    }

    const totalSwapAmount = PathUtils.getTotalSwapAmount(filledPaths)
    if (totalSwapAmount.gt(swapAmount)) {
      filledPaths[0].decreaseSwapAmount(totalSwapAmount.minus(swapAmount))
    }

    return filledPaths
  }

  static sortBySpotPrice<SP extends SwapPath>(path1: SP, path2: SP): number {
    return path1.spotPrice.minus(path2.spotPrice).toNumber()
  }

  static sortBySwapLimit<SP extends SwapPath>(path1: SP, path2: SP): number {
    return path1.swapLimit
      .minus(path2.swapLimit)
      .times(-1)
      .toNumber()
  }

  static getTotalLimitInput(paths: SwapPath[]): Big {
    return paths.reduce((total, { swapLimit }) => total.add(swapLimit), ZERO)
  }

  static getTotalSwapAmount(paths: SwapPath[]): Big {
    return paths.reduce((total, { swapAmount }) => total.add(swapAmount), ZERO)
  }

  static getTotalSwapFeeAmount(paths: SwapPath[]): Big {
    return paths.reduce((total, { swapFeeAmount }) => total.add(swapFeeAmount), ZERO)
  }

  static getTotalReturnAmount(paths: SwapPath[]): Big {
    return paths.reduce((total, { returnAmount }) => total.plus(returnAmount), ZERO)
  }

  static getTotalAvailableOutput(paths: SwapPath[]): Big {
    return paths.reduce((total, { maxReturnAmount }) => total.add(maxReturnAmount), ZERO)
  }
}
