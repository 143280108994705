import Big from 'big.js'

import { SwapTxType } from '../enums/swap-tx-type'
import { SwapPath } from '../types/swap-path'
import { SwapSequence } from '../types/swap-sequence'
import { bdiv, bmul, min, ZERO } from '../utils/big'
import { Pool } from './pool'
import { SingleSwapPath } from './single-swap-path'

export class MultihopSwapPath implements SwapPath {
  private firstHop: SingleSwapPath

  private secondHop: SingleSwapPath

  private _fulfilled = false

  private _availableAmountForSwap: Big

  constructor(
    public tokenInAddress: string,
    public tokenOutAddress: string,
    public hopToken: string,
    public poolTokenIn: Pool,
    public poolTokenOut: Pool,
    public readonly swapType: SwapTxType,
  ) {
    this.firstHop = new SingleSwapPath(tokenInAddress, hopToken, swapType, poolTokenIn)
    this.secondHop = new SingleSwapPath(hopToken, tokenOutAddress, swapType, poolTokenOut)

    this._availableAmountForSwap = this.swapLimit
    if (this._availableAmountForSwap.eq(0)) {
      this._fulfilled = true
    }
  }
  get availableAmountForSwap(): Big {
    return this._availableAmountForSwap
  }

  get fulfilled(): boolean {
    return this._fulfilled
  }

  public increaseSwapAmount(amountToAdd: Big): void {
    this.swapAmount = this.swapAmount.plus(amountToAdd)
  }

  public decreaseSwapAmount(amountToReduce: Big): void {
    this.swapAmount = this.swapAmount.minus(amountToReduce)
  }

  get swapAmount(): Big {
    if (this.swapType === SwapTxType.exactIn) {
      return this.firstHop.swapAmount
    }
    return this.secondHop.swapAmount
  }

  set swapAmount(newAmount: Big) {
    if (this.swapType === SwapTxType.exactIn) {
      this.firstHop.swapAmount = newAmount
      this.secondHop.swapAmount = this.firstHop.returnAmount
    } else {
      this.secondHop.swapAmount = newAmount
      this.firstHop.swapAmount = this.secondHop.returnAmount
    }

    if (this.firstHop.fulfilled || this.secondHop.fulfilled) {
      this._fulfilled = true
    }

    this._availableAmountForSwap = newAmount.gte(this.availableAmountForSwap)
      ? ZERO
      : this.availableAmountForSwap.minus(newAmount)
  }

  get returnAmount(): Big {
    if (this.swapType === SwapTxType.exactIn) {
      return this.secondHop.returnAmount
    }
    return this.firstHop.returnAmount
  }

  get maxReturnAmount(): Big {
    if (this.swapType === SwapTxType.exactIn) {
      return this.secondHop.maxReturnAmount
    }
    return this.firstHop.maxReturnAmount
  }

  get swapFeeAmount(): Big {
    const firstHopSwapFee = this.firstHop.swapFeeAmount
    let secondHopIn: Big

    if (this.swapType === SwapTxType.exactIn) {
      secondHopIn = this.firstHop.swapAmount.minus(firstHopSwapFee)
    } else {
      secondHopIn = this.firstHop.returnAmount.minus(firstHopSwapFee)
    }

    const secondHopSwapFee = secondHopIn.times(this.secondHop.pool.swapFee)

    return firstHopSwapFee.plus(secondHopSwapFee)
  }

  get swapLimit(): Big {
    if (this.swapType === SwapTxType.exactIn) {
      return min(this.firstHop.swapLimit, bmul(this.secondHop.swapLimit, this.firstHop.spotPrice))
    }

    return min(this.secondHop.swapLimit, bdiv(this.firstHop.swapLimit, this.secondHop.spotPrice))
  }

  get spotPrice(): Big {
    const firstHopSpotPrice = this.firstHop.pool.getSpotPricePerTokenPair(
      this.tokenInAddress,
      this.hopToken,
    )
    const secondHopSpotPrice = this.secondHop.pool.getSpotPricePerTokenPair(
      this.hopToken,
      this.tokenOutAddress,
    )

    return bmul(firstHopSpotPrice, secondHopSpotPrice)
  }

  get swapSequence(): SwapSequence {
    return [this.firstHop.swapSequence[0], this.secondHop.swapSequence[0]]
  }
}
