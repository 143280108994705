import type { Theme } from "@mui/material/styles";

import { useStore } from "@/state";
import type { Configuration } from "@/state/configuration";
import { ACTIVE_CHAINS } from "@/constants/chains";
import { API_BASE_URL } from "@/constants/environment";
import { setBaseUrl } from "@/services/api";
import { setWidgetKey } from "@/services/api";

export interface SetupConfiguration {
  widgetKey: string;
  supportedAssets: Record<string, string>;
  preOnboardingContent: string;
  primaryColor?: string;
  smallLogoUrl?: string;
  largeLogoUrl?: string;
  apiUrl?: string;
}

export function setupConfiguration(
  configuration: SetupConfiguration,
  theme: Theme
): Partial<Configuration> {
  const store = useStore.getState();

  // Widget key
  const widgetKey = configuration.widgetKey;
  setWidgetKey(widgetKey);

  // Supported assets
  const supportedAssetsByChainKey = Object.entries(
    configuration.supportedAssets
  ).reduce<Record<string, string[]>>(
    (currentSupportedAssets, [chainKey, chainAssets]) => {
      currentSupportedAssets[chainKey] = chainAssets.split(",");
      return currentSupportedAssets;
    },
    {}
  );
  const supportedChains = ACTIVE_CHAINS.filter((chain) => {
    const chainAssets = supportedAssetsByChainKey[chain.key];
    return chainAssets !== undefined && chainAssets.length >= 2;
  });

  // Pre onboarding content
  const preOnboardingContent = configuration.preOnboardingContent;

  const parsedConfiguration: Configuration = {
    widgetKey,
    supportedChains,
    supportedAssetsByChainKey,
    preOnboardingContent,
  };

  // Primary color
  if (configuration.primaryColor !== undefined) {
    theme.custom.colors.primary = configuration.primaryColor;
    theme.palette.primary.main = configuration.primaryColor;
  }

  // Small logo url
  if (configuration.smallLogoUrl !== undefined) {
    parsedConfiguration.smallLogoUrl = configuration.smallLogoUrl;
  }

  // Large logo url
  if (configuration.largeLogoUrl !== undefined) {
    parsedConfiguration.largeLogoUrl = configuration.largeLogoUrl;
  }

  // API url
  const apiUrl =
    configuration.apiUrl !== undefined ? configuration.apiUrl : API_BASE_URL;
  setBaseUrl(apiUrl);

  store.setConfiguration(parsedConfiguration);

  return parsedConfiguration;
}
