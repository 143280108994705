import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface Props {
  onClick: () => void;
}

export const SwapFormReverseButton: React.FC<Props> = (props) => {
  const { onClick } = props;

  return (
    <IconButton
      aria-label="reverse swap"
      size="small"
      color="primary"
      sx={(theme) => {
        return {
          margin: "0 auto",
          padding: "2.5px",
          backgroundColor: theme.custom.colors.background.primary,
        };
      }}
      onClick={onClick}
    >
      <ArrowDownwardIcon fontSize="small" />
    </IconButton>
  );
};
