export type EncoderType<T> = (value: T) => string | null;
export type DecoderType<T> = (value: string | null) => T;

type SubscriptionCallback<Value> = (value: Value) => void;

export class LocalStorageService<Value> {
  private subscriptions: SubscriptionCallback<Value>[] = [];

  constructor(
    public readonly key: string,
    public encode: EncoderType<Value>,
    public decode: DecoderType<Value>
  ) {}

  public get = (): Value => {
    const value = window.localStorage.getItem(this.key);
    return this.decode(value);
  };

  public set(value: Value): void {
    const encodedValue = this.encode(value);

    if (encodedValue === null) {
      this.remove();
      return;
    }

    window.localStorage.setItem(this.key, encodedValue);

    this.subscriptions.forEach((callback) => {
      callback(value);
    });
  }

  public remove = (): void => {
    window.localStorage.removeItem(this.key);

    this.subscriptions.forEach((callback) => {
      callback(this.decode(null));
    });
  };

  public subscribe = (
    callback: SubscriptionCallback<Value>
  ): { unsubscribe: () => void } => {
    this.subscriptions.push(callback);
    return {
      unsubscribe: () => {
        this.subscriptions = this.subscriptions.filter(
          (subscriptionCallback) => {
            return subscriptionCallback !== callback;
          }
        );
      },
    };
  };
}
