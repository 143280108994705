import React from "react";
import Link from "@mui/material/Link";

import { ROUTES } from "@/routes";
import { Flexbox } from "@/components/Flexbox";

interface Props {}

export const WalletsButton: React.FC<Props> = (props) => {
  return (
    <Link
      href={ROUTES.TRADE_PLATFORM_WALLETS}
      target="_blank"
      rel="noopener"
      underline="none"
      aria-label="Wallets"
      sx={(theme) => {
        return {
          "&:hover, &:active": {
            outline: theme.custom.borders.levelOne,
            boxShadow: theme.custom.shadows.levelOne,
          },
        };
      }}
    >
      <Flexbox
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={(theme) => {
          return {
            width: "40px",
            height: "40px",
            backgroundColor: theme.custom.colors.white,
            borderRadius: "4px",
          };
        }}
      >
        <svg
          viewBox="0 4.691 38.42 30.667"
          fill="#0078EF"
          xmlns="http://www.w3.org/2000/svg"
          style={{ height: 28 }}
        >
          <path d="M7.698 8.261a3.057 3.057 0 0 0-3.041 3.041v19.267a2.72 2.72 0 0 0 2.702 2.703h23.66a2.72 2.72 0 0 0 2.705-2.703V15.02a2.72 2.72 0 0 0-2.705-2.704h-1.351V9.274c0-.529-.484-1.013-1.013-1.013H7.698Zm0 2.027H27.64v2.028H7.698a.99.99 0 0 1-1.014-1.014.99.99 0 0 1 1.014-1.014Zm-1.014 3.877c.318.113.659.178 1.014.178h23.321c.394 0 .677.287.677.677v3.042h-8.788a2.046 2.046 0 0 0-2.027 2.027v5.41c0 1.105.921 2.027 2.027 2.027h8.788v3.043a.654.654 0 0 1-.677.676H7.359a.654.654 0 0 1-.675-.676V14.165Zm16.224 5.924h8.788v5.41h-8.788v-5.41Zm2.705 1.355a1.35 1.35 0 1 0 0 2.702 1.352 1.352 0 0 0 0-2.702Z" />
        </svg>
      </Flexbox>
    </Link>
  );
};
