import React from "react";

interface Props {}

export const SupportIcon: React.FC<Props> = (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      style={{ height: "100%" }}
    >
      <path
        d="M10 17.5A7.5 7.5 0 0 1 2.5 10 7.5 7.5 0 0 1 10 2.5a7.5 7.5 0 0 1 7.5 7.5 7.5 7.5 0 0 1-7.5 7.5Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="square"
      />
      <path
        d="M10 11.041v-.208c0-.68.42-1.05.842-1.333.412-.278.824-.64.824-1.306a1.666 1.666 0 1 0-3.333 0"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="square"
      />
      <path
        d="M9.997 12.664a.879.879 0 1 0 .008 1.756.879.879 0 0 0-.008-1.756Z"
        fill="currentColor"
      />
    </svg>
  );
};
