import React from "react";

import { Flexbox } from "@/components/Flexbox";

import { SwapFormSlippageButton } from "./components/SwapFormSlippageButton";
import { SwapFormCustomSlippageInput } from "./components/SwapFormCustomSlippageInput";

interface Props {
  slippageTolerance: string;
  customSlippageTolerance: string;
  options: string[];
  setSlippageTolerance: (slippageTolerance: string) => void;
  setCustomSlippageTolerance: (customSlippageTolerance: string) => void;
}

export const SwapFormSlippageSelectInput: React.FC<Props> = (props) => {
  const {
    slippageTolerance,
    customSlippageTolerance,
    options,
    setSlippageTolerance,
    setCustomSlippageTolerance,
  } = props;

  const isCustomSelected = customSlippageTolerance !== "";

  return (
    <Flexbox
      direction="row"
      justifyContent="start"
      alignItems="center"
      spacing={1}
    >
      {options.map((option) => {
        return (
          <SwapFormSlippageButton
            key={option}
            slippageTolerange={option}
            isSelected={
              isCustomSelected === false && slippageTolerance === option
            }
            onClick={() => {
              if (isCustomSelected) {
                setCustomSlippageTolerance("");
              }
              setSlippageTolerance(option);
            }}
          />
        );
      })}
      <SwapFormCustomSlippageInput
        value={customSlippageTolerance}
        isSelected={isCustomSelected}
        setValue={setCustomSlippageTolerance}
      />
    </Flexbox>
  );
};
