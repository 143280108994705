import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";

import type { Address } from "@/types/web3";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";
import { matchIsSameEthereumAddress } from "@/utils/matchIsSameEthereumAddress";
import { getBigNumber } from "@/utils/getBigNumber";
import { Flexbox } from "@/components/Flexbox";
import { TokenListItem } from "@/components/TokenListItem";
import { Dialog } from "@/components/Dialog";
import { Balance } from "@/components/Balance";

interface Props {
  tokens: (SwapToken | NativeSwapToken)[];
  selectedTokenAddress?: Address;
  isOpen: boolean;
  setSelectedTokenAddress: (address: Address) => void;
  close: () => void;
}

export const SwapFormSelectTokenDialog: React.FC<Props> = (props) => {
  const {
    tokens,
    selectedTokenAddress,
    isOpen,
    setSelectedTokenAddress,
    close,
  } = props;

  const [search, setSearch] = React.useState<string>("");

  return (
    <Dialog
      title="Select a token"
      isOpen={isOpen}
      close={close}
      postTitle={
        <StyledTextField
          placeholder="Search token name or paste contract address"
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          inputProps={{
            style: {
              fontSize: "1rem",
              padding: "6px 10px",
            },
          }}
        />
      }
      hasDividers={true}
    >
      <Flexbox direction="column" justifyContent="start" alignItems="start">
        <List disablePadding={true} sx={{ width: "100%" }}>
          {tokens
            .filter((token) => {
              return (
                token.symbol.toLowerCase().includes(search.toLowerCase()) ||
                token.name.toLowerCase().includes(search.toLowerCase()) ||
                matchIsSameEthereumAddress(token.address, search)
              );
            })
            .sort((tokenA, tokenB) => {
              if (
                tokenA.usdBalance === undefined ||
                tokenB.usdBalance === undefined
              ) {
                return 0;
              }
              return getBigNumber(tokenB.usdBalance)
                .sub(tokenA.usdBalance)
                .toNumber();
            })
            .map((token) => {
              return (
                <ListItemButton
                  key={token.symbol}
                  selected={
                    selectedTokenAddress !== undefined &&
                    matchIsSameEthereumAddress(
                      token.address,
                      selectedTokenAddress
                    )
                  }
                  disableGutters={true}
                  onClick={() => {
                    setSelectedTokenAddress(token.address);
                    close();
                  }}
                  sx={{
                    padding: "12px 6px",
                    borderRadius: "4px",
                  }}
                >
                  <TokenListItem
                    symbol={token.symbol}
                    name={token.name}
                    rightLabel={
                      token.balance !== undefined ? (
                        <Balance
                          value={token.balance}
                          decimals={4}
                          hasFixedDecimals={true}
                        />
                      ) : undefined
                    }
                  />
                </ListItemButton>
              );
            })}
        </List>
      </Flexbox>
    </Dialog>
  );
};

const StyledTextField = styled(TextField)(({ theme }) => {
  return {
    width: "100%",
    height: "36px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.custom.colors.lightgray,
      },
      "&:hover fieldset": {
        borderColor: theme.custom.colors.lightgray,
        boxShadow: theme.custom.shadows.levelOne,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.custom.colors.primary,
        boxShadow: theme.custom.shadows.levelOne,
      },
    },
  };
});
