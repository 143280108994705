import React from "react";
import Button from "@mui/material/Button";

interface Props {
  slippageTolerange: string;
  isSelected?: boolean;
  onClick: () => void;
}

export const SwapFormSlippageButton: React.FC<Props> = (props) => {
  const { slippageTolerange, isSelected = false, onClick } = props;

  return (
    <Button
      variant="outlined"
      sx={(theme) => {
        return {
          minWidth: 0,
          height: "36px",
          padding: "0 10px",
          fontSize: "1rem",
          fontWeight: 500,
          color: isSelected
            ? theme.custom.colors.white
            : theme.custom.colors.primary,
          borderColor: isSelected
            ? theme.custom.colors.primary
            : theme.custom.colors.lightgray,
          backgroundColor: isSelected
            ? theme.custom.colors.primary
            : theme.custom.colors.white,
          "&:hover, &:active, &:focus": {
            color: isSelected
              ? theme.custom.colors.white
              : theme.custom.colors.primary,
            borderColor: theme.custom.colors.primary,
            backgroundColor: isSelected
              ? theme.custom.colors.primary
              : theme.custom.colors.white,
            boxShadow: theme.custom.shadows.levelOne,
          },
        };
      }}
      onClick={onClick}
    >
      {slippageTolerange} %
    </Button>
  );
};
