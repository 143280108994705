import React from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import SettingsIcon from "@mui/icons-material/Settings";

import { Chain } from "@/constants/chains";
import { Flexbox } from "@/components/Flexbox";
import { NetworkLogo } from "@/components/NetworkLogo";
import { useDialog } from "@/components/Dialog";

import { SwapFormAdvancedSettingsDialog } from "../SwapFormAdvancedSettingsDialog";

interface Props {
  connectedChain: Chain | null;
  toggleRecentSwapsVisibility: () => void;
}

export const SwarpFormHeader: React.FC<Props> = (props) => {
  const { connectedChain, toggleRecentSwapsVisibility } = props;

  const settingDialogProps = useDialog();

  return (
    <React.Fragment>
      <Flexbox
        direction="row"
        justifyContent="start"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Typography
          variant="h6"
          fontWeight={700}
          sx={(theme) => {
            return {
              flex: 1,
              color: theme.custom.colors.gray,
            };
          }}
        >
          <Flexbox direction="row" justifyContent="start" alignItems="center">
            Swap on
            {connectedChain !== null ? (
              <Flexbox
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                <NetworkLogo
                  chainKey={connectedChain.key}
                  width="20px"
                  height="20px"
                  sx={{ marginLeft: 0.75, marginRight: 0.5 }}
                />
                {connectedChain.label}
              </Flexbox>
            ) : null}
          </Flexbox>
        </Typography>
        <Flexbox
          direction="row"
          justifyContent="start"
          alignItems="center"
          sx={{
            flexShrink: 0,
            marginRight: "-4px",
          }}
        >
          <Tooltip placement="top" title="Toggle recent swaps">
            <IconButton
              aria-label="toggle-recent-swaps"
              sx={(theme) => {
                return {
                  color: theme.custom.colors.gray,
                  "&:hover, &:active": {
                    color: theme.custom.colors.primary,
                  },
                };
              }}
              onClick={toggleRecentSwapsVisibility}
            >
              <SvgIcon sx={{ fill: "none" }}>
                <path
                  d="M3 15h5M8 18H5M8 21H7M11.718 7.985v4.651l3.656 2.23"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.059 11A9 9 0 0 1 21 12a9 9 0 0 1-9 9"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title="Toggle settings">
            <IconButton
              aria-label="toggle-settings"
              sx={(theme) => {
                return {
                  color: theme.custom.colors.gray,
                  "&:hover, &:active": {
                    color: theme.custom.colors.primary,
                  },
                };
              }}
              onClick={settingDialogProps.open}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </Flexbox>
      </Flexbox>

      <SwapFormAdvancedSettingsDialog
        isOpen={settingDialogProps.isOpen}
        close={settingDialogProps.close}
      />
    </React.Fragment>
  );
};
