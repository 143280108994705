import type { WalletStoreSlice } from "./store";

export const selectLastConnectedWalletLabel = (state: WalletStoreSlice) => {
  return state.lastConnectedWalletLabel;
};

export const selectSetLastConnectedWalletLabel = (state: WalletStoreSlice) => {
  return state.setLastConnectedWalletLabel;
};

export const selectClearLastConnectedWalletLabel = (
  state: WalletStoreSlice
) => {
  return state.clearLastConnectedWalletLabel;
};
