export let baseUrl: string | null = null;
export function setBaseUrl(url: string): void {
  baseUrl = url;
}

export let widgetKey: string | null = null;
export function setWidgetKey(key: string | null): void {
  widgetKey = key;
}

export let accessToken: string | null = null;
export function setAccessToken(token: string): void {
  accessToken = token;
}
export function clearAccessToken(): void {
  accessToken = null;
}
