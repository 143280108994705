import React from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { useWallet } from "@/contexts/WalletContext";
import { useSwapsHistory } from "@/services/subgraph";
import { Flexbox } from "@/components/Flexbox";
import { Balance } from "@/components/Balance";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";

import { getPriceChangePercentage } from "./utils/getPriceChangePercentage";
import { getPriceChangePercentageColor } from "./utils/getPriceChangePercentageColor";
import { getTimestamp24HoursAgo } from "./utils/getTimestamp24HoursAgo";
import { getSwapsHistoryGraphData } from "./utils/getSwapsHistoryGraphData";

const SwapPriceChangeGraph = React.lazy(() => {
  return import("./components/SwapPriceChangeGraph").then((mod) => {
    return { default: mod.SwapPriceChangeGraph };
  });
});

interface Props {
  tokenIn: SwapToken | NativeSwapToken;
  tokenOut: SwapToken | NativeSwapToken;
}

export const SwapPriceChange: React.FC<Props> = (props) => {
  const { tokenIn, tokenOut } = props;

  const theme = useTheme();

  const { connectedChain } = useWallet();

  const swapsHistoryStartTimestamp = React.useRef<number>(
    getTimestamp24HoursAgo()
  );

  const { data: swapsHistory } = useSwapsHistory(
    connectedChain !== null ? connectedChain.subgraphUrl : undefined,
    tokenIn.xToken !== undefined ? tokenIn.xToken.address : undefined,
    tokenOut.xToken !== undefined ? tokenOut.xToken.address : undefined,
    swapsHistoryStartTimestamp.current
  );

  const priceChangePercentage = getPriceChangePercentage(
    swapsHistory !== undefined ? swapsHistory : []
  );

  const priceChangePercentageColor = getPriceChangePercentageColor(
    theme,
    priceChangePercentage
  );

  const graphData = getSwapsHistoryGraphData(
    swapsHistory !== undefined ? swapsHistory : []
  );

  return (
    <Flexbox
      direction="row"
      justifyContent="start"
      alignItems="center"
      gap={2}
      sx={(theme) => {
        return {
          width: "100%",
          backgroundColor: theme.custom.colors.white,
          borderRadius: "8px",
          padding: "8px",
        };
      }}
    >
      <Flexbox
        direction="row"
        justifyContent="start"
        alignItems="center"
        wrap={[true, false]}
        sx={{ flex: 1 }}
      >
        <Typography
          component="div"
          sx={(theme) => {
            return {
              color: theme.custom.colors.gray,
              marginRight: "6px",
            };
          }}
        >
          <span style={{ whiteSpace: "nowrap" }}>
            {tokenIn.name} <b>{tokenIn.symbol}</b> /
          </span>{" "}
          <span style={{ whiteSpace: "nowrap" }}>
            {tokenOut.name} <b>{tokenOut.symbol}</b>
          </span>
        </Typography>
        <Flexbox direction="row" justifyContent="start" alignItems="start">
          <Flexbox
            direction="row"
            justifyContent="start"
            alignItems="center"
            sx={{
              color: priceChangePercentageColor.main,
            }}
          >
            {priceChangePercentage !== 0 ? (
              <PlayArrowIcon
                sx={{
                  position: "relative",
                  top: priceChangePercentage > 0 ? "1px" : "-1px",
                  color: "inherit",
                  transform: `rotate(${
                    priceChangePercentage > 0 ? -90 : 90
                  }deg)`,
                }}
              />
            ) : null}
            <Typography
              component="div"
              fontWeight={700}
              sx={{
                color: "inherit",
                marginRight: "3px",
              }}
            >
              <Balance value={priceChangePercentage.toString()} decimals={2} />%
            </Typography>
          </Flexbox>

          <Typography
            component="div"
            sx={(theme) => {
              return {
                color: theme.custom.colors.gray,
              };
            }}
          >
            (24h)
          </Typography>
        </Flexbox>
      </Flexbox>
      <div style={{ flex: 1 }}>
        <React.Suspense fallback={null}>
          <SwapPriceChangeGraph
            data={graphData}
            color={priceChangePercentageColor}
          />
        </React.Suspense>
      </div>
    </Flexbox>
  );
};
