import { ethers } from "ethers";
import CPK, { TransactionResult, Transaction } from "contract-proxy-kit";
import { SwapSequence } from "@swarmmarkets/smart-order-router";

import type { Address, BigNumber, EthersBigNumber } from "@/types/web3";
import { Chain } from "@/constants/chains";
import { ZERO } from "@/constants/one-time-values";
import { getNormalizedDecimalsNumber } from "@/utils/getNormalizedDecimalsNumber";
import { getBigNumber } from "@/utils/getBigNumber";
import { getCPKTransactionEstimatedGasLimit } from "@/services/gas-estimator";
import {
  getBatchedValuesOnce,
  getErc20BalanceMulticall,
} from "@/services/multicall";

import Erc20Abi from "../interfaces/ERC20.json";
import XTokenAbi from "../interfaces/XToken.json";
import XTokenWrapperAbi from "../interfaces/XTokenWrapper.json";
import BPoolProxyAbi from "../interfaces/BPoolProxy.json";

const Erc20Interface = new ethers.utils.Interface(Erc20Abi);
const XTokenInterface = new ethers.utils.Interface(XTokenAbi);
const XTokenWrapperInterface = new ethers.utils.Interface(XTokenWrapperAbi);
const BPoolProxyInterface = new ethers.utils.Interface(BPoolProxyAbi);

interface SwapToken {
  address: Address;
  decimals: number;
  xToken: {
    address: Address;
  };
}

export async function makeMultihopBatchSwapExactOut(
  cpk: CPK,
  connectedAddress: Address,
  connectedChain: Chain,
  tokenIn: SwapToken,
  tokenOut: SwapToken,
  swaps: SwapSequence[],
  maxAmountIn: BigNumber,
  swapProtocolFee: BigNumber,
  isSmtFeeDiscountApplied: boolean
): Promise<TransactionResult> {
  if (cpk.address === undefined) {
    throw new Error("CPK is not ready");
  }

  const cpkTransactions: Transaction[] = [];

  const { xTokenInCpkBalance, xTokenOutCpkBalance, smtCpkBalance } =
    await getBatchedValuesOnce<{
      xTokenInCpkBalance: EthersBigNumber;
      xTokenOutCpkBalance: EthersBigNumber;
      smtCpkBalance: EthersBigNumber;
    }>(
      [
        getErc20BalanceMulticall(tokenIn.xToken.address, cpk.address, {
          label: "xTokenInCpkBalance",
        }),
        getErc20BalanceMulticall(tokenOut.xToken.address, cpk.address, {
          label: "xTokenOutCpkBalance",
        }),
        getErc20BalanceMulticall(
          connectedChain.contractsAddresses.smt,
          cpk.address,
          {
            label: "smtCpkBalance",
          }
        ),
      ],
      {
        rpcUrl: connectedChain.rpcUrl,
        multicallAddress: connectedChain.multicallAddress,
      }
    );

  const swapExactOutAmount = swaps.reduce((total, swapSequence) => {
    const lastSwap = swapSequence[swapSequence.length - 1];
    if (lastSwap === undefined) {
      return total;
    }
    return total.add(lastSwap.swapAmount);
  }, ZERO);

  const normalizedMaxAmountIn = getNormalizedDecimalsNumber(
    maxAmountIn,
    tokenIn.decimals
  );

  // The amount that BPoolProxy will transferFrom during the swap,
  // including the swap fee if it is payed in tokenIn
  const transferInAmount = normalizedMaxAmountIn.add(
    isSmtFeeDiscountApplied ? 0 : swapProtocolFee
  );

  // The amount the user will receive from the CPK
  const transferOutAmount = swapExactOutAmount.add(
    getBigNumber(xTokenOutCpkBalance)
  );

  const tokenInAmountToTransferToCPK = getBigNumber(xTokenInCpkBalance).lt(
    transferInAmount
  )
    ? transferInAmount.sub(getBigNumber(xTokenInCpkBalance))
    : ZERO;

  if (tokenInAmountToTransferToCPK.gt(0)) {
    // 1. Transfer from user to CPK
    cpkTransactions.push({
      to: tokenIn.address,
      data: Erc20Interface.encodeFunctionData("transferFrom", [
        connectedAddress,
        cpk.address,
        tokenInAmountToTransferToCPK.toFixed(0),
      ]),
    });
    // 2. Approve xToken wrapper to use amount
    cpkTransactions.push({
      to: tokenIn.address,
      data: Erc20Interface.encodeFunctionData("approve", [
        connectedChain.contractsAddresses.xTokenWrapper,
        tokenInAmountToTransferToCPK.toFixed(0),
      ]),
    });
    // 3. Wrap amount via xToken wrapper
    cpkTransactions.push({
      to: connectedChain.contractsAddresses.xTokenWrapper,
      data: XTokenWrapperInterface.encodeFunctionData("wrap", [
        tokenIn.address,
        tokenInAmountToTransferToCPK.toFixed(0),
      ]),
    });
  }

  if (isSmtFeeDiscountApplied) {
    const smtAmountToTransferToCPK = getBigNumber(smtCpkBalance).lt(
      swapProtocolFee
    )
      ? swapProtocolFee.sub(getBigNumber(smtCpkBalance))
      : ZERO;

    if (smtAmountToTransferToCPK.gt(0)) {
      // Transfer from user to CPK
      cpkTransactions.push({
        to: connectedChain.contractsAddresses.smt,
        data: Erc20Interface.encodeFunctionData("transferFrom", [
          connectedAddress,
          cpk.address,
          smtAmountToTransferToCPK.toFixed(0),
        ]),
      });
    }

    // Approve amount to BPoolProxy contract
    cpkTransactions.push({
      to: connectedChain.contractsAddresses.smt,
      data: Erc20Interface.encodeFunctionData("approve", [
        connectedChain.contractsAddresses.bpoolProxy,
        swapProtocolFee.toFixed(0),
      ]),
    });
  }

  // 1. Approve transferInAmount to BPoolProxy contract
  cpkTransactions.push({
    to: tokenIn.xToken.address,
    data: XTokenInterface.encodeFunctionData("approve", [
      connectedChain.contractsAddresses.bpoolProxy,
      transferInAmount.toFixed(0),
    ]),
  });

  // 2. Send multihopBatchSwapExactOut method to BPoolProxy
  cpkTransactions.push({
    to: connectedChain.contractsAddresses.bpoolProxy,
    data: BPoolProxyInterface.encodeFunctionData("multihopBatchSwapExactOut", [
      swaps,
      tokenIn.xToken.address,
      tokenOut.xToken.address,
      normalizedMaxAmountIn.toFixed(0),
      isSmtFeeDiscountApplied,
    ]),
  });

  // 3. Unwrap transferOutAmount via xToken wrapper
  cpkTransactions.push({
    to: connectedChain.contractsAddresses.xTokenWrapper,
    data: XTokenWrapperInterface.encodeFunctionData("unwrap", [
      tokenOut.xToken.address,
      transferOutAmount.toFixed(0),
    ]),
  });

  // 4. Transfer transferOutAmount from CPK to user
  cpkTransactions.push({
    to: tokenOut.address,
    data: Erc20Interface.encodeFunctionData("transfer", [
      connectedAddress,
      transferOutAmount.toFixed(0),
    ]),
  });

  // Finally, execute all transactions stored on CPK
  const gasLimit = await getCPKTransactionEstimatedGasLimit(
    cpk,
    cpkTransactions
  );

  return cpk.execTransactions(cpkTransactions, {
    gasLimit,
  });
}
