export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as
  | "mainnet"
  | "testnet";
export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const GOERLI_RPC_URL = process.env.REACT_APP_GOERLI_RPC_URL as string;
export const RINKEBY_RPC_URL = process.env.REACT_APP_RINKEBY_RPC_URL as string;
export const MUMBAI_RPC_URL = process.env.REACT_APP_MUMBAI_RPC_URL as string;
export const ETHEREUM_RPC_URL = process.env
  .REACT_APP_ETHEREUM_RPC_URL as string;
export const POLYGON_RPC_URL = process.env.REACT_APP_POLYGON_RPC_URL as string;

export const GOERLI_MULTICALL_ADDRESS = process.env
  .REACT_APP_GOERLI_MULTICALL_ADDRESS as string;
export const RINKEBY_MULTICALL_ADDRESS = process.env
  .REACT_APP_RINKEBY_MULTICALL_ADDRESS as string;
export const MUMBAI_MULTICALL_ADDRESS = process.env
  .REACT_APP_MUMBAI_MULTICALL_ADDRESS as string;
export const ETHEREUM_MULTICALL_ADDRESS = process.env
  .REACT_APP_ETHEREUM_MULTICALL_ADDRESS as string;
export const POLYGON_MULTICALL_ADDRESS = process.env
  .REACT_APP_POLYGON_MULTICALL_ADDRESS as string;

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;
export const GOERLI_SUBGRAPH_URL = process.env
  .REACT_APP_GOERLI_SUBGRAPH_URL as string;
export const RINKEBY_SUBGRAPH_URL = process.env
  .REACT_APP_RINKEBY_SUBGRAPH_URL as string;
export const MUMBAI_SUBGRAPH_URL = process.env
  .REACT_APP_MUMBAI_SUBGRAPH_URL as string;
export const ETHEREUM_SUBGRAPH_URL = process.env
  .REACT_APP_ETHEREUM_SUBGRAPH_URL as string;
export const POLYGON_SUBGRAPH_URL = process.env
  .REACT_APP_POLYGON_SUBGRAPH_URL as string;

export const BRIDGE_URL = process.env.REACT_APP_BRIDGE_URL as string;

export const TRADE_PLATFORM_BASE_URL = process.env
  .REACT_APP_TRADE_PLATFORM_BASE_URL as string;

export const DOCS_BASE_URL = process.env.REACT_APP_DOCS_BASE_URL as string;

export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL as string;
