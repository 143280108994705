import React from "react";

interface Props {}

export const PassportIcon: React.FC<Props> = (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 32"
      style={{ height: "100%" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.235.775c-.247 0-.488.088-.67.25a.848.848 0 0 0-.29.632v28.687c0 .242.108.469.29.632.182.162.423.25.67.25h20.081c1.11-.002 2.18-.396 2.972-1.104.793-.708 1.244-1.674 1.245-2.688V4.567c-.001-1.014-.453-1.98-1.245-2.689-.792-.707-1.862-1.102-2.972-1.103H1.236ZM2.2 29.462V2.539h19.117c.617 0 1.204.219 1.634.603.428.382.663.896.663 1.425v22.867c0 .529-.235 1.042-.663 1.425a2.458 2.458 0 0 1-1.634.603H2.199Z"
        fill="currentColor"
      />
      <path
        d="M12.636 21.233c4.146 0 7.52-3.374 7.52-7.52 0-4.148-3.374-7.522-7.52-7.522-4.147 0-7.52 3.374-7.52 7.521s3.373 7.52 7.52 7.52Zm.001-1.25c-.286-.045-.831-1.082-1.152-2.993h2.302c-.321 1.91-.866 2.948-1.15 2.993Zm5.944-8.25a6.172 6.172 0 0 1-.03 4.042c-.239-.069.119-.015-3.349-.035.111-1.262.12-2.607.007-3.966 3.384-.02 3.142.038 3.372-.04Zm-.556-1.21h-2.96c-.163-1.094-.415-2.09-.764-2.85a6.287 6.287 0 0 1 3.724 2.85Zm-2.973 6.467h2.919a6.285 6.285 0 0 1-3.67 2.761c.339-.74.588-1.7.75-2.76Zm-2.418-9.549c.29.046.849 1.114 1.168 3.083h-2.333c.319-1.969.877-3.037 1.165-3.083Zm-1.319 4.333h2.64a22.37 22.37 0 0 1-.007 3.966h-2.626a22.37 22.37 0 0 1-.007-3.966Zm-.345-4.101c-.348.76-.6 1.756-.763 2.85H7.246a6.287 6.287 0 0 1 3.724-2.85Zm-4.25 8.102a6.173 6.173 0 0 1-.03-4.042c.23.079.028.022 3.372.04a23.306 23.306 0 0 0 .007 3.967c-3.46.02-3.11-.034-3.348.035Zm3.5 1.215c.162 1.06.411 2.022.75 2.761a6.286 6.286 0 0 1-3.67-2.76h2.92ZM18.752 24.12H6.312c-.826 0-.827 1.25 0 1.25h12.44c.826 0 .827-1.25 0-1.25Z"
        fill="currentColor"
      />
    </svg>
  );
};
