import { Chain } from "@/constants/chains";
import { matchIsWrappedNativeToken } from "@/utils/matchIsWrappedNativeToken";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";
import { matchIsNativeSwapToken } from "@/pages/Swap/utils/matchIsNativeSwapToken";

export function matchIsUnwrappingNativeToken(
  tokenIn: SwapToken | NativeSwapToken | undefined,
  tokenOut: SwapToken | NativeSwapToken | undefined,
  connectedChain: Chain | null
): boolean {
  return (
    tokenIn !== undefined &&
    tokenOut !== undefined &&
    connectedChain !== null &&
    matchIsWrappedNativeToken(tokenIn, connectedChain) &&
    matchIsNativeSwapToken(tokenOut)
  );
}
