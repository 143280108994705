import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NumberFormat, { InputAttributes } from "react-number-format";

import type { Address } from "@/types/web3";
import { getBigNumber } from "@/utils/getBigNumber";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";
import { Flexbox } from "@/components/Flexbox";
import { TokenIcon } from "@/components/TokenIcon";
import { useDialog } from "@/components/Dialog";
import { Balance } from "@/components/Balance";

import { SwapFormSelectTokenDialog } from "../SwapFormSelectTokenDialog";

interface Props {
  selectedToken: SwapToken | NativeSwapToken | undefined;
  tokens: (SwapToken | NativeSwapToken)[] | undefined;
  value: string;
  maxAmount?: string;
  placeholder?: string;
  hasSetMaxButton?: boolean;
  setValue: (value: string) => void;
  setSelectedTokenAddress: (tokenAddress: Address) => void;
}

export const SwapFormTokenInput: React.FC<Props> = (props) => {
  const {
    selectedToken,
    tokens,
    value,
    maxAmount,
    placeholder = "0",
    hasSetMaxButton = false,
    setValue,
    setSelectedTokenAddress,
  } = props;

  const dialogProps = useDialog();

  const selectMaxAmount = (): void => {
    if (maxAmount === undefined) {
      return;
    }
    setValue(maxAmount);
  };

  return (
    <React.Fragment>
      <Flexbox
        direction="column"
        justifyContent="start"
        alignItems="start"
        sx={{ width: "100%" }}
      >
        <Flexbox
          direction="row"
          justifyContent="end"
          alignItems="center"
          spacing={0.5}
          sx={{ width: "100%", marginBottom: 0.75 }}
        >
          {hasSetMaxButton ? (
            <Button
              data-testid="max-button"
              variant="text"
              sx={{
                position: "relative",
                top: "1px", // visual alignment
                minWidth: "32px",
                padding: "2px 5px",
                fontSize: "0.75rem",
                fontWeight: 700,
              }}
              onClick={selectMaxAmount}
            >
              MAX
            </Button>
          ) : null}
          <Typography
            component="span"
            fontSize="0.875rem"
            sx={(theme) => {
              return {
                color: theme.custom.colors.gray,
              };
            }}
          >
            Balance:{" "}
            {selectedToken !== undefined &&
            selectedToken.balance !== undefined ? (
              <Balance
                value={selectedToken.balance}
                decimals={4}
                hasFixedDecimals={true}
              />
            ) : (
              "-"
            )}
          </Typography>
        </Flexbox>
        <StyledTextField
          placeholder={placeholder}
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            startAdornment: (
              <InputAdornment position="start">
                <Button variant="text" onClick={dialogProps.open}>
                  <Flexbox
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    spacing={1}
                  >
                    <TokenIcon
                      symbol={
                        selectedToken !== undefined ? selectedToken.symbol : "-"
                      }
                      width="24px"
                      height="24px"
                      hasEmptyAlt={true}
                    />
                    <Typography
                      component="span"
                      fontWeight={700}
                      sx={(theme) => {
                        return {
                          color: theme.custom.colors.black,
                        };
                      }}
                    >
                      {selectedToken !== undefined ? selectedToken.symbol : "-"}
                    </Typography>
                    <ExpandMoreIcon color="primary" />
                  </Flexbox>
                </Button>
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxValue: maxAmount,
            style: {
              fontSize: "1.25rem",
              textAlign: "right",
              padding: "12px",
            },
          }}
        />
      </Flexbox>

      <SwapFormSelectTokenDialog
        tokens={tokens !== undefined ? tokens : []}
        selectedTokenAddress={
          selectedToken !== undefined ? selectedToken.address : undefined
        }
        isOpen={dialogProps.isOpen}
        setSelectedTokenAddress={setSelectedTokenAddress}
        close={dialogProps.close}
      />
    </React.Fragment>
  );
};

const StyledTextField = styled(TextField)(({ theme }) => {
  return {
    width: "100%",
    height: "52px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.custom.colors.lightgray,
      },
      "&:hover fieldset": {
        borderColor: theme.custom.colors.lightgray,
        boxShadow: theme.custom.shadows.levelOne,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.custom.colors.primary,
        boxShadow: theme.custom.shadows.levelOne,
      },
    },
  };
});

interface NumberFormatCustomProps {
  value: string;
  maxValue: string | undefined;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const NumberFormatCustom = React.forwardRef<
  NumberFormat<InputAttributes>,
  NumberFormatCustomProps
>(function NumberFormatCustom(props, ref) {
  const { value, maxValue, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      value={value}
      getInputRef={ref}
      onValueChange={(values) => {
        // onValueChange is not same as onChange, it gets called on whenever there is change in value which can be caused by
        // any event like change or blur event or by a prop change
        if (values.value !== value) {
          onChange({ target: { name: "", value: values.value } });
        }
      }}
      thousandSeparator={true}
      isNumericString={true}
      isAllowed={(values) => {
        if (values.floatValue === undefined || maxValue === undefined) {
          return true;
        }
        return getBigNumber(values.floatValue).lte(maxValue);
      }}
    />
  );
});
