import Big, { BigSource } from "big.js";

import { BigNumber } from "@/types/web3";

export function getDenormalizedDecimalsNumber(
  denormalizedNumber: BigSource,
  decimals: number
): BigNumber {
  return new Big(denormalizedNumber).div(new Big(10).pow(decimals));
}
