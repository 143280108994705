import SwapRouter from "@swarmmarkets/smart-order-router";

import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";
import { getNormalizedDecimalsNumber } from "@/utils/getNormalizedDecimalsNumber";
import { getDenormalizedDecimalsNumber } from "@/utils/getDenormalizedDecimalsNumber";
import { getProtocolFee } from "./getProtocolFee";
import { getBigNumber } from "@/utils/getBigNumber";

export function getSwapMaxTokenInAmount(
  tokenIn: SwapToken | NativeSwapToken | undefined,
  maxTokentInAmount: string | undefined,
  swapRouter: SwapRouter | undefined,
  swapFeeProtocolFeePercentage: number,
  tokenAmountMinimumProtocolFeePercentage: number,
  isSmtFeeDiscountApplied: boolean
): string | undefined {
  if (
    tokenIn === undefined ||
    maxTokentInAmount === undefined ||
    swapRouter === undefined
  ) {
    return undefined;
  }

  const normalizedMaxTokenInAmount = getNormalizedDecimalsNumber(
    maxTokentInAmount,
    tokenIn.decimals
  );

  try {
    const exactInLimits = swapRouter.getExactInLimits(
      normalizedMaxTokenInAmount
    );

    if (isSmtFeeDiscountApplied) {
      return getDenormalizedDecimalsNumber(
        exactInLimits.totalSwapLimitInput,
        tokenIn.decimals
      ).toString();
    }

    const protocolFee = getProtocolFee(
      exactInLimits.totalSwapLimitInput,
      exactInLimits.totalSwapFeeAmount,
      swapFeeProtocolFeePercentage,
      tokenAmountMinimumProtocolFeePercentage
    );

    return getDenormalizedDecimalsNumber(
      getBigNumber(exactInLimits.totalSwapLimitInput).minus(protocolFee),
      tokenIn.decimals
    ).toString();
  } catch (error) {
    return undefined;
  }
}
