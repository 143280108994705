import React from "react";

import { useWallet } from "@/contexts/WalletContext";
import { useAssetTokenAuthorizationContracts } from "@/services/subgraph";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";

import { getSecurityRestrictionValidation } from "../utils/getSecurityRestrictionValidation";

interface ReturnValue {
  minimumEurAmountSecurityRestriction: string;
  isSecurityRestrictionCompliant: boolean | undefined;
}

export function useSecurityRestriction(
  tokenOut: SwapToken | NativeSwapToken | undefined,
  tokenOutAmount: string
): ReturnValue {
  const { connectedAddress, connectedChain } = useWallet();

  const { data: assetTokenAuthorizationContracts } =
    useAssetTokenAuthorizationContracts(
      connectedChain !== null ? connectedChain.subgraphUrl : undefined,
      tokenOut !== undefined ? tokenOut.address : undefined
    );

  const [
    minimumEurAmountSecurityRestriction,
    setMinimumEurAmountSecurityRestriction,
  ] = React.useState<string>("0");

  const [isSecurityRestrictionCompliant, setIsSecurityRestrictionCompliant] =
    React.useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    if (
      tokenOut === undefined ||
      tokenOutAmount === "" ||
      assetTokenAuthorizationContracts === undefined ||
      connectedAddress === null ||
      connectedChain === null
    ) {
      return;
    }

    let isCancelled = false;

    const updateIsShowingSecurityRestriction = async (): Promise<void> => {
      setIsSecurityRestrictionCompliant(undefined);

      const securityRestrictionValidations = await Promise.all(
        assetTokenAuthorizationContracts.map(
          (assetTokenAuthorizationContract) => {
            return getSecurityRestrictionValidation(
              tokenOut,
              tokenOutAmount,
              assetTokenAuthorizationContract,
              connectedAddress,
              connectedChain
            );
          }
        )
      );

      if (isCancelled) {
        return;
      }

      const invalidValidation = securityRestrictionValidations.find(
        (securityRestrictionValidation) => {
          return (
            securityRestrictionValidation.isSecurityRestrictionCompliant ===
            false
          );
        }
      );

      if (invalidValidation === undefined) {
        setMinimumEurAmountSecurityRestriction("0");
        setIsSecurityRestrictionCompliant(true);
        return;
      }

      setMinimumEurAmountSecurityRestriction(
        invalidValidation.minimumEurAmount.toString()
      );
      setIsSecurityRestrictionCompliant(false);
    };

    updateIsShowingSecurityRestriction();

    return () => {
      isCancelled = true;
    };
  }, [
    tokenOut,
    tokenOutAmount,
    assetTokenAuthorizationContracts,
    connectedAddress,
    connectedChain,
  ]);

  const value = React.useMemo<ReturnValue>(() => {
    return {
      minimumEurAmountSecurityRestriction,
      isSecurityRestrictionCompliant,
    };
  }, [minimumEurAmountSecurityRestriction, isSecurityRestrictionCompliant]);

  return value;
}
