import React from "react";

import { useStore } from "@/state";

import {
  selectLastConnectedWalletLabel,
  selectSetLastConnectedWalletLabel,
  selectClearLastConnectedWalletLabel,
} from "../selectors";

interface ReturnValue {
  lastConnectedWalletLabel: string | null;
  setLastConnectedWalletLabel: (label: string) => void;
  clearLastConnectedWalletLabel: () => void;
}

export function useLastConnectedWalletLabel(): ReturnValue {
  const lastConnectedWalletLabel = useStore(selectLastConnectedWalletLabel);
  const setLastConnectedWalletLabel = useStore(
    selectSetLastConnectedWalletLabel
  );
  const clearLastConnectedWalletLabel = useStore(
    selectClearLastConnectedWalletLabel
  );

  return React.useMemo<ReturnValue>(() => {
    return {
      lastConnectedWalletLabel,
      setLastConnectedWalletLabel,
      clearLastConnectedWalletLabel,
    };
  }, [
    lastConnectedWalletLabel,
    setLastConnectedWalletLabel,
    clearLastConnectedWalletLabel,
  ]);
}
