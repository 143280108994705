import React from "react";

interface Props {}

export const WalletIcon: React.FC<Props> = (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      style={{ height: "100%" }}
    >
      <path
        d="M2.93 1.4A2.946 2.946 0 0 0 0 4.33V22.89a2.62 2.62 0 0 0 2.605 2.604h22.79A2.62 2.62 0 0 0 28 22.89V7.912a2.62 2.62 0 0 0-2.605-2.605h-1.302v-2.93c0-.511-.465-.977-.977-.977H2.93Zm0 1.954h19.21v1.953H2.93a.954.954 0 0 1-.977-.976c0-.558.42-.977.977-.977Zm-.977 3.734c.307.11.635.173.977.173h22.465a.63.63 0 0 1 .652.651v2.93H17.58a1.971 1.971 0 0 0-1.953 1.954v5.209c0 1.065.888 1.954 1.953 1.954h8.466v2.93a.63.63 0 0 1-.652.65H2.605a.63.63 0 0 1-.652-.65V7.088Zm15.628 5.708h8.466v5.209H17.58v-5.21Zm2.605 1.302a1.302 1.302 0 1 0 0 2.605 1.302 1.302 0 0 0 0-2.605Z"
        fill="currentColor"
      />
    </svg>
  );
};
