import React from "react";
import Link from "@mui/material/Link";

import { ROUTES } from "@/routes";
import { Flexbox } from "@/components/Flexbox";

interface Props {}

export const PassportButton: React.FC<Props> = (props) => {
  return (
    <Link
      href={ROUTES.TRADE_PLATFORM_PASSPORT}
      target="_blank"
      rel="noopener"
      underline="none"
      aria-label="Passport"
      sx={(theme) => {
        return {
          "&:hover, &:active": {
            outline: theme.custom.borders.levelOne,
            boxShadow: theme.custom.shadows.levelOne,
          },
        };
      }}
    >
      <Flexbox
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={(theme) => {
          return {
            width: "40px",
            height: "40px",
            backgroundColor: theme.custom.colors.white,
            borderRadius: "4px",
          };
        }}
      >
        <svg
          viewBox="0 4.691 38.42 30.667"
          fill="#0078EF"
          xmlns="http://www.w3.org/2000/svg"
          style={{ height: 30 }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.235 4.775c-.247 0-.488.087-.67.25a.848.848 0 0 0-.29.632v28.686c0 .243.108.47.29.632.182.163.423.25.67.25h20.08c1.111-.001 2.18-.395 2.972-1.103.793-.708 1.245-1.675 1.246-2.689V8.566c-.002-1.014-.453-1.98-1.246-2.688-.792-.708-1.861-1.102-2.971-1.103H8.235Zm.964 28.687V6.538h19.117c.616 0 1.204.22 1.633.603.428.383.664.896.664 1.426v22.866c0 .53-.235 1.043-.664 1.426a2.458 2.458 0 0 1-1.634.602H9.2Z"
          />
          <path d="M19.636 25.234c4.146 0 7.52-3.374 7.52-7.521s-3.374-7.52-7.52-7.52c-4.147 0-7.52 3.373-7.52 7.52 0 4.147 3.373 7.52 7.52 7.52Zm.001-1.25c-.286-.045-.831-1.082-1.152-2.993h2.302c-.321 1.91-.866 2.948-1.15 2.993Zm5.944-8.25a6.172 6.172 0 0 1-.03 4.042c-.239-.069.119-.015-3.349-.035.111-1.262.12-2.607.007-3.966 3.384-.02 3.142.038 3.372-.041Zm-.556-1.21h-2.96c-.163-1.095-.416-2.09-.764-2.85a6.287 6.287 0 0 1 3.724 2.85Zm-2.973 6.467h2.919a6.286 6.286 0 0 1-3.67 2.761c.339-.74.588-1.7.75-2.76Zm-2.418-9.549c.29.046.849 1.114 1.168 3.083h-2.333c.319-1.97.877-3.037 1.165-3.083Zm-1.319 4.333h2.64a22.37 22.37 0 0 1-.006 3.966h-2.627a22.37 22.37 0 0 1-.007-3.966Zm-.345-4.101c-.348.76-.6 1.755-.763 2.85h-2.961a6.287 6.287 0 0 1 3.724-2.85Zm-4.25 8.102a6.173 6.173 0 0 1-.03-4.042c.23.079.028.022 3.372.04a23.304 23.304 0 0 0 .007 3.967c-3.46.02-3.11-.034-3.348.035Zm3.5 1.215c.162 1.06.411 2.022.75 2.761a6.285 6.285 0 0 1-3.67-2.76h2.92ZM25.752 28.118h-12.44c-.826 0-.827 1.25 0 1.25h12.44c.826 0 .827-1.25 0-1.25Z" />
        </svg>
      </Flexbox>
    </Link>
  );
};
