import CPK, { EthersAdapter } from "contract-proxy-kit";
import { ethers } from "ethers";

import { POLYGON_DETERMINISTIC_ADDRESSES } from "./constants";

export function createCpkInstance(signer: ethers.Signer): Promise<CPK> {
  const ethLibAdapter = new EthersAdapter({ ethers, signer });

  return CPK.create({
    ethLibAdapter,
    // Define non buit-in Polygon networks
    networks: {
      137: {
        ...POLYGON_DETERMINISTIC_ADDRESSES,
        proxyFactoryAddress: "0x1B5CFC0C4Ce241C9F7De29Ed60fEeA1a614A3457",
      },
      80001: {
        ...POLYGON_DETERMINISTIC_ADDRESSES,
        proxyFactoryAddress: "0x7983d49B5714b30eaC2B991ae011004992B9173c",
      },
    },
  });
}
