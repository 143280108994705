import React from "react";

// Directly copy pasted from
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/

type Callback = () => void;

export function useInterval(
  callback: Callback,
  delay: number | undefined
): void {
  const savedCallback = React.useRef<Callback>(callback);

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick(): void {
      savedCallback.current();
    }
    if (delay !== undefined) {
      const intervalId = setInterval(tick, delay);
      return () => {
        clearInterval(intervalId);
      };
    }
    return;
  }, [delay]);
}
