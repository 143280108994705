import React from "react";
import Box from "@mui/material/Box";

import { ChainKey } from "@/constants/chains";
import { Flexbox } from "@/components/Flexbox";

interface Props extends React.ComponentProps<typeof Box> {
  chainKey: ChainKey;
}

export const NetworkLogo: React.FC<Props> = (props) => {
  const { chainKey, ...boxProps } = props;

  const getNetworkLogo = (): React.ReactNode => {
    if (
      chainKey === "goerli" ||
      chainKey === "rinkeby" ||
      chainKey === "ethereum"
    ) {
      return (
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 18"
          style={{ height: "100%" }}
        >
          <path
            d="m5.878 12.312.12.12 5.525-3.266L6 0l-.12.41v11.902Z"
            fill="#62688F"
          />
          <path d="M6 12.432V0L.474 9.166l5.524 3.266Z" fill="#8A92B2" />
          <path
            d="M5.931 17.8 6 18l5.528-7.785L6 13.479l-.068.083v4.24Z"
            fill="#62688F"
          />
          <path d="M.474 10.214 6 18v-4.521L.474 10.214Z" fill="#8A92B2" />
          <path d="M5.999 6.655v5.777l5.524-3.266L6 6.656Z" fill="#454A75" />
          <path d="M5.999 6.655.474 9.166 6 12.432V6.655Z" fill="#62688F" />
        </svg>
      );
    }

    return (
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 16"
        style={{ height: "100%" }}
      >
        <path
          d="M13.63 4.89c-.33-.19-.753-.19-1.129 0L9.87 6.457l-1.785.997-2.585 1.567c-.33.19-.752.19-1.128 0L2.35 7.786a1.16 1.16 0 0 1-.564-.997V4.415c0-.38.188-.76.564-.997l2.02-1.187c.33-.19.753-.19 1.129 0l2.02 1.235c.33.19.565.57.565.997V6.03l1.785-1.045V3.371c0-.38-.188-.76-.564-.997L5.545.142c-.328-.19-.751-.19-1.127 0L.564 2.422c-.376.19-.564.57-.564.949v4.463c0 .38.188.76.564.997l3.807 2.231c.329.19.752.19 1.128 0l2.585-1.519 1.785-1.044 2.585-1.52c.33-.19.752-.19 1.128 0l2.021 1.187c.33.19.564.57.564.997v2.374c0 .38-.188.76-.564.997l-1.974 1.187c-.329.19-.752.19-1.128 0l-2.02-1.187a1.16 1.16 0 0 1-.565-.997v-1.52l-1.785 1.045v1.567c0 .38.188.76.564.997l3.806 2.232c.33.19.752.19 1.128 0l3.807-2.232c.329-.19.564-.57.564-.997V8.12c0-.38-.188-.76-.564-.997L13.629 4.89Z"
          fill="#8247E5"
        />
      </svg>
    );
  };

  return (
    <Flexbox
      {...boxProps}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {getNetworkLogo()}
    </Flexbox>
  );
};
