import React from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import Button from "@mui/material/Button";

import { useSwapAdvancedSettingsLocalStorage } from "@/services/local-storage";
import { Dialog } from "@/components/Dialog";
import { Flexbox } from "@/components/Flexbox";

import { SwapFormOnOffSwitch } from "../SwapFormOnOffSwitch";
import { SwapFormSlippageSelectInput } from "../SwapFormSlippageSelectInput";

const SLIPPAGE_TOLERANCE_OPTIONS = ["0.1", "0.5", "1"];

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const SwapFormAdvancedSettingsDialog: React.FC<Props> = (props) => {
  const { isOpen, close } = props;

  const { value: settings, setValue: setSettings } =
    useSwapAdvancedSettingsLocalStorage();

  const [slippageTolerance, setSlippageTolerance] = React.useState<string>(
    SLIPPAGE_TOLERANCE_OPTIONS.includes(settings.slippageTolerance.toString())
      ? settings.slippageTolerance.toString()
      : ""
  );
  const [customSlippageTolerance, setCustomSlippageTolerance] =
    React.useState<string>(
      SLIPPAGE_TOLERANCE_OPTIONS.includes(
        settings.slippageTolerance.toString()
      ) === false
        ? settings.slippageTolerance.toString()
        : ""
    );

  const [isSmtFeeDiscountEnabled, setIsAutoPayingFeeWithSmt] =
    React.useState<boolean>(settings.isSmtFeeDiscountEnabled);

  const save = (): void => {
    setSettings({
      slippageTolerance:
        customSlippageTolerance !== ""
          ? parseFloat(customSlippageTolerance)
          : parseFloat(slippageTolerance),
      isSmtFeeDiscountEnabled,
    });
    close();
  };

  return (
    <Dialog title="Advanced transaction settings" isOpen={isOpen} close={close}>
      <Flexbox
        direction="column"
        justifyContent="start"
        alignItems="start"
        spacing={3}
      >
        <Flexbox
          direction="column"
          justifyContent="start"
          alignItems="start"
          spacing={1}
        >
          <ConfigTitle
            label="Slippage tolerance"
            tooltipTitle="Your transaction will revert if the price changes unfavorably by more than this percentage."
          />
          <SwapFormSlippageSelectInput
            slippageTolerance={slippageTolerance}
            customSlippageTolerance={customSlippageTolerance}
            options={SLIPPAGE_TOLERANCE_OPTIONS}
            setSlippageTolerance={setSlippageTolerance}
            setCustomSlippageTolerance={setCustomSlippageTolerance}
          />
        </Flexbox>

        <Flexbox
          direction="column"
          justifyContent="start"
          alignItems="start"
          spacing={1}
        >
          <ConfigTitle
            label="Autopay platform fees in SMT (50% discount)"
            tooltipTitle="Autopay platform fees in SMT (50% discount)"
          />
          <SwapFormOnOffSwitch
            ariaLabel="Autopay platform fees in SMT (50% discount)"
            isChecked={isSmtFeeDiscountEnabled}
            setIsChecked={setIsAutoPayingFeeWithSmt}
          />
        </Flexbox>
      </Flexbox>

      <Button
        variant="contained"
        fullWidth={true}
        sx={(theme) => {
          return {
            marginTop: 4,
            height: "48px",
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: 600,
            boxShadow: theme.custom.shadows.levelOne,
            "&:hover": {
              boxShadow: theme.custom.shadows.levelTwo,
            },
          };
        }}
        onClick={save}
      >
        Save
      </Button>
    </Dialog>
  );
};

interface ConfigTitleProps {
  label: string;
  tooltipTitle: string;
}

const ConfigTitle: React.FC<ConfigTitleProps> = (props) => {
  const { label, tooltipTitle } = props;

  return (
    <Flexbox
      direction="row"
      justifyContent="start"
      alignItems="center"
      spacing={0.5}
    >
      <Typography
        component="span"
        fontSize="0.875rem"
        sx={(theme) => {
          return {
            fontWeight: 600,
            color: theme.custom.colors.lightblack,
          };
        }}
      >
        {label}
      </Typography>
      <Tooltip placement="top" title={tooltipTitle}>
        <HelpIcon
          sx={(theme) => {
            return {
              fontSize: "1.125rem",
              color: theme.custom.colors.gray,
            };
          }}
        />
      </Tooltip>
    </Flexbox>
  );
};
