import { getBigNumber } from "@/utils/getBigNumber";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";
import { matchIsSwapToken } from "@/pages/Swap/utils/matchIsSwapToken";

export function matchHasCpkLeftovers(
  tokenIn: SwapToken | NativeSwapToken | undefined,
  tokenOut: SwapToken | NativeSwapToken | undefined
): boolean {
  const willUseCpkBalance =
    tokenIn !== undefined &&
    matchIsSwapToken(tokenIn) &&
    tokenIn.xToken.cpkBalance !== undefined &&
    getBigNumber(tokenIn.xToken.cpkBalance).gt(0);

  const willReceiveCpkBalance =
    tokenOut !== undefined &&
    matchIsSwapToken(tokenOut) &&
    tokenOut.xToken.cpkBalance !== undefined &&
    getBigNumber(tokenOut.xToken.cpkBalance).gt(0);

  return willUseCpkBalance || willReceiveCpkBalance;
}
