import type { GasPrice, PolygonNetwork } from "../types";
import { getGweiEthers } from "../utils/getGweiEthers";

interface ResponsePolygonGasPrice {
  fast: {
    maxPriorityFee: number;
    maxFee: number;
  };
}

export const FALLBACK_GWEI_FEE = 50;

const GAS_STATION_URL_BY_NETWORK: Record<PolygonNetwork, string> = {
  polygon: "https://gasstation-mainnet.matic.network/v2",
  mumbai: "https://gasstation-mumbai.matic.today/v2",
};

export async function getPolygonGasPrice(
  network: PolygonNetwork
): Promise<GasPrice> {
  try {
    const responsePolygonGasPrice = await fetch(
      GAS_STATION_URL_BY_NETWORK[network]
    ).then<ResponsePolygonGasPrice>((response) => {
      return response.json();
    });

    const maxPriorityFeePerGas = getGweiEthers(
      responsePolygonGasPrice.fast.maxPriorityFee
    );

    const maxFeePerGas = getGweiEthers(responsePolygonGasPrice.fast.maxFee);

    return {
      maxPriorityFeePerGas,
      maxFeePerGas,
    };
  } catch (error) {
    return {
      maxPriorityFeePerGas: getGweiEthers(FALLBACK_GWEI_FEE),
      maxFeePerGas: getGweiEthers(FALLBACK_GWEI_FEE),
    };
  }
}
