import React from "react";

type Context = {
  containerWidth: number;
};

export const ContainerObserverContext = React.createContext<Context>({
  containerWidth: 0,
});

interface ContainerObserverContextProviderProps {
  containerElement: HTMLElement | null;
  children: React.ReactNode;
}

export const ContainerObserverContextProvider: React.FC<
  ContainerObserverContextProviderProps
> = (props) => {
  const { containerElement, children } = props;

  const [containerWidth, setContainerWidth] = React.useState<number>(0);

  const resizeObserverRef = React.useRef<ResizeObserver>(
    new ResizeObserver((entries) => {
      const containerElementEntry = entries[0];
      if (containerElementEntry === undefined) {
        return;
      }

      const borderBoxBlockSize =
        containerElementEntry.borderBoxSize[0]?.inlineSize;

      if (borderBoxBlockSize !== undefined) {
        setContainerWidth(borderBoxBlockSize);
      } else {
        setContainerWidth(containerElementEntry.contentRect.width);
      }
    })
  );

  React.useEffect(() => {
    if (containerElement === null) {
      return;
    }

    const observer = resizeObserverRef.current;

    observer.observe(containerElement);

    return () => {
      observer.disconnect();
    };
  }, [containerElement]);

  const value = React.useMemo<Context>(() => {
    return {
      containerWidth,
    };
  }, [containerWidth]);

  return (
    <ContainerObserverContext.Provider value={value}>
      {children}
    </ContainerObserverContext.Provider>
  );
};

export const useContainerObserver = () => {
  return React.useContext(ContainerObserverContext);
};
