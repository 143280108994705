import type { Address } from "@/types/web3";
import { Chain } from "@/constants/chains";

import { matchIsSameEthereumAddress } from "./matchIsSameEthereumAddress";

export function matchIsWrappedNativeToken(
  token: {
    address: Address;
  },
  connectedChain: Chain
): boolean {
  return matchIsSameEthereumAddress(
    token.address,
    connectedChain.wrappedNativeToken.address
  );
}
