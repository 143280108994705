import { Call } from "../types";

export function getTokenAmountMinimumProtocolFeePercentage(
  protocolFeeAddress: string,
  options: { label?: string } = {}
): Call {
  const { label = "tokenAmountMinimumProtocolFeePercentage" } = options;

  return {
    target: protocolFeeAddress,
    call: ["minProtocolFee()(uint256)"],
    label,
  };
}
