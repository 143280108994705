import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { ROUTES } from "@/routes";
import { Chain } from "@/constants/chains";
import { Flexbox } from "@/components/Flexbox";
import { Configuration } from "@/state/configuration";

import { LayoutHeader } from "./components/LayoutHeader";

interface Props {
  children: React.ReactNode;
  connectedAddress: string | null;
  chains: Chain[];
  connectedChain: Chain | null;
  configuration: Configuration;
  setChain: (options: { chainId: string }) => void;
  connect: () => void;
  disconnect: () => void;
}

export const Layout: React.FC<Props> = (props) => {
  const {
    children,
    connectedAddress,
    chains,
    connectedChain,
    configuration,
    setChain,
    connect,
    disconnect,
  } = props;

  return (
    <Flexbox
      direction="column"
      justifyContent="start"
      alignItems="start"
      sx={(theme) => {
        return {
          minHeight: "100vh",
          backgroundColor: theme.custom.colors.background.default,
        };
      }}
    >
      <Box
        sx={(theme) => {
          return {
            flexShrink: 0,
            width: "100%",
            zIndex: theme.custom.zIndexes.layerTwo,
          };
        }}
      >
        <LayoutHeader
          connectedAddress={connectedAddress}
          chains={chains}
          connectedChain={connectedChain}
          configuration={configuration}
          setChain={setChain}
          connect={connect}
          disconnect={disconnect}
        />
      </Box>
      <Flexbox
        direction="row"
        justifyContent="start"
        alignItems="stretch"
        sx={{ flexShrink: 0, width: "100%" }}
      >
        <Box
          sx={{
            flex: 1,
            maxWidth: "100%",
            paddingX: 2,
            paddingY: 2,
          }}
        >
          {children}
        </Box>
      </Flexbox>
      <Flexbox
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          flexShrink: 0,
          width: "100%",
          paddingBottom: 3,
        }}
      >
        <Typography
          sx={(theme) => {
            return {
              fontSize: "0.75rem",
              color: theme.custom.colors.gray,
            };
          }}
        >
          Powered by{" "}
          <Link
            href={ROUTES.WEBSITE}
            target="_blank"
            rel="noopener"
            underline="hover"
            sx={(theme) => {
              return {
                color: theme.custom.colors.gray,
              };
            }}
          >
            Swarm
          </Link>{" "}
          -{" "}
          <Link
            href={ROUTES.DOCS_PRIVACY}
            target="_blank"
            rel="noopener"
            underline="hover"
            sx={(theme) => {
              return {
                color: theme.custom.colors.gray,
              };
            }}
          >
            Privacy
          </Link>{" "}
          /{" "}
          <Link
            href={ROUTES.DOCS_TERMS}
            target="_blank"
            rel="noopener"
            underline="hover"
            sx={(theme) => {
              return {
                color: theme.custom.colors.gray,
              };
            }}
          >
            Terms
          </Link>{" "}
          /{" "}
          <Link
            href={ROUTES.DOCS_IMPRINT}
            target="_blank"
            rel="noopener"
            underline="hover"
            sx={(theme) => {
              return {
                color: theme.custom.colors.gray,
              };
            }}
          >
            Imprint
          </Link>
        </Typography>
      </Flexbox>
      {/*<Box
        sx={(theme) => {
          return {
            flexShrink: 0,
            margin: "0 auto 24px",
            ...getMinContainerWidthCSS(
              containerWidth,
              theme.breakpoints.values["desktop"],
              {
                display: "none",
              }
            ),
          };
        }}
      >
        <LayoutTabs />
      </Box>*/}
    </Flexbox>
  );
};
