import type { StoreSlice } from "../store";

import type { Configuration } from "./types";

export interface ConfigurationStoreSlice {
  configuration: Configuration;
  setConfiguration: (configuration: Partial<Configuration>) => void;
}

export const createConfigurationStoreSlice: StoreSlice<
  ConfigurationStoreSlice
> = (set, get) => {
  return {
    configuration: {
      widgetKey: "",
      supportedChains: [],
      supportedAssetsByChainKey: {},
      preOnboardingContent: "",
      smallLogoUrl: undefined,
      largeLogoUrl: undefined,
    },
    setConfiguration: (configuration) => {
      const currentConfiguration = get().configuration;

      set({
        configuration: {
          ...currentConfiguration,
          ...configuration,
        },
      });
    },
  };
};
