import type { Address, BigNumber, EthersBigNumber } from "@/types/web3";
import { ZERO } from "@/constants/one-time-values";
import { Chain } from "@/constants/chains";
import {
  getBatchedValuesOnce,
  getAssetTokenSecurityTokenId,
  getFiatAmountValidation,
  getHasAssetTokenSecurityToken,
  getValidateAsSecurityToken,
} from "@/services/multicall";
import { getBigNumber } from "@/utils/getBigNumber";
import { captureException } from "@/services/sentry";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";
import { getDenormalizedDecimalsNumber } from "@/utils/getDenormalizedDecimalsNumber";

interface SecurityRestrictionValidation {
  minimumEurAmount: BigNumber;
  isSecurityRestrictionCompliant: boolean;
}

export async function getSecurityRestrictionValidation(
  tokenOut: SwapToken | NativeSwapToken,
  tokenOutAmount: string,
  authorizationContractAddress: Address,
  connectedAddress: string,
  connectedChain: Chain
): Promise<SecurityRestrictionValidation> {
  let hasSecurityTokenValidation = false;
  let minimumEurAmount = ZERO;

  try {
    const batchedValues = await getBatchedValuesOnce<{
      validateAsSecurityToken: boolean;
      fiatAmountValidation: EthersBigNumber;
    }>(
      [
        getValidateAsSecurityToken(authorizationContractAddress, {
          label: "validateAsSecurityToken",
        }),
        getFiatAmountValidation(authorizationContractAddress, {
          label: "fiatAmountValidation",
        }),
      ],
      {
        rpcUrl: connectedChain.rpcUrl,
        multicallAddress: connectedChain.multicallAddress,
      }
    );

    hasSecurityTokenValidation = batchedValues.validateAsSecurityToken;
    minimumEurAmount = getDenormalizedDecimalsNumber(
      getBigNumber(batchedValues.fiatAmountValidation),
      18 // fiatAmountValidation has 18 implicit decimals on the contract
    );
  } catch (error) {
    // Failing to retrieve the values means the authorization contract does not have the expected fields
    // We still report the exception in case this is a mistake
    captureException(error as Error, { authorizationContractAddress });
    return {
      minimumEurAmount,
      isSecurityRestrictionCompliant: true,
    };
  }

  if (hasSecurityTokenValidation === false) {
    return {
      minimumEurAmount,
      isSecurityRestrictionCompliant: true,
    };
  }

  if (tokenOut.eurPrice === undefined) {
    captureException(new Error("EUR price is not defined"), {
      tokenOut,
      authorizationContractAddress,
    });
    return {
      minimumEurAmount,
      isSecurityRestrictionCompliant: true,
    };
  }

  if (
    getBigNumber(tokenOutAmount).times(tokenOut.eurPrice).gte(minimumEurAmount)
  ) {
    return {
      minimumEurAmount,
      isSecurityRestrictionCompliant: true,
    };
  }

  try {
    const batchedValuesSecurityTokenId = await getBatchedValuesOnce<{
      securityTokenId: EthersBigNumber;
    }>(
      [
        getAssetTokenSecurityTokenId(
          connectedChain.contractsAddresses.permissionManager,
          tokenOut.address,
          {
            label: "securityTokenId",
          }
        ),
      ],
      {
        rpcUrl: connectedChain.rpcUrl,
        multicallAddress: connectedChain.multicallAddress,
      }
    );

    const batchedValuesHasSecurityToken = await getBatchedValuesOnce<{
      hasSecurityToken: boolean;
    }>(
      [
        getHasAssetTokenSecurityToken(
          connectedChain.contractsAddresses.permissionManager,
          connectedAddress,
          batchedValuesSecurityTokenId.securityTokenId.toString(),
          { label: "hasSecurityToken" }
        ),
      ],
      {
        rpcUrl: connectedChain.rpcUrl,
        multicallAddress: connectedChain.multicallAddress,
      }
    );

    return {
      minimumEurAmount,
      isSecurityRestrictionCompliant:
        batchedValuesHasSecurityToken.hasSecurityToken,
    };
  } catch (error) {
    // Failing to retrieve the permission tokens here means we want to still show the error just in case
    // We still report the exception as this is a mistake
    captureException(error as Error, {
      authorizationContractAddress,
      permissionManagerContractAddress:
        connectedChain.contractsAddresses.permissionManager,
      tokenAddress: tokenOut.address,
      connectedAddress,
    });
    return {
      minimumEurAmount,
      isSecurityRestrictionCompliant: false,
    };
  }
}
