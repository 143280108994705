import { ethers, PopulatedTransaction, Signer } from "ethers";

import type { TransactionGasEstimationOptions } from "../types";
import { DEFAULT_ADDED_GAS_LIMIT_PERCENTAGE } from "../constants";

export async function getTransactionEstimatedGasLimit(
  transaction: PopulatedTransaction,
  signer?: Signer,
  options: TransactionGasEstimationOptions = {}
): Promise<number | undefined> {
  if (signer === undefined) {
    return undefined;
  }

  const { addedGasLimitPercentage = DEFAULT_ADDED_GAS_LIMIT_PERCENTAGE } =
    options;

  let estimatedGasLimit = ethers.constants.Zero;

  try {
    estimatedGasLimit = await signer.estimateGas(transaction);
  } catch {
    return undefined;
  }

  const gasLimit = estimatedGasLimit
    .mul(100 + addedGasLimitPercentage)
    .div(100);

  return gasLimit.toNumber();
}
