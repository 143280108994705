import { LocalStorageService } from "../instance";
import { jsonEncoder, jsonDecoder } from "../codecs";
import { useLocalStorage } from "../useLocalStorage";

export const swapAdvancedSettingsStorage = new LocalStorageService<{
  slippageTolerance: number;
  isSmtFeeDiscountEnabled: boolean;
}>(
  "swap-advanced-settings",
  jsonEncoder(),
  jsonDecoder({
    slippageTolerance: 0.1,
    isSmtFeeDiscountEnabled: true,
  })
);

export const useSwapAdvancedSettingsLocalStorage = () => {
  return useLocalStorage(swapAdvancedSettingsStorage);
};
