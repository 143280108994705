import type { BigSource } from "@/types/web3";

import { getBigNumber } from "@/utils/getBigNumber";

export function getProtocolFee(
  normalizedAmount: BigSource,
  totalSwapFeeAmount: BigSource,
  swapFeeProtocolFeePercentage: number,
  tokenAmountMinimumProtocolFeePercentage: number
) {
  const swapProtocolFee = getBigNumber(totalSwapFeeAmount)
    .mul(swapFeeProtocolFeePercentage)
    .div(100);
  const minProtocolFee = getBigNumber(normalizedAmount)
    .mul(tokenAmountMinimumProtocolFeePercentage)
    .div(100);

  return swapProtocolFee.gt(minProtocolFee) ? swapProtocolFee : minProtocolFee;
}
