import type { Theme } from "@mui/material/styles";

interface Colors {
  main: string;
  light: string;
}

export function getPriceChangePercentageColor(
  theme: Theme,
  priceChangePercentage: number
): Colors {
  if (priceChangePercentage > 0) {
    return theme.palette.success;
  }

  if (priceChangePercentage < 0) {
    return theme.palette.error;
  }

  return theme.palette.warning;
}
