import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import ReactMarkdown from "react-markdown";

import { Dialog } from "@/components/Dialog";
import { Flexbox } from "@/components/Flexbox";

interface Props {
  content: string;
  isOpen: boolean;
  openOnboarding: () => void;
  close: () => void;
}

export const SwapFormOnboardingDialog: React.FC<Props> = (props) => {
  const { content, isOpen, openOnboarding, close } = props;

  return (
    <Dialog title="Terms and Agreements" isOpen={isOpen} close={close}>
      <Flexbox
        direction="column"
        justifyContent="start"
        alignItems="start"
        spacing={3}
      >
        <ReactMarkdown
          components={{
            // eslint-disable-next-line id-length
            p: (props) => {
              return <Typography {...props} fontSize="0.875rem" />;
            },
            // eslint-disable-next-line id-length
            a: (props) => {
              return (
                <Link
                  {...props}
                  color="primary"
                  target="_blank"
                  rel="noopener"
                />
              );
            },
          }}
        >
          {content}
        </ReactMarkdown>

        <Flexbox
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={(theme) => {
              return {
                flex: 1,
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 600,
                boxShadow: theme.custom.shadows.levelOne,
                "&:hover": {
                  boxShadow: theme.custom.shadows.levelTwo,
                },
              };
            }}
            onClick={close}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={(theme) => {
              return {
                flex: 1,
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: 600,
                boxShadow: theme.custom.shadows.levelOne,
                "&:hover": {
                  boxShadow: theme.custom.shadows.levelTwo,
                },
              };
            }}
            onClick={openOnboarding}
          >
            Continue
          </Button>
        </Flexbox>
      </Flexbox>
    </Dialog>
  );
};
