export const POLYGON_DETERMINISTIC_ADDRESSES = {
  masterCopyAddressVersions: [
    {
      version: "1.2.0",
      address: "0x6851D6fDFAfD08c0295C392436245E5bc78B0185",
    },
    {
      version: "1.1.1",
      address: "0x34CfAC646f301356fAa8B21e94227e3583Fe3F5F",
    },
  ],
  multiSendAddress: "0x8D29bE29923b68abfDD21e541b9374737B49cdAD",
  fallbackHandlerAddress: "0xd5D82B6aDDc9027B22dCA772Aa68D5d74cdBdF44",
};
