import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { Chain } from "@/constants/chains";
import { Flexbox } from "@/components/Flexbox";
import { NetworkLogo } from "@/components/NetworkLogo";

interface Props {
  chains: Chain[];
  setChain: (options: { chainId: string }) => void;
}

export const NonSupportedChain: React.FC<Props> = (props) => {
  const { chains, setChain } = props;

  return (
    <Flexbox
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={(theme) => {
        return {
          width: "100%",
          height: "100vh",
          backgroundColor: theme.custom.colors.background.default,
        };
      }}
    >
      <Flexbox
        direction="column"
        justifyContent="start"
        alignItems="center"
        sx={(theme) => {
          return {
            width: "500px",
            maxWidth: "90%",
            backgroundColor: theme.custom.colors.white,
            padding: 4,
            borderRadius: 2,
            boxShadow: theme.custom.shadows.levelTwo,
          };
        }}
      >
        <Typography variant="h5" marginBottom={2}>
          Non-supported chain
        </Typography>
        <Typography
          variant="subtitle1"
          marginBottom={2}
          sx={(theme) => {
            return {
              color: theme.custom.colors.gray,
              textAlign: "center",
            };
          }}
        >
          You are currently connected to a non-supported chain. Please switch to
          one of the following:
        </Typography>
        <Flexbox
          direction="column"
          justifyContent="start"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          {chains.map((chain) => {
            return (
              <Flexbox
                key={chain.id}
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                <Flexbox
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  sx={{ flexShrink: 0 }}
                >
                  <NetworkLogo
                    chainKey={chain.key}
                    width="20px"
                    height="20px"
                    marginRight={1}
                  />
                  <Typography variant="subtitle2" sx={{ width: "60px" }}>
                    {chain.label}
                  </Typography>
                </Flexbox>

                <Button
                  data-testid={`${chain.label}-switch-button`}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setChain({ chainId: chain.id });
                  }}
                  sx={{ flexShrink: 0, marginLeft: 3 }}
                >
                  Switch
                </Button>
              </Flexbox>
            );
          })}
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};
