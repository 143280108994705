import React from "react";
import Typography from "@mui/material/Typography";

interface Props {
  children: string;
}

export const SwapRecentTransactionsStateMessage: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <Typography
      fontWeight={500}
      sx={(theme) => {
        return {
          marginTop: 2,
          color: theme.custom.colors.gray,
        };
      }}
    >
      {children}
    </Typography>
  );
};
