import React from "react";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";

import { useContainerObserver } from "@/contexts/ContainerObserverContext";
import { matchIsSameSymbol } from "@/utils/matchIsSameSymbol";
import { getBigNumber } from "@/utils/getBigNumber";
import { getMinContainerWidthCSS } from "@/utils/getContainerWidthCSSMediaQuery";
import { Flexbox } from "@/components/Flexbox";
import { Balance } from "@/components/Balance";

import { SwapFormCheckSwitch } from "../SwapFormCheckSwitch";

interface Props {
  swapFee: string | undefined;
  swapFeeTokenSymbol: string | undefined;
  protocolFee: string | undefined;
  protocolFeeSymbol: string | undefined;
  isSmtFeeDiscountEnabled: boolean;
  isSmtFeeDiscountAvailable: boolean;
  setIsSmtFeeDiscountEnabled: (isSmtFeeDiscountEnabled: boolean) => void;
}

export const SwapFormTransactionDetails: React.FC<Props> = (props) => {
  const {
    swapFee,
    swapFeeTokenSymbol,
    protocolFee,
    protocolFeeSymbol,
    isSmtFeeDiscountEnabled,
    isSmtFeeDiscountAvailable,
    setIsSmtFeeDiscountEnabled,
  } = props;

  const { containerWidth } = useContainerObserver();

  return (
    <Flexbox
      direction="column"
      justifyContent="start"
      alignItems="start"
      spacing={0.5}
      sx={{ width: "100%" }}
    >
      <Flexbox
        direction="row"
        justifyContent="start"
        alignItems="center"
        spacing={0.5}
        sx={{ width: "100%" }}
      >
        <Typography
          component="span"
          fontSize="0.875rem"
          sx={(theme) => {
            return {
              color: theme.custom.colors.gray,
            };
          }}
        >
          Transaction fee:{" "}
          {swapFee !== undefined &&
          swapFeeTokenSymbol !== undefined &&
          protocolFee !== undefined &&
          protocolFeeSymbol !== undefined ? (
            <Typography
              component="span"
              fontSize="inherit"
              sx={(theme) => {
                return {
                  color: theme.custom.colors.lightblack,
                };
              }}
            >
              {matchIsSameSymbol(swapFeeTokenSymbol, protocolFeeSymbol) ? (
                <React.Fragment>
                  <Balance
                    value={getBigNumber(swapFee).add(protocolFee).toString()}
                    decimals={4}
                  />{" "}
                  {swapFeeTokenSymbol}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Balance value={swapFee} decimals={4} /> {swapFeeTokenSymbol}{" "}
                  + <Balance value={protocolFee} decimals={4} />{" "}
                  {protocolFeeSymbol}
                </React.Fragment>
              )}
            </Typography>
          ) : (
            "-"
          )}
        </Typography>
        <Tooltip
          placement="top"
          title={
            swapFee !== undefined && protocolFee !== undefined ? (
              <span>
                Liquidity Provider Fee: <Balance value={swapFee} decimals={6} />{" "}
                {swapFeeTokenSymbol} (remains in pool) + Protocol Fee:{" "}
                <Balance value={protocolFee} decimals={6} /> {protocolFeeSymbol}
              </span>
            ) : (
              "-"
            )
          }
        >
          <HelpIcon
            sx={(theme) => {
              return {
                fontSize: "1.125rem",
                opacity: 0.5,
                color: theme.custom.colors.gray,
              };
            }}
          />
        </Tooltip>
      </Flexbox>

      <Flexbox
        direction={["column", "row"]}
        justifyContent="start"
        alignItems={["start", "center"]}
        spacing={[3, 0]}
        sx={{ width: "100%" }}
      >
        <Flexbox
          direction="row"
          justifyContent="start"
          alignItems="center"
          spacing={0.5}
          sx={(theme) => {
            return {
              ...getMinContainerWidthCSS(
                containerWidth,
                theme.breakpoints.values["tablet"],
                {
                  flex: 1,
                }
              ),
            };
          }}
        >
          <Typography
            component="span"
            fontSize="0.875rem"
            sx={(theme) => {
              return {
                color: theme.custom.colors.gray,
              };
            }}
          >
            Price impact:{" "}
            <Typography
              component="span"
              fontSize="inherit"
              sx={(theme) => {
                return {
                  color: theme.palette.success.light,
                };
              }}
            >
              {"<0.01%"}
            </Typography>
          </Typography>
          <Tooltip placement="top" title="Price impact">
            <HelpIcon
              sx={(theme) => {
                return {
                  fontSize: "1.125rem",
                  opacity: 0.5,
                  color: theme.custom.colors.gray,
                };
              }}
            />
          </Tooltip>
        </Flexbox>
        <Flexbox
          direction="row"
          justifyContent="start"
          alignItems="center"
          spacing={1.5}
          sx={(theme) => {
            return {
              ...getMinContainerWidthCSS(
                containerWidth,
                theme.breakpoints.values["tablet"],
                {
                  flexShrink: 0,
                }
              ),
            };
          }}
        >
          <Flexbox
            direction="row"
            justifyContent="start"
            alignItems="center"
            spacing={0.5}
          >
            <Typography
              component="span"
              fontSize="0.875rem"
              sx={(theme) => {
                return {
                  color: theme.custom.colors.gray,
                };
              }}
            >
              SMT fee discount
            </Typography>
            <Tooltip
              placement="top"
              title="Enable 50% protocol fee discount when paid in SMT. Discount not available on all pairs."
            >
              <HelpIcon
                sx={(theme) => {
                  return {
                    fontSize: "1.125rem",
                    opacity: 0.5,
                    color: theme.custom.colors.gray,
                  };
                }}
              />
            </Tooltip>
          </Flexbox>
          <SwapFormCheckSwitch
            ariaLabel="SMT fee discount"
            isChecked={isSmtFeeDiscountEnabled && isSmtFeeDiscountAvailable}
            isDisabled={isSmtFeeDiscountAvailable === false}
            setIsChecked={setIsSmtFeeDiscountEnabled}
          />
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
};
