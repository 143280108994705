import React from "react";
import { SwapRoutesDiff, SwapTxType } from "@swarmmarkets/smart-order-router";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import WhatshotIcon from "@mui/icons-material/Whatshot";

import { ZERO } from "@/constants/one-time-values";
import type {
  NativeSwapToken,
  SwapOptimizationStrategy,
  SwapToken,
} from "@/pages/Swap/types";
import { Flexbox } from "@/components/Flexbox";

import { getSwapOptimisationPriceDifference } from "../../utils/getSwapOptimisationPriceDifference";

interface Props {
  swapOptimizationStrategy: SwapOptimizationStrategy;
  swapStrategiesDiff: SwapRoutesDiff | null;
  swapType: SwapTxType;
  tokenIn: SwapToken | NativeSwapToken | undefined;
  tokenOut: SwapToken | NativeSwapToken | undefined;
  setSwapOptimizationStrategy: (
    swapOptimizationStrategy: SwapOptimizationStrategy
  ) => void;
}

export const SwapFormOptimizeForSelector = (props: Props) => {
  const {
    swapOptimizationStrategy,
    swapStrategiesDiff,
    swapType,
    tokenIn,
    tokenOut,
    setSwapOptimizationStrategy,
  } = props;

  const swapOptimisationPriceDifference = React.useMemo(() => {
    if (
      swapStrategiesDiff === null ||
      tokenIn === undefined ||
      tokenOut === undefined
    ) {
      return ZERO;
    }

    return getSwapOptimisationPriceDifference(
      swapType,
      swapStrategiesDiff,
      tokenIn,
      tokenOut
    );
  }, [swapType, swapStrategiesDiff, tokenIn, tokenOut]);

  // Handle case where user selects gas price optimization strategy and
  // then switches to a pair that has no optimisation price difference
  React.useEffect(() => {
    if (swapOptimisationPriceDifference.eq(0)) {
      setSwapOptimizationStrategy("price");
    }
  }, [swapOptimisationPriceDifference, setSwapOptimizationStrategy]);

  const getPriceOptimizationButtonTitle = (): string => {
    if (swapOptimisationPriceDifference.lte(0)) {
      return "Your price is already the best!";
    }

    if (swapType === SwapTxType.exactIn) {
      return tokenOut !== undefined
        ? `You will receive ${swapOptimisationPriceDifference} more ${tokenOut.symbol}`
        : "";
    }

    return tokenIn !== undefined
      ? `You will spend ${swapOptimisationPriceDifference} less ${tokenIn.symbol}`
      : "";
  };

  return (
    <Flexbox
      direction="column"
      justifyContent="start"
      alignItems="start"
      sx={{
        width: "100%",
      }}
    >
      <Typography
        sx={(theme) => {
          return {
            fontSize: "0.875rem",
            fontWeight: 600,
            color: theme.custom.colors.gray,
          };
        }}
      >
        Optimized for
      </Typography>

      <Flexbox
        direction={["column", "column", "row"]}
        justifyContent="start"
        alignItems="start"
        sx={(theme) => {
          return {
            marginTop: 1,
            width: "100%",
            padding: "4px",
            borderRadius: "4px",
            backgroundColor: theme.custom.colors.background.default,
          };
        }}
      >
        <Tooltip placement="bottom" title={getPriceOptimizationButtonTitle()}>
          <StyledButton
            startIcon={<WhatshotIcon />}
            $isActive={swapOptimizationStrategy === "price"}
            onClick={() => {
              setSwapOptimizationStrategy("price");
            }}
          >
            Best price
          </StyledButton>
        </Tooltip>
        {swapOptimisationPriceDifference.gt(0) ? (
          <StyledButton
            startIcon={<PaidOutlinedIcon />}
            $isActive={swapOptimizationStrategy === "gas"}
            onClick={() => {
              setSwapOptimizationStrategy("gas");
            }}
          >
            Lowest gas cost
          </StyledButton>
        ) : null}
      </Flexbox>

      <Typography
        sx={(theme) => {
          return {
            width: "calc(100% - 8px)",
            marginLeft: "4px",
            fontSize: "0.625rem",
            lineHeight: "20px",
            textAlign: "center",
            borderRadius: "2px",
            borderLeft: "1px solid",
            borderRight: "1px solid",
            borderBottom: "1px solid",
            borderColor: theme.custom.colors.background.primary,
          };
        }}
      >
        {swapOptimizationStrategy === "price"
          ? "Trade across multiple pools to get the best price."
          : "Trade using the shortest path. Save on gas."}
      </Typography>
    </Flexbox>
  );
};

interface StyledButtonProps {
  $isActive: boolean;
}

const StyledButton = styled(Button)<StyledButtonProps>(
  ({ theme, $isActive }) => {
    return {
      flex: 1,

      width: "100%",
      height: "28px",

      fontSize: "1rem",
      color: theme.custom.colors.black,
      textTransform: "none",

      ...($isActive
        ? {
            color: theme.custom.colors.primary,
            backgroundColor: theme.custom.colors.white,
            "&:hover, &:focus": {
              backgroundColor: theme.custom.colors.white,
            },
          }
        : {}),
    };
  }
);
