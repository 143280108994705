import React from "react";
import Link from "@mui/material/Link";

import { ROUTES } from "@/routes";
import { getParsedEthersError } from "@/utils/getParsedEthersError";

export function getSwapEthersErrorTitle(ethersError: Error): React.ReactNode {
  const parsedEthersError = getParsedEthersError(ethersError);

  if (
    parsedEthersError.errorCode === "EXECUTION_REVERTED" &&
    parsedEthersError.context !== undefined
  ) {
    return parsedEthersError.context;
  }

  if (
    parsedEthersError.errorCode === "REJECTED_TRANSACTION" &&
    parsedEthersError.context !== undefined
  ) {
    return parsedEthersError.context;
  }

  if (parsedEthersError.errorCode === "TRANSACTION_UNDERPRICED") {
    return "Transaction underpriced. Please increase the gas price.";
  }

  if (parsedEthersError.errorCode === "TRANSACTION_RAN_OUT_OF_GAS") {
    return "Transaction ran out of gas. Please increase the gas limit.";
  }

  return (
    <span>
      This transaction is expected to fail. See our{" "}
      <Link
        href={ROUTES.DOCS_FAQ}
        target="_blank"
        rel="noopener"
        color="primary"
      >
        FAQ
      </Link>{" "}
      for possible reasons.
    </span>
  );
}
