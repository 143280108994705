import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import CheckIcon from "@mui/icons-material/Check";

const thumbStyles = {
  width: "22px",
  height: "22px",
  margin: 3,
  borderRadius: "2px",
};

const StyledSwitch = styled(Switch)(({ theme }) => {
  return {
    width: 52,
    height: 30,
    padding: 0,
    border: theme.custom.borders.lightgray,
    borderRadius: "4px",

    "&:focus-within": {
      borderColor: theme.custom.colors.primary,
    },

    "& .MuiSwitch-switchBase": {
      padding: 0,

      "&:hover": {
        background: "none",
      },

      "&.Mui-checked": {
        ...thumbStyles,
        backgroundColor: theme.custom.colors.primary,

        "& + .MuiSwitch-track": {
          backgroundColor: "transparent",
        },
      },

      "&.Mui-disabled": {
        "& + .MuiSwitch-track": {
          backgroundColor: theme.custom.colors.gray,

          "&:hover": {
            cursor: "not-allowed",
          },
        },
      },
    },
    "& .MuiSwitch-thumb": {
      ...thumbStyles,
      backgroundColor: theme.custom.colors.gray,
    },
    "& .MuiSwitch-track": {
      backgroundColor: "transparent",
      borderRadius: "2px",
    },
  };
});

interface Props {
  ariaLabel: string;
  isChecked: boolean;
  isDisabled?: boolean;
  setIsChecked: (isChecked: boolean) => void;
}

export const SwapFormCheckSwitch: React.FC<Props> = (props) => {
  const { ariaLabel, isChecked, isDisabled = false, setIsChecked } = props;

  return (
    <StyledSwitch
      checkedIcon={
        <CheckIcon
          sx={(theme) => {
            return { fontSize: "0.875rem", color: theme.custom.colors.white };
          }}
        />
      }
      inputProps={{ "aria-label": ariaLabel }}
      checked={isChecked}
      disabled={isDisabled}
      onChange={(event) => {
        setIsChecked(event.target.checked);
      }}
    />
  );
};
