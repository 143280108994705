import { gql } from "urql";

import { matchIsSameEthereumAddress } from "@/utils/matchIsSameEthereumAddress";
import { getBigNumber } from "@/utils/getBigNumber";

import type { SwapsHistoryQuery, SwapsHistoryQueryVariables } from "../codegen";

import { makeSubgraphRequest } from "../makeSubgraphRequest";
import { useSubgraphRequest } from "../useSubgraphRequest";

export interface Swap {
  id: string;
  xTokenInAddress: string;
  xTokenOutAddress: string;
  tokenInAmount: string;
  tokenOutAmount: string;
  tokenInSymbol: string;
  tokenOutSymbol: string;
  timestamp: number;
  priceRatio: string;
}

export async function getSwapsHistory(
  subgraphUrl: string,
  xTokenInAddress: string,
  xTokenOutAddress: string,
  startTimestamp: number,
  connectedAddress?: string
): Promise<Swap[]> {
  const getSwapPriceRatio = (
    swap: Pick<
      Swap,
      | "xTokenInAddress"
      | "xTokenOutAddress"
      | "tokenInAmount"
      | "tokenOutAmount"
    >
  ): string => {
    return matchIsSameEthereumAddress(swap.xTokenInAddress, xTokenInAddress)
      ? getBigNumber(swap.tokenOutAmount).div(swap.tokenInAmount).toString()
      : getBigNumber(swap.tokenInAmount).div(swap.tokenOutAmount).toString();
  };

  let swapsHistory: Swap[] = [];

  const pageSize = 1000; // the maximum "first" value that the subgraph supports
  const maxPage = 5; // the maximum "skip" value that the subgraph supports is 5000, so 5 pages of 1000

  let currentPage = 0;

  // Repeat until we did not get a full page or reach max page
  while (
    swapsHistory.length === currentPage * pageSize &&
    currentPage <= maxPage
  ) {
    const response = await makeSubgraphRequest(subgraphUrl, query, {
      pageSize,
      skip: currentPage * pageSize,
      filter: {
        tokenIn_in: [
          xTokenInAddress.toLowerCase(),
          xTokenOutAddress.toLowerCase(),
        ],
        tokenOut_in: [
          xTokenInAddress.toLowerCase(),
          xTokenOutAddress.toLowerCase(),
        ],
        timestamp_gt: Math.floor(startTimestamp / 1000), // TheGraph uses seconds timestamps as blockchain does
        ...(connectedAddress !== undefined
          ? { userAddress: connectedAddress.toLowerCase() }
          : undefined),
      },
    });

    const swapsWithPrice = response.swaps.map((swap) => {
      const parsedSwap = {
        id: swap.id,
        xTokenInAddress: swap.tokenIn,
        xTokenOutAddress: swap.tokenOut,
        tokenInAmount: swap.tokenAmountIn,
        tokenOutAmount: swap.tokenAmountOut,
        tokenInSymbol: swap.tokenInSym,
        tokenOutSymbol: swap.tokenOutSym,
        timestamp: swap.timestamp * 1000, // TheGraph uses seconds timestamps as blockchain does
      };

      return {
        ...parsedSwap,
        priceRatio: getSwapPriceRatio(parsedSwap),
      };
    });

    swapsHistory = swapsHistory.concat(swapsWithPrice);
    currentPage = currentPage + 1;
  }

  return swapsHistory;
}

export function useSwapsHistory(
  subgraphUrl: string | undefined,
  xTokenInAddress: string | undefined,
  xTokenOutAddress: string | undefined,
  startTimestamp: number,
  connectedAddress?: string
) {
  return useSubgraphRequest(
    subgraphUrl !== undefined &&
      xTokenInAddress !== undefined &&
      xTokenOutAddress !== undefined
      ? [
          "useSwapsHistory",
          subgraphUrl,
          xTokenInAddress,
          xTokenOutAddress,
          startTimestamp,
          connectedAddress,
        ]
      : null,
    getSwapsHistory
  );
}

const query = gql<SwapsHistoryQuery, SwapsHistoryQueryVariables>`
  query SwapsHistory($pageSize: Int, $skip: Int, $filter: Swap_filter) {
    swaps(
      where: $filter
      first: $pageSize
      skip: $skip
      orderBy: timestamp
      orderDirection: desc
    ) {
      id
      tokenIn
      tokenOut
      tokenAmountIn
      tokenAmountOut
      tokenInSym
      tokenOutSym
      timestamp
    }
  }
`;
