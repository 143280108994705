import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { Flexbox } from "@/components/Flexbox";
import { useInterval } from "@/hooks/useInterval";
import { useIsWindowActive } from "@/hooks/useIsWindowActive";

const ONE_SECOND_MILLISECONDS = 1000;
const MILLISECONDS_BEFORE_AUTO_DISMISS = 6000;

interface Props {
  title: React.ReactNode;
  explorerUrl?: string;
  hadSuccess: boolean;
  dismiss: () => void;
}

export const TransactionFeedback: React.FC<Props> = (props) => {
  const { title, explorerUrl, hadSuccess, dismiss } = props;

  const [timeLeftBeforeAutoDismiss, setTimeLeftBeforeAutoDismiss] =
    React.useState<number>(MILLISECONDS_BEFORE_AUTO_DISMISS);

  const updateTimeLeftBeforeAutoDismiss = React.useCallback(() => {
    setTimeLeftBeforeAutoDismiss((currentTimeLeftBeforeAutoDismiss) => {
      return currentTimeLeftBeforeAutoDismiss - ONE_SECOND_MILLISECONDS;
    });
  }, []);

  const isWindowActive = useIsWindowActive();

  useInterval(
    updateTimeLeftBeforeAutoDismiss,
    timeLeftBeforeAutoDismiss >= 0 && isWindowActive
      ? ONE_SECOND_MILLISECONDS
      : undefined
  );

  React.useEffect(() => {
    if (timeLeftBeforeAutoDismiss > -ONE_SECOND_MILLISECONDS) {
      return;
    }

    dismiss();
  }, [timeLeftBeforeAutoDismiss, dismiss]);

  return (
    <Flexbox
      direction="row"
      justifyContent="start"
      alignItems="center"
      sx={(theme) => {
        return {
          position: "relative",
          width: "100%",
          backgroundColor: theme.custom.colors.white,
          borderRadius: "8px",
          padding: "8px 8px 8px 16px",
          overflow: "hidden",
        };
      }}
    >
      <Flexbox
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ marginRight: 2 }}
      >
        {hadSuccess ? (
          <CheckIcon
            fontSize="medium"
            sx={(theme) => {
              return { color: theme.palette.success.main };
            }}
          />
        ) : (
          <CloseIcon
            fontSize="medium"
            sx={(theme) => {
              return { color: theme.palette.error.main };
            }}
          />
        )}
      </Flexbox>
      <Flexbox
        direction="column"
        justifyContent="start"
        alignItems="start"
        sx={{ width: "100%" }}
      >
        <Flexbox
          direction="row"
          justifyContent="start"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography fontWeight={500} sx={{ flex: 1 }}>
            {title}
          </Typography>
          <Tooltip placement="top" title="Dismiss">
            <IconButton
              sx={(theme) => {
                return {
                  flexShrink: 0,
                  marginLeft: 2,
                  padding: "2.5px",
                  color: theme.custom.colors.gray,
                };
              }}
              onClick={dismiss}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Flexbox>
        {explorerUrl !== undefined ? (
          <Link
            href={explorerUrl}
            target="_blank"
            rel="noopener"
            underline="none"
            sx={{
              fontSize: "0.875rem",
            }}
          >
            View on explorer
          </Link>
        ) : null}
      </Flexbox>

      <Box
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: "100%",
        }}
      >
        <StyledLinearProgress
          aria-label="remaining time before auto dismiss"
          variant="determinate"
          value={
            (timeLeftBeforeAutoDismiss / MILLISECONDS_BEFORE_AUTO_DISMISS) * 100
          }
          color="inherit"
          sx={(theme) => {
            return {
              height: "2px",
              color: theme.custom.colors.lightgray,
            };
          }}
        />
      </Box>
    </Flexbox>
  );
};

const StyledLinearProgress = styled(LinearProgress)({
  "& .MuiLinearProgress-bar": {
    transition: "1s linear all",
  },
});
