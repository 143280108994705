import React from "react";

import { useToggle } from "@/hooks/useToggle";

interface ReturnValue {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export function useDialog(): ReturnValue {
  const { isOn, toggleOn, toggleOff } = useToggle(false);

  const value = React.useMemo<ReturnValue>(() => {
    return {
      isOpen: isOn,
      open: toggleOn,
      close: toggleOff,
    };
  }, [isOn, toggleOn, toggleOff]);

  return value;
}
