import { CONFIG } from "@/constants/config";

export const CDN_ICONS = {
  smt: "SMT",
  vsmt: "vSMT",
  spt: "SPT",
  xspt: "SPT",
  weth: "Weth",
  wmatic: "matic",
  matic: "matic",
  "1inch": "1inch",
  cel: "cel",
  luna: "luna",
  solsn: "SOLsn",
  sol: "sol",
  tsla: "tsla",
  aapl: "aapl",
  coin: "coin",
  tslast: "tsla",
  aaplst: "aapl",
  coinst: "coin",
};

export const ICONS_CDN_URL = CONFIG.RESOURCES.ICONS_CDN_URL;

export const GENERIC_ICON_SRC = `${ICONS_CDN_URL}/svg/color/generic.svg`;

export const DEFAULT_SIZE = "24px";
