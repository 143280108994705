import React from "react";

import { checkWidgetKey } from "@/services/api";
import { captureException } from "@/services/sentry";
import { useConfiguration } from "@/state/configuration";
import { NonValidWidgetKey } from "@/components/NonValidWidgetKey";

type Context = null;

export const WidgetKeyContext = React.createContext<Context>(null);

interface WidgetKeyProviderProps {
  children: React.ReactNode;
}

export const WidgetKeyContextProvider: React.FC<WidgetKeyProviderProps> = (
  props
) => {
  const { children } = props;

  const {
    configuration: { widgetKey },
  } = useConfiguration();

  const [isWidgetKeyValid, setIsWidgetKeyValid] = React.useState<
    boolean | undefined
  >(undefined);

  React.useEffect(() => {
    const checkWidgetKeyIsValid = async () => {
      const isValid = await checkWidgetKey(widgetKey);
      setIsWidgetKeyValid(isValid);

      if (isValid === false) {
        captureException(new Error("Widget key is not valid"), {
          widgetKey,
        });
      }
    };

    checkWidgetKeyIsValid();
  }, [widgetKey]);

  const getChildren = () => {
    if (isWidgetKeyValid === undefined) {
      return undefined;
    }

    if (isWidgetKeyValid === true) {
      return children;
    }

    return <NonValidWidgetKey />;
  };

  return (
    <WidgetKeyContext.Provider value={null}>
      {getChildren()}
    </WidgetKeyContext.Provider>
  );
};
