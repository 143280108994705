import { PopulatedTransaction, Signer } from "ethers";

import type { Network, TransactionGasEstimationOptions } from "../types";
import { getNetworkGasPrice } from "../gasPrice";
import { getTransactionEstimatedGasLimit } from "../gasLimit";

interface TransactionGasEstimation {
  gasLimit: number;
  maxPriorityFeePerGas: number;
  maxFeePerGas: number;
  gasEstimate: number;
}

export async function getTransactionGasEstimation(
  transaction: PopulatedTransaction,
  signer: Signer,
  network: Network,
  options: TransactionGasEstimationOptions = {}
): Promise<TransactionGasEstimation> {
  const gasLimit = await getTransactionEstimatedGasLimit(
    transaction,
    signer,
    options
  );

  if (gasLimit === undefined) {
    throw new Error("Could not estimate transaction gas limit");
  }

  const { maxPriorityFeePerGas, maxFeePerGas } = await getNetworkGasPrice(
    network
  );

  const gasEstimate = maxFeePerGas.mul(gasLimit);

  return {
    gasLimit,
    maxPriorityFeePerGas: maxPriorityFeePerGas.toNumber(),
    maxFeePerGas: maxFeePerGas.toNumber(),
    gasEstimate: gasEstimate.toNumber(),
  };
}
