import React from "react";

import { Chain } from "@/constants/chains";
import { Address, Signer } from "@/types/web3";
import {
  State,
  useTwoStepTransactionFlag,
} from "@/hooks/useTwoStepTransactionFlag";
import { getUnwrapNativeTokenTransaction } from "@/contracts";
import {
  getTransactionEstimatedGasLimit,
  getTransactionGasEstimation,
} from "@/services/gas-estimator";
import { captureException } from "@/services/sentry";
import { getDenormalizedDecimalsNumber } from "@/utils/getDenormalizedDecimalsNumber";
import { getNormalizedDecimalsNumber } from "@/utils/getNormalizedDecimalsNumber";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";
import { matchIsNativeSwapToken } from "@/pages/Swap/utils/matchIsNativeSwapToken";

import type { SwapTransactionResult } from "../types";
import { getMaxNativeTokenInAmount } from "../utils/getMaxNativeTokenInAmount";
import { matchIsUnwrappingNativeToken } from "../utils/matchIsUnwrappingNativeToken";
import { getSwapEthersErrorTitle } from "../utils/getSwapEthersErrorTitle";

type UnwrapNativeToken = (amount: string) => Promise<SwapTransactionResult>;

interface ReturnValue {
  unwrapState: State;
  maxWrappedNativeTokenInAmount: string | undefined;
  isUnwrappingNativeToken: boolean;
  unwrapNativeToken: UnwrapNativeToken;
}

export function useUnwrapNativeToken(
  tokenIn: SwapToken | NativeSwapToken | undefined,
  tokenOut: SwapToken | NativeSwapToken | undefined,
  maxTokenInAmount: string | undefined,
  connectedChain: Chain | null,
  connectedWalletSigner: Signer | null,
  setTokenInAddress: (address: Address) => void
): ReturnValue {
  const {
    state: unwrapState,
    startConfirmation: startUnwrapConfirmation,
    startExecution: startUnwrapExecution,
    stop: stopUnwrap,
  } = useTwoStepTransactionFlag();

  const [unwrapNativeTokenGasFee, setUnunwrapNativeTokenGasFee] =
    React.useState<string | undefined>(undefined);

  const unwrapNativeToken = React.useCallback<UnwrapNativeToken>(
    async (amount) => {
      if (connectedChain === null || connectedWalletSigner === null) {
        return {
          title: "There was an issue. Please try again.",
          hadSuccess: false,
        };
      }

      const normalizedAmount = getNormalizedDecimalsNumber(
        amount,
        connectedChain.wrappedNativeToken.decimals
      ).toString();

      let transactionHash: string | undefined = undefined;

      startUnwrapConfirmation();
      try {
        const populatedTransaction = await getUnwrapNativeTokenTransaction(
          connectedChain.wrappedNativeToken.address,
          normalizedAmount,
          connectedWalletSigner
        );

        const gasLimit = await getTransactionEstimatedGasLimit(
          populatedTransaction,
          connectedWalletSigner
        );

        const transaction = await connectedWalletSigner.sendTransaction({
          ...populatedTransaction,
          gasLimit,
        });

        transactionHash = transaction.hash;

        startUnwrapExecution();

        await transaction.wait();

        return {
          title: "Unwrap successful.",
          transactionHash,
          hadSuccess: true,
        };
      } catch (error) {
        captureException(error as Error);

        return {
          title: getSwapEthersErrorTitle(error as Error),
          transactionHash,
          hadSuccess: false,
        };
      } finally {
        stopUnwrap();
      }
    },
    [
      connectedChain,
      connectedWalletSigner,
      startUnwrapConfirmation,
      startUnwrapExecution,
      stopUnwrap,
    ]
  );

  // This effect listens on picking the wrapped native token to select the native token on the other side
  React.useEffect(() => {
    if (
      tokenOut !== undefined &&
      connectedChain !== null &&
      matchIsNativeSwapToken(tokenOut)
    ) {
      setTokenInAddress(connectedChain.wrappedNativeToken.address);
    }
  }, [tokenOut, connectedChain, setTokenInAddress]);

  const isUnwrappingNativeToken = matchIsUnwrappingNativeToken(
    tokenIn,
    tokenOut,
    connectedChain
  );

  // This effect is in charge of updating the wrap native token gas fee value
  React.useEffect(() => {
    if (
      connectedChain === null ||
      connectedWalletSigner === null ||
      isUnwrappingNativeToken === false
    ) {
      return;
    }

    const updateUnunwrapNativeTokenGasFee = async () => {
      try {
        const populatedTransaction = await getUnwrapNativeTokenTransaction(
          connectedChain.wrappedNativeToken.address,
          "1", // amount does not matter for gas fee estimation
          connectedWalletSigner
        );

        const { gasEstimate } = await getTransactionGasEstimation(
          populatedTransaction,
          connectedWalletSigner,
          connectedChain.key
        );

        const denormalizedGasEstimate = getDenormalizedDecimalsNumber(
          gasEstimate,
          connectedChain.wrappedNativeToken.decimals
        );

        setUnunwrapNativeTokenGasFee(denormalizedGasEstimate.toString());
      } catch {
        // do nothing
      }
    };

    updateUnunwrapNativeTokenGasFee();
  }, [connectedChain, connectedWalletSigner, isUnwrappingNativeToken]);

  const maxWrappedNativeTokenInAmount = isUnwrappingNativeToken
    ? getMaxNativeTokenInAmount(maxTokenInAmount, unwrapNativeTokenGasFee)
    : maxTokenInAmount;

  const value = React.useMemo<ReturnValue>(() => {
    return {
      unwrapState,
      maxWrappedNativeTokenInAmount,
      isUnwrappingNativeToken,
      unwrapNativeToken,
    };
  }, [
    unwrapState,
    maxWrappedNativeTokenInAmount,
    isUnwrappingNativeToken,
    unwrapNativeToken,
  ]);

  return value;
}
