import { createClient, TypedDocumentNode } from "urql";

export async function makeSubgraphRequest<Data, Variables extends object>(
  subgraphUrl: string,
  query: TypedDocumentNode<Data, Variables>,
  variables?: Variables
): Promise<Data> {
  const client = createClient({
    url: subgraphUrl,
  });

  const response = await client
    .query<Data, Variables>(query, variables)
    .toPromise();

  if (response.error !== undefined) {
    throw new Error(response.error.message);
  }

  if (response.data === undefined) {
    throw new Error("No data value");
  }

  return response.data;
}
