import React from "react";
import CPK from "contract-proxy-kit";
import { ethers } from "ethers";

import { createCpkInstance } from "./createCpkInstance";

export function useCpkInstance(signer: ethers.Signer | null): CPK | null {
  const [cpkInstance, setCpkInstance] = React.useState<CPK | null>(null);

  React.useEffect(() => {
    if (signer === null) {
      return;
    }

    const updateCpkInstance = async () => {
      try {
        const cpkInstance = await createCpkInstance(signer);
        setCpkInstance(cpkInstance);
      } catch {
        setCpkInstance(null);
      }
    };

    updateCpkInstance();
  }, [signer]);

  return cpkInstance;
}
