import Big from 'big.js'

import { SwapTxType } from '../enums/swap-tx-type'
import { PoolModel } from '../types/pool.model'
import big, {
  bdiv,
  bmul,
  BONE,
  calcInGivenOut,
  calcOutGivenIn,
  MAX_IN_RATIO,
  MAX_OUT_RATIO,
  ZERO,
} from '../utils/big'
import { isSameAddress } from '../utils/is-same-address'
import { unifyAddress } from '../utils/unify-address'
import { PoolToken } from './pool-token'

interface TokenPairData {
  weightOut: Big
  weightIn: Big
  balanceIn: Big
  balanceOut: Big
}

export class Pool {
  public readonly id: string

  public readonly publicSwap: boolean

  public readonly tokensList: string[]

  public readonly swapFee: Big

  private readonly totalWeight: Big

  private readonly tokens: PoolToken[]

  constructor(pool: PoolModel) {
    this.id = unifyAddress(pool.id)
    this.publicSwap = Boolean(pool?.publicSwap)
    this.swapFee = big(pool?.swapFee)
    this.tokens = pool.tokens?.map(token => new PoolToken(token)) || []
    this.totalWeight = big(pool?.totalWeight)
    this.tokensList = pool?.tokensList?.map(unifyAddress) || []
  }

  public hasOwnToken = (xTokenAddress: string): boolean =>
    this.tokensList.some(id => isSameAddress(id, xTokenAddress))

  getEffectiveLiquidityPerTokenPair = (tokenInAddress: string, tokenOutAddress: string): Big => {
    const { weightIn, balanceOut, weightOut } = this.getDataPerTokenPair(
      tokenInAddress,
      tokenOutAddress,
    )
    return this.calcEffectiveLiquidity(weightIn, balanceOut, weightOut)
  }

  getSwapLimitAmount = (
    tokenInAddress: string,
    tokenOutAddress: string,
    swapType: SwapTxType,
  ): Big => {
    const { balanceIn, balanceOut } = this.getDataPerTokenPair(tokenInAddress, tokenOutAddress)

    return swapType === SwapTxType.exactIn
      ? bmul(balanceIn, MAX_IN_RATIO)
      : bmul(balanceOut, MAX_OUT_RATIO)
  }

  getSpotPricePerTokenPair = (tokenInAddress: string, tokenOutAddress: string): Big => {
    const { balanceIn, balanceOut, weightIn, weightOut } = this.getDataPerTokenPair(
      tokenInAddress,
      tokenOutAddress,
    )
    const inRatio = bdiv(balanceIn, weightIn)
    const outRatio = bdiv(balanceOut, weightOut)

    return outRatio.eq(ZERO) ? ZERO : bdiv(bdiv(inRatio, outRatio), BONE.minus(this.swapFee))
  }

  calcOutGivenInPerTokenPair = (
    tokenInAddress: string,
    tokenOutAddress: string,
    swapAmount: Big,
  ): Big => {
    const { weightIn, weightOut, balanceIn, balanceOut } = this.getDataPerTokenPair(
      tokenInAddress,
      tokenOutAddress,
    )

    // tokenAmountOut
    return calcOutGivenIn(balanceIn, weightIn, balanceOut, weightOut, swapAmount, this.swapFee)
  }

  calcInGivenOutPerTokenPair = (
    tokenInAddress: string,
    tokenOutAddress: string,
    swapAmount: Big,
  ): Big => {
    const { weightIn, weightOut, balanceIn, balanceOut } = this.getDataPerTokenPair(
      tokenInAddress,
      tokenOutAddress,
    )

    // tokenAmountIn
    return calcInGivenOut(balanceIn, weightIn, balanceOut, weightOut, swapAmount, this.swapFee)
  }

  private getDataPerTokenPair = (
    tokenInAddress: string,
    tokenOutAddress: string,
  ): TokenPairData => {
    const tIn = this.getPoolTokenByXTokenId(tokenInAddress)
    const tOut = this.getPoolTokenByXTokenId(tokenOutAddress)

    const balanceIn = tIn?.balance || ZERO
    const balanceOut = tOut?.balance || ZERO
    const weightIn = tIn?.denormWeight.div(this.totalWeight) || ZERO
    const weightOut = tOut?.denormWeight.div(this.totalWeight) || ZERO

    return { balanceIn, balanceOut, weightIn, weightOut }
  }

  static sortByEffectiveLiquidity = (tokenInAddress: string, tokenOutAddress: string) => (
    pool1: Pool,
    pool2: Pool,
  ): number => {
    const efL1 = pool1.getEffectiveLiquidityPerTokenPair(tokenInAddress, tokenOutAddress)
    const efL2 = pool2.getEffectiveLiquidityPerTokenPair(tokenInAddress, tokenOutAddress)

    return efL1
      .minus(efL2)
      .times(-1)
      .toNumber()
  }

  static sortBySpotPrice = (tokenInAddress: string, tokenOutAddress: string) => (
    pool1: Pool,
    pool2: Pool,
  ): number => {
    const sp1 = pool1.getSpotPricePerTokenPair(tokenInAddress, tokenOutAddress)
    const sp2 = pool2.getSpotPricePerTokenPair(tokenInAddress, tokenOutAddress)

    return sp1.minus(sp2).toNumber()
  }

  static getSumEffectiveLiquidity = (
    tokenInAddress: string,
    tokenOutAddress: string,
    pools: Pool[],
  ): Big =>
    pools.reduce(
      (acc, pool) =>
        acc.plus(pool.getEffectiveLiquidityPerTokenPair(tokenInAddress, tokenOutAddress)),
      ZERO,
    )

  private calcEffectiveLiquidity = (
    tokenWeightIn: Big,
    tokenBalanceOut: Big,
    tokenWeightOut: Big,
  ): Big =>
    tokenWeightIn
      .mul(BONE)
      .div(tokenWeightOut.add(tokenWeightIn))
      .mul(tokenBalanceOut)
      .div(BONE)

  private getPoolTokenByXTokenId = (id: string): PoolToken | undefined =>
    this.tokens.find(({ xTokenId }) => isSameAddress(xTokenId, id))
}
