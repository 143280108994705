import React from "react";
import NumberFormat from "react-number-format";

interface Props {
  value: string;
  decimals?: number;
  hasFixedDecimals?: boolean;
}

export const Balance: React.FC<Props> = (props) => {
  const { value, decimals = 4, hasFixedDecimals = false } = props;

  return (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator={true}
      isNumericString={true}
      decimalScale={decimals}
      fixedDecimalScale={hasFixedDecimals}
    />
  );
};
