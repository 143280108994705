class Logger {
  private readonly prefix: string = '### SOR: '

  constructor(private showLogs: boolean = true) {}

  info = (...args: unknown[]): void => {
    if (this.showLogs) {
      console.log(this.prefix, ...args)
    }
  }

  warn = (...args: unknown[]): void => {
    if (this.showLogs) {
      console.warn(this.prefix, ...args)
    }
  }

  error = (...args: unknown[]): void => {
    if (this.showLogs) {
      console.error(this.prefix, ...args)
    }
  }

  table = (title: string, ...args: unknown[]): void => {
    if (this.showLogs) {
      this.info(title)
      console.table(...args)
    }
  }
}

declare let __DEV__: boolean
const devLog = new Logger(__DEV__)

const log = new Logger()

export { devLog }
export default log
