export function openCenteredPopupWindow(
  url: string,
  windowName: string,
  parentWindow: Window,
  width: number,
  height: number
): Window | null {
  const x =
    parentWindow.top !== null
      ? parentWindow.top.outerWidth / 2 + parentWindow.top.screenX - width / 2
      : 0;
  const y =
    parentWindow.top !== null
      ? parentWindow.top.outerHeight / 2 + parentWindow.top.screenY - height / 2
      : 0;

  return parentWindow.open(
    url,
    windowName,
    `width=${width}, height=${height}, top=${y}, left=${x}, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no`
  );
}
