import Big, { BigSource } from "big.js";

import type { BigNumber, EthersBigNumber } from "@/types/web3";

function matchIsEthersBigNumber<T>(
  value: EthersBigNumber | T
): value is EthersBigNumber {
  return (value as EthersBigNumber)._isBigNumber === true;
}

export function getBigNumber(value: BigSource | EthersBigNumber): BigNumber {
  if (matchIsEthersBigNumber(value)) {
    return new Big(value.toString());
  }

  return new Big(value);
}
