import { ethers } from "ethers";

import type { Address, Signer } from "@/types/web3";

import { getNativeTokenWrapperContractInstance } from "./getNativeTokenWrapperContractInstance";

export async function getWrapNativeTokenTransaction(
  nativeTokenWrapperAddress: Address,
  amount: string,
  signer: Signer
): Promise<ethers.PopulatedTransaction> {
  const contractInstance = getNativeTokenWrapperContractInstance(
    nativeTokenWrapperAddress,
    signer
  );

  return contractInstance.populateTransaction.deposit({
    value: amount,
  });
}
