import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckIcon from "@mui/icons-material/Check";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";

import { Chain } from "@/constants/chains";
import { useContainerObserver } from "@/contexts/ContainerObserverContext";
import { getMinContainerWidthCSS } from "@/utils/getContainerWidthCSSMediaQuery";
import { BRIDGE_URL } from "@/constants/environment";
import { NetworkLogo } from "@/components/NetworkLogo";
import { Flexbox } from "@/components/Flexbox";
import { Breakpoint } from "@/components/Breakpoint";

interface Props {
  chains: Chain[];
  connectedChain: Chain;
  setChain: (options: { chainId: Chain["id"] }) => void;
}

export const NetworkSelector: React.FC<Props> = (props) => {
  const { chains, connectedChain, setChain } = props;

  const { containerWidth } = useContainerObserver();

  const [anchorElement, setAnchorElelement] =
    React.useState<HTMLElement | null>(null);

  const isOpen = anchorElement !== null;

  const openSelectMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElelement(event.currentTarget);
  };

  const closeSelectMenu = () => {
    setAnchorElelement(null);
  };

  return (
    <React.Fragment>
      <Button
        id="network-selector-button"
        data-testid="network-selector-button"
        variant="contained"
        startIcon={
          <NetworkLogo
            chainKey={connectedChain.key}
            width="20px"
            height="20px"
          />
        }
        endIcon={
          <ArrowDropDownIcon
            fontSize="small"
            color="primary"
            sx={(theme) => {
              return {
                marginLeft: "-12px",
                ...getMinContainerWidthCSS(
                  containerWidth,
                  theme.breakpoints.values["tablet"],
                  {
                    marginLeft: 0,
                  }
                ),
              };
            }}
          />
        }
        title="Select network"
        aria-controls={isOpen ? "network-selector-button-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        onClick={openSelectMenu}
        sx={(theme) => {
          return {
            minWidth: 60,
            minHeight: 40,
            color: theme.custom.colors.black,
            fontSize: "1rem",
            fontWeight: "700",
            textTransform: "none",
            outline: theme.custom.borders.none,
            backgroundColor: theme.custom.colors.white,
            boxShadow: theme.custom.shadows.none,
            padding: "6px 8px 6px 12px",
            "&:hover, &:active": {
              outline: theme.custom.borders.levelOne,
              backgroundColor: theme.custom.colors.white,
              boxShadow: theme.custom.shadows.levelOne,
            },
          };
        }}
      >
        <Breakpoint from="tablet">{connectedChain.label}</Breakpoint>
      </Button>
      <Menu
        id="network-selector-button-menu"
        data-testid="network-selector-button-menu"
        anchorEl={anchorElement}
        open={isOpen}
        onClose={closeSelectMenu}
        componentsProps={{
          root: {
            style: {
              top: "4px",
            },
          },
        }}
        container={() => {
          return document.getElementById("popup-container");
        }}
        MenuListProps={{
          "aria-labelledby": "network-selector-button",
          sx: { width: "220px", paddingLeft: 1, paddingRight: 1 },
        }}
      >
        <Typography
          variant="subtitle2"
          paddingX={2}
          marginBottom={1}
          sx={(theme) => {
            return {
              color: theme.custom.colors.gray,
            };
          }}
        >
          Networks
        </Typography>

        {chains.map((chain) => {
          const isSelected = chain.id === connectedChain.id;
          return (
            <MenuItem
              key={chain.id}
              selected={isSelected}
              sx={{
                paddingY: 1,
              }}
              onClick={() => {
                setChain({ chainId: chain.id });
                closeSelectMenu();
              }}
            >
              <NetworkLogo
                chainKey={chain.key}
                width="20px"
                height="20px"
                marginRight={1}
              />
              <Typography
                variant="subtitle2"
                marginRight="auto"
                fontWeight={700}
              >
                {chain.label}
              </Typography>
              {isSelected ? (
                <CheckIcon fontSize="small" color="primary" />
              ) : null}
            </MenuItem>
          );
        })}

        <Divider component="li" />

        <MenuItem
          sx={{
            width: "100%",
            marginTop: 1,
            padding: 0,
          }}
        >
          <Link
            href={BRIDGE_URL}
            target="_blank"
            rel="noopener"
            underline="none"
            sx={{ width: "100%" }}
          >
            <Flexbox
              direction="row"
              justifyContent="start"
              alignItems="center"
              sx={{ width: "100%", height: 48, paddingX: 2 }}
            >
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                style={{ width: 20, height: 20, marginRight: 8 }}
              >
                <path
                  d="M16.045 3.955a8.55 8.55 0 1 1-12.09 12.09 8.55 8.55 0 0 1 12.09-12.09"
                  stroke="#0179EF"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.2 7.15h6.6"
                  stroke="#0179EF"
                  strokeWidth="1.6"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
                <path
                  d="m8.1 10.95-1.9 1.9 1.9 1.9"
                  stroke="#0179EF"
                  strokeWidth="1.6"
                  strokeLinecap="square"
                />
                <path
                  d="M13.8 12.85H7.2"
                  stroke="#0179EF"
                  strokeWidth="1.6"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
                <path
                  d="m11.9 5.25 1.9 1.9-1.9 1.9"
                  stroke="#0179EF"
                  strokeWidth="1.6"
                  strokeLinecap="square"
                />
              </svg>
              <Typography
                variant="subtitle2"
                sx={(theme) => {
                  return {
                    fontWeight: "700",
                    color: theme.custom.colors.black,
                  };
                }}
              >
                Bridge
              </Typography>
            </Flexbox>
          </Link>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
