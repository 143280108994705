import React from "react";

import type { Address, BigNumber, EthersBigNumber } from "@/types/web3";
import { Chain } from "@/constants/chains";
import {
  getErc20BalanceMulticall,
  getErc20DecimalsMulticall,
  getXTokenAmountInSmt,
} from "@/services/multicall";
import { getBigNumber } from "@/utils/getBigNumber";
import { useBatchedValues } from "@/hooks/useBatchedValues";
import type { NativeSwapToken, SwapToken } from "@/pages/Swap/types";

interface ReturnValue {
  smtProtocolFee: BigNumber | undefined;
  smtDecimals: number | undefined;
  hasEnoughSmtToPaySmtProtocolFee: boolean | undefined;
}

export function useSwapSmtProtocolFee(
  connectedAddress: Address | null,
  connectedChain: Chain | null,
  tokenIn: SwapToken | NativeSwapToken | undefined,
  protocolFee: BigNumber
): ReturnValue {
  const smtProtocolFeeBatchedValuesCalls = React.useMemo(() => {
    if (
      connectedAddress === null ||
      connectedChain === null ||
      tokenIn === undefined ||
      tokenIn.xToken === undefined
    ) {
      return [];
    }

    return [
      getXTokenAmountInSmt(
        connectedChain.contractsAddresses.smtPriceFeed,
        tokenIn.xToken.address,
        protocolFee.div(2).toFixed(0),
        { label: "smtProtocolFee" }
      ),
      getErc20BalanceMulticall(
        connectedChain.contractsAddresses.smt,
        connectedAddress,
        { label: "smtBalance" }
      ),
      getErc20DecimalsMulticall(connectedChain.contractsAddresses.smt, {
        label: "smtDecimals",
      }),
    ];
  }, [connectedAddress, connectedChain, tokenIn, protocolFee]);

  const smtProtocolFeeBatchedValues = useBatchedValues<{
    smtProtocolFee: EthersBigNumber;
    smtBalance: EthersBigNumber;
    smtDecimals: number;
  }>(smtProtocolFeeBatchedValuesCalls);

  const value = React.useMemo<ReturnValue>(() => {
    if (smtProtocolFeeBatchedValues === null) {
      return {
        smtProtocolFee: undefined,
        smtDecimals: undefined,
        hasEnoughSmtToPaySmtProtocolFee: undefined,
      };
    }

    const smtProtocolFee =
      smtProtocolFeeBatchedValues.smtProtocolFee !== undefined
        ? getBigNumber(smtProtocolFeeBatchedValues.smtProtocolFee)
        : undefined;

    const smtBalance = smtProtocolFeeBatchedValues.smtBalance;

    const smtDecimals = smtProtocolFeeBatchedValues.smtDecimals;

    const hasEnoughSmtToPaySmtProtocolFee =
      smtProtocolFee !== undefined &&
      smtBalance !== undefined &&
      getBigNumber(smtBalance).gte(smtProtocolFee);

    return {
      smtProtocolFee,
      smtDecimals,
      hasEnoughSmtToPaySmtProtocolFee,
    };
  }, [smtProtocolFeeBatchedValues]);

  return value;
}
