import { matchIsSameSymbol } from "@/utils/matchIsSameSymbol";
import { getCdnUrl } from "@/utils/getCdnUrl";

import {
  GENERIC_ICON_SRC,
  ICONS_CDN_URL,
  CDN_ICONS,
} from "./TokenIcon.constants";

export function getIconSrc(
  symbol: string,
  manifest: Array<{ symbol: string }> | undefined
): string {
  const iconInCDN = Object.entries(CDN_ICONS).find(([cdnIconSymbol, _]) => {
    return matchIsSameSymbol(cdnIconSymbol, symbol);
  });

  if (iconInCDN !== undefined) {
    const cdnIconName = iconInCDN[1];
    return getCdnUrl(`${cdnIconName}.svg`);
  }

  const isIconInManifest =
    manifest !== undefined &&
    manifest.find((manifestItem) => {
      return matchIsSameSymbol(manifestItem.symbol, symbol);
    }) !== undefined;

  if (isIconInManifest) {
    return `${ICONS_CDN_URL}/svg/color/${symbol.toLowerCase()}.svg`;
  }

  return GENERIC_ICON_SRC;
}
