import { createTheme } from "@mui/material/styles";

export const customTheme = {
  colors: {
    primary: "#0179F1",
    white: "#ffffff",
    black: "#262626",
    lightblack: "#4D4D4D",
    gray: "#9FA3BC",
    lightgray: "#D3D5E5",
    background: {
      default: "#F0F3F7",
      primary: "#F0F6FE",
    },
  },
  shadows: {
    none: "none",
    levelOne: "0px 1px 3px 0px rgba(0, 0, 0, 0.1)",
    levelTwo: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
  },
  borders: {
    none: "none",
    levelOne: "1px solid rgba(0, 0, 0, 0.01)",
    lightgray: "1px solid #D3D5E5",
  },
  zIndexes: {
    layerOne: 1,
    layerTwo: 2,
    layerThree: 3,
  },
  transitions: {
    backgroundColor:
      "background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms",
  },
};

export type CustomTheme = typeof customTheme;

export const theme = createTheme({
  custom: customTheme,
  palette: {
    primary: {
      main: "#0179F1",
    },
  },
  typography: {
    fontFamily: ['"Inter"', "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      laptop: 1024,
      desktop: 1600,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export type Breakpoint = keyof typeof theme.breakpoints.values;
