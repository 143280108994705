import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

interface PackageJSON {
  name: string;
  version: string;
}

export function setupSentry(
  dsn: string,
  environment: string,
  packageJSON: PackageJSON
): void {
  Sentry.init({
    release: `${packageJSON.name}@${packageJSON.version}`,
    dsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.25,
    environment,
  });
}
