import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { ROUTES } from "@/routes";
import { getMergedSxProps } from "@/utils/getMergedSxProps";
import { Flexbox } from "@/components/Flexbox";
import { Breakpoint } from "@/components/Breakpoint";

import { PassportIcon } from "./components/PassportIcon";
import { WalletIcon } from "./components/WalletIcon";
import { AboutIcon } from "./components/AboutIcon";
import { SupportIcon } from "./components/SupportIcon";
import { LegalIcon } from "./components/LegalIcon";

interface Props {}

export const MenuButton: React.FC<Props> = (props) => {
  const [anchorElement, setAnchorElelement] =
    React.useState<HTMLElement | null>(null);

  const isOpen = anchorElement !== null;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElelement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElelement(null);
  };

  return (
    <React.Fragment>
      <Button
        id="menu-button"
        data-testid="menu-button"
        variant="contained"
        title="Menu"
        aria-controls={isOpen ? "menu-button-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        onClick={handleClick}
        sx={(theme) => {
          return {
            width: 44,
            minWidth: 44,
            height: 40,
            color: theme.custom.colors.primary,
            fontSize: "1rem",
            fontWeight: "600",
            textTransform: "none",
            outline: theme.custom.borders.none,
            backgroundColor: theme.custom.colors.white,
            boxShadow: theme.custom.shadows.none,
            padding: "6px 12px",
            "&:hover, &:active": {
              outline: theme.custom.borders.levelOne,
              backgroundColor: theme.custom.colors.white,
              boxShadow: theme.custom.shadows.levelOne,
            },
          };
        }}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="menu-button-menu"
        data-testid="menu-button-menu"
        anchorEl={anchorElement}
        open={isOpen}
        onClose={handleClose}
        componentsProps={{
          root: {
            style: {
              top: "4px",
            },
          },
        }}
        container={() => {
          return document.getElementById("popup-container");
        }}
        MenuListProps={{
          "aria-labelledby": "menu-button",
          sx: { width: "220px", paddingLeft: 1, paddingRight: 1 },
        }}
      >
        <Breakpoint until="desktop">
          <MenuButtonItem
            href={ROUTES.TRADE_PLATFORM_PASSPORT}
            icon={<PassportIcon />}
            onClick={handleClose}
          >
            My Account
          </MenuButtonItem>
        </Breakpoint>
        <Breakpoint until="desktop">
          <MenuButtonItem
            href={ROUTES.TRADE_PLATFORM_WALLETS}
            icon={<WalletIcon />}
            onClick={handleClose}
          >
            Wallets
          </MenuButtonItem>
        </Breakpoint>
        <MenuButtonItem
          href={ROUTES.DOCS_ABOUT}
          icon={<AboutIcon />}
          onClick={handleClose}
        >
          About
        </MenuButtonItem>
        <MenuButtonItem
          href={ROUTES.DOCS_SUPPORT}
          icon={<SupportIcon />}
          onClick={handleClose}
        >
          Support
        </MenuButtonItem>
        <Divider component="li" />
        <MenuButtonItem
          href={ROUTES.DOCS_LEGAL_AND_PRIVACY}
          icon={<LegalIcon />}
          onClick={handleClose}
          sx={{ marginTop: 1 }}
        >
          Legal & Privacy
        </MenuButtonItem>
      </Menu>
    </React.Fragment>
  );
};

interface MenuButtonItemProps extends React.ComponentProps<typeof MenuItem> {
  href: string;
  icon: React.ReactNode;
  children: string;
  onClick: () => void;
}

const MenuButtonItem: React.FC<MenuButtonItemProps> = (props) => {
  const { href, icon, children, ...menuItemProps } = props;

  return (
    <MenuItem
      {...menuItemProps}
      sx={getMergedSxProps(menuItemProps.sx, {
        width: "100%",
        minHeight: 36,
        padding: 0,
      })}
    >
      <Link
        href={href}
        target="_blank"
        rel="noopener"
        underline="none"
        sx={{ width: "100%" }}
      >
        <Flexbox
          direction="row"
          justifyContent="start"
          alignItems="center"
          sx={{ height: 36, paddingX: 1, paddingY: "2px" }}
        >
          <Flexbox
            direction="row"
            justifyContent="start"
            alignItems="center"
            sx={{
              width: "32px",
              height: "20px",
            }}
          >
            <Flexbox
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={(theme) => {
                return {
                  width: "20px",
                  height: "20px",
                  color: theme.palette.grey["500"],
                };
              }}
            >
              {icon}
            </Flexbox>
          </Flexbox>
          <Typography
            sx={(theme) => {
              return {
                fontSize: "1rem",
                fontWeight: "400",
                color: theme.custom.colors.black,
              };
            }}
          >
            {children}
          </Typography>
        </Flexbox>
      </Link>
    </MenuItem>
  );
};
