import React from "react";

interface ReturnValue {
  isOn: boolean;
  toggleOn: () => void;
  toggleOff: () => void;
  toggle: () => void;
  reset: () => void;
}

export function useToggle(initialIsOn = false): ReturnValue {
  const [isOn, setIsOn] = React.useState(initialIsOn);

  return React.useMemo(() => {
    return {
      isOn,
      toggleOn: () => {
        setIsOn(true);
      },
      toggleOff: () => {
        setIsOn(false);
      },
      toggle: () => {
        setIsOn((currentIsOn) => {
          return !currentIsOn;
        });
      },
      reset: () => {
        setIsOn(initialIsOn);
      },
    };
  }, [isOn, initialIsOn]);
}
