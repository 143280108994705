import type { Address } from "@/types/web3";

import type { BaseResponse } from "../types";
import { makeApiFetchRequest } from "../makeApiFetchRequest";
import { useApiFetchRequest } from "../useApiFetchRequest";
import { ChainKey } from "@/constants/chains";

type Prices = Record<Address, { usd: string; eur: string }>;

interface ExchangePricesAttributes {
  prices: Prices;
}

export async function getExchangePrices(
  chainKey: ChainKey,
  tokensAddresses: Address[]
): Promise<Prices> {
  const response = await makeApiFetchRequest<
    BaseResponse<ExchangePricesAttributes>
  >(`/exchange_prices`, {
    method: "GET",
    isAuthorizationRequired: false,
    searchParams: {
      chain:
        chainKey === "mumbai" || chainKey === "polygon" ? "polygon" : "primary",
      contract_addresses: tokensAddresses.join(","),
      vs_currencies: "usd,eur",
    },
  });
  return response.attributes.prices;
}

export function useExchangePrices(
  chainKey: ChainKey | null,
  tokensAddresses: Address[]
) {
  return useApiFetchRequest(
    chainKey !== null && tokensAddresses.length > 0
      ? ["useExchangePrices", chainKey, tokensAddresses]
      : null,
    getExchangePrices
  );
}
