import create, { GetState, SetState, Mutate, StoreApi } from "zustand";
import { persist } from "zustand/middleware";

import { createConfigurationStoreSlice } from "./configuration/store";
import { createWalletStoreSlice } from "./wallet/store";

// A Store slice can read its state and the state of other slices but can only set its own state
export type StoreSlice<T extends object, E extends object = T> = (
  set: SetState<T>,
  get: GetState<E extends T ? E : E & T>
) => T;

type PersistStoreApiType = ReturnType<typeof createWalletStoreSlice>;

const createRootSlice = (
  set: SetState<any>,
  get: GetState<any>,
  api: Mutate<
    StoreApi<PersistStoreApiType>,
    [["zustand/persist", Partial<PersistStoreApiType>]]
  >
) => {
  return {
    ...createConfigurationStoreSlice(set, get),
    ...persist(createWalletStoreSlice, {
      name: "wallet",
      partialize: (state) => {
        return {
          lastConnectedWalletLabel: state.lastConnectedWalletLabel,
        };
      },
    })(set, get, api),
  };
};

export const useStore = create(createRootSlice);
