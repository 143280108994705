export function getMinContainerWidthCSS<CSSObject>(
  containerWidth: number,
  breakpointWidth: number,
  cssObject: CSSObject
): CSSObject | {} {
  if (containerWidth >= breakpointWidth) {
    return cssObject;
  }

  return {};
}
