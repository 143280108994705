import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";

import { Address } from "@/types/web3";
import { getTruncatedAddress } from "@/utils/getTruncatedAddress";
import { Avatar } from "@/components/Avatar";
import { Breakpoint } from "@/components/Breakpoint";

interface Props {
  connectedAddress: Address | null;
  connect: () => void;
  disconnect: () => void;
}

export const AccountButton: React.FC<Props> = (props) => {
  const { connectedAddress, connect, disconnect } = props;

  const [anchorElement, setAnchorElelement] =
    React.useState<HTMLElement | null>(null);

  const isOpen = anchorElement !== null;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElelement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElelement(null);
  };

  if (connectedAddress === null) {
    return (
      <Button
        data-testid="connect-account-button"
        variant="contained"
        onClick={connect}
        sx={(theme) => {
          return {
            minWidth: 60,
            minHeight: 40,
            fontSize: "1rem",
            fontWeight: "600",
            textTransform: "none",
            boxShadow: theme.custom.shadows.levelOne,
            "&:hover": {
              boxShadow: theme.custom.shadows.levelTwo,
            },
          };
        }}
      >
        Connect wallet
      </Button>
    );
  }

  return (
    <React.Fragment>
      <Button
        id="account-button"
        data-testid="account-button"
        variant="contained"
        aria-label={connectedAddress}
        aria-controls={isOpen ? "account-button-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        onClick={handleClick}
        sx={(theme) => {
          return {
            minWidth: 60,
            minHeight: 40,
            color: theme.custom.colors.primary,
            fontSize: "1rem",
            fontWeight: "600",
            textTransform: "none",
            outline: theme.custom.borders.none,
            backgroundColor: theme.custom.colors.white,
            boxShadow: theme.custom.shadows.none,
            padding: "6px 12px",
            "&:hover, &:active": {
              outline: theme.custom.borders.levelOne,
              backgroundColor: theme.custom.colors.white,
              boxShadow: theme.custom.shadows.levelOne,
            },
            "&::after": {
              content: '""',
              position: "absolute",
              top: "5px",
              right: "5px",
              width: "5px",
              height: "5px",
              borderRadius: "50%",
              backgroundColor: theme.palette.success.light,
              zIndex: theme.custom.zIndexes.layerOne,
            },
          };
        }}
      >
        <Breakpoint until="tablet">
          <Avatar address={connectedAddress} size={6} />
        </Breakpoint>
        <Breakpoint from="tablet">
          {getTruncatedAddress(connectedAddress)}
        </Breakpoint>
      </Button>
      <Menu
        id="account-button-menu"
        data-testid="account-button-menu"
        anchorEl={anchorElement}
        open={isOpen}
        onClose={handleClose}
        componentsProps={{
          root: {
            style: {
              top: "4px",
            },
          },
        }}
        container={() => {
          return document.getElementById("popup-container");
        }}
        MenuListProps={{
          "aria-labelledby": "account-button",
        }}
      >
        <MenuItem
          data-testid="account-button-menu-disconnect-item"
          sx={(theme) => {
            return {
              color: theme.palette.error.light,
            };
          }}
          onClick={() => {
            disconnect();
            handleClose();
          }}
        >
          <LogoutIcon sx={{ marginRight: 1 }} fontSize="small" />
          Disconnect
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
