import { ethers } from "ethers";
import CPK, { Transaction as CPKTransaction } from "contract-proxy-kit";

import type { CPKTransactionGasEstimationOptions } from "../types";
import { DEFAULT_ADDED_GAS_LIMIT_PERCENTAGE } from "../constants";

export const SWAP_OWNER_GAS_AMOUNT = 35000;

export async function getCPKTransactionEstimatedGasLimit(
  cpkInstance: CPK,
  transactions: CPKTransaction[],
  options: CPKTransactionGasEstimationOptions = {}
): Promise<number> {
  const { addedGasLimitPercentage = DEFAULT_ADDED_GAS_LIMIT_PERCENTAGE } =
    options;

  const { gasLimit } = await cpkInstance.getExecTransactionsGasLimit(
    transactions
  );

  const estimatedGasLimit = ethers.BigNumber.from(gasLimit)
    .mul(100 + addedGasLimitPercentage)
    .div(100)
    .toNumber();

  const isDeployed = await cpkInstance.isProxyDeployed();

  return estimatedGasLimit + (isDeployed ? 0 : SWAP_OWNER_GAS_AMOUNT);
}
