import React from "react";

interface Props {}

export const SmallLogo: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 116 69.57"
      style={{ width: "100%" }}
    >
      <path
        d="m34 62.69 7.61-21.37L29.07 6.19H0l20.13 56.46a7.44 7.44 0 0 0 13.84 0ZM70.12 6.81c-.88-2.37-3-4.49-5.68-4.54s-5.11 2.15-6 4.65l-.24.65-10.5 29.92-9.49 26.64a11.22 11.22 0 0 1-1.89 3.25h24.52a7.48 7.48 0 0 0 7-4.73L68 62l10.51-29.92.31-.86Zm14.66 27.8h29.07L104 6.87a7.48 7.48 0 0 0-7-4.73H72.5a11.15 11.15 0 0 1 1.87 3.23Z"
        fill="#0078f0"
      />
    </svg>
  );
};
