import { getBigNumber } from "@/utils/getBigNumber";

export function getMaxNativeTokenInAmount(
  maxTokenInAmount: string | undefined,
  nativeTokenGasFee: string | undefined
): string | undefined {
  if (maxTokenInAmount === undefined || nativeTokenGasFee === undefined) {
    return undefined;
  }

  const maxNativeTokenInAmount =
    getBigNumber(maxTokenInAmount).sub(nativeTokenGasFee);

  if (maxNativeTokenInAmount.lt(0)) {
    return "0";
  }

  return maxNativeTokenInAmount.toString();
}
