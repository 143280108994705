import type { BaseResponse } from "../types";
import { makeApiFetchRequest } from "../makeApiFetchRequest";
import { useApiFetchRequest } from "../useApiFetchRequest";

export async function checkWidgetKey(widgetKey: string): Promise<boolean> {
  try {
    await makeApiFetchRequest<BaseResponse<void>>(`/widget/access/check`, {
      method: "GET",
      isAuthorizationRequired: false,
      searchParams: {
        key: widgetKey,
      },
    });

    return true;
  } catch {
    return false;
  }
}

export function useCheckWidgetKey(widgetKey: string | undefined) {
  return useApiFetchRequest(
    widgetKey !== undefined ? ["useCheckWidgetKey", widgetKey] : null,
    checkWidgetKey
  );
}
