import { gql } from "urql";

import type {
  AssetTokenAuthorizationContractsQuery,
  AssetTokenAuthorizationContractsQueryVariables,
} from "../codegen";

import { makeSubgraphRequest } from "../makeSubgraphRequest";
import { useSubgraphRequest } from "../useSubgraphRequest";

export async function getAssetTokenAuthorizationContracts(
  subgraphUrl: string,
  assetTokenAddress: string
): Promise<string[]> {
  const response = await makeSubgraphRequest(subgraphUrl, query, {
    assetTokenAddress,
  });

  const assetToken = response.assetToken;

  if (assetToken === null || assetToken === undefined) {
    return [];
  }

  return assetToken.authorizationList;
}

export function useAssetTokenAuthorizationContracts(
  subgraphUrl: string | undefined,
  assetTokenAddress: string | undefined
) {
  return useSubgraphRequest(
    subgraphUrl !== undefined && assetTokenAddress !== undefined
      ? ["useAssetTokenAuthorizationContracts", subgraphUrl, assetTokenAddress]
      : null,
    getAssetTokenAuthorizationContracts
  );
}

const query = gql<
  AssetTokenAuthorizationContractsQuery,
  AssetTokenAuthorizationContractsQueryVariables
>`
  query AssetTokenAuthorizationContracts($assetTokenAddress: ID!) {
    assetToken(id: $assetTokenAddress) {
      authorizationList
    }
  }
`;
