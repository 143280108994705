import { Call } from "../types";

export function getFiatAmountValidation(
  assetTokenAuthorizationContractAddress: string,
  options: { label?: string } = {}
): Call {
  const { label = "fiatAmountValidation" } = options;

  return {
    target: assetTokenAuthorizationContractAddress,
    call: ["fiatAmountValidation()(uint256)"],
    label,
  };
}
