import { Swap } from "@/pages/Swap/types";
import { getBigNumber } from "@/utils/getBigNumber";

export function getPriceChangePercentage(swapsHistory: Swap[]): number {
  const chronologicalSwapsHistory = swapsHistory.slice().reverse();

  const firstItem = chronologicalSwapsHistory[0];
  const lastItem =
    chronologicalSwapsHistory[chronologicalSwapsHistory.length - 1];

  if (firstItem === undefined || lastItem === undefined) {
    return 0;
  }

  return getBigNumber(lastItem.priceRatio)
    .minus(firstItem.priceRatio)
    .times(100)
    .div(firstItem.priceRatio)
    .toNumber();
}
