import React from "react";
import { SwapTxType } from "@swarmmarkets/smart-order-router";
import { NativeSwapToken, SwapToken } from "@/pages/Swap/types";
import { Address } from "@/types/web3";

type ReverseSwap = () => void;

interface ReturnValue {
  reverseSwap: ReverseSwap;
}

export function useSwapReverse(
  swapType: SwapTxType,
  tokenIn: SwapToken | NativeSwapToken | undefined,
  tokenOut: SwapToken | NativeSwapToken | undefined,
  tokenInAmount: string,
  tokenOutAmount: string,
  setSwapType: (swapType: SwapTxType) => void,
  setTokenInAddress: (address: Address) => void,
  setTokenOutAddress: (address: Address) => void,
  setTokenInAmount: (amount: string) => void,
  setTokenOutAmount: (amount: string) => void
): ReturnValue {
  const originalTokenInAmountRef = React.useRef<string | undefined>(undefined);
  const originalTokenOutAmountRef = React.useRef<string | undefined>(undefined);

  const reverseSwap = React.useCallback<ReverseSwap>(() => {
    if (swapType === SwapTxType.exactIn) {
      // Toggle swap type
      setSwapType(SwapTxType.exactOut);

      // Toggle token
      if (tokenOut !== undefined) {
        setTokenInAddress(tokenOut.address);
      }
      if (tokenIn !== undefined) {
        setTokenOutAddress(tokenIn.address);
      }

      // Try to restore previous token out amount
      if (originalTokenOutAmountRef.current !== undefined) {
        setTokenOutAmount(originalTokenOutAmountRef.current);
        originalTokenOutAmountRef.current = undefined;
      } else {
        setTokenOutAmount(tokenInAmount);
      }

      // Reset token in amount as it needs to be computed
      setTokenInAmount("");

      // Store current exactIn amount
      originalTokenInAmountRef.current = tokenInAmount;
    } else {
      // Toggle swap type
      setSwapType(SwapTxType.exactIn);

      // Toggle token
      if (tokenOut !== undefined) {
        setTokenInAddress(tokenOut.address);
      }
      if (tokenIn !== undefined) {
        setTokenOutAddress(tokenIn.address);
      }

      // Try to restore previous token in amount
      if (originalTokenInAmountRef.current !== undefined) {
        setTokenInAmount(originalTokenInAmountRef.current);
        originalTokenInAmountRef.current = undefined;
      } else {
        setTokenInAmount(tokenOutAmount);
      }

      // Reset token out amount as it needs to be computed
      setTokenOutAmount("");

      // Store current exactOut amount
      originalTokenOutAmountRef.current = tokenOutAmount;
    }
  }, [
    swapType,
    tokenIn,
    tokenOut,
    tokenInAmount,
    tokenOutAmount,
    setSwapType,
    setTokenInAddress,
    setTokenOutAddress,
    setTokenInAmount,
    setTokenOutAmount,
  ]);

  const value = React.useMemo<ReturnValue>(() => {
    return {
      reverseSwap,
    };
  }, [reverseSwap]);

  return value;
}
