import React from "react";

interface Props {}

export const LegalIcon: React.FC<Props> = (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      style={{ height: "100%" }}
    >
      <path
        d="M10 5.107c-2.162-1.21-4.093-1.692-6.652-1.775a.829.829 0 0 0-.848.834v9.877c0 .451.36.825.812.832 2.576.038 4.516.513 6.688 1.79 2.172-1.277 4.112-1.752 6.688-1.79a.83.83 0 0 0 .812-.832V4.166a.83.83 0 0 0-.848-.834c-2.559.083-4.49.565-6.652 1.776v0ZM10 5.105v11.559"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
