import React from "react";

import { Chain } from "@/constants/chains";
import { Logo } from "@/components/Logo";
import { Flexbox } from "@/components/Flexbox";
import { Breakpoint } from "@/components/Breakpoint";
import { Configuration } from "@/state/configuration";

import { NetworkSelector } from "./components/NetworkSelector";
import { AccountButton } from "./components/AccountButton";
import { MenuButton } from "./components/MenuButton";
import { PassportButton } from "./components/PassportButton";
import { WalletsButton } from "./components/WalletsButton";

interface Props {
  connectedAddress: string | null;
  chains: Chain[];
  connectedChain: Chain | null;
  configuration: Configuration;
  setChain: (options: { chainId: string }) => void;
  connect: () => void;
  disconnect: () => void;
}

export const LayoutHeader: React.FC<Props> = (props) => {
  const {
    connectedAddress,
    chains,
    connectedChain,
    configuration,
    setChain,
    connect,
    disconnect,
  } = props;

  /*
  const calls = React.useMemo(() => {
    if (connectedChain === null || connectedAddress === null) {
      return [];
    }

    return [
      getErc20BalanceMulticall(
        connectedChain.contractsAddresses.smt,
        connectedAddress,
        { label: "balanceSMT" }
      ),
      getErc20DecimalsMulticall(connectedChain.contractsAddresses.smt, {
        label: "decimalsSMT",
      }),
    ];
  }, [connectedChain, connectedAddress]);

  const batchedValues = useBatchedValues<{
    balanceSMT: EthersBigNumber;
    decimalsSMT: number;
  }>(calls);
  */

  return (
    <Flexbox
      direction="row"
      justifyContent="start"
      alignItems="center"
      sx={{
        width: "100%",
        height: "64px",
        paddingX: 2,
      }}
    >
      <Flexbox
        direction="row"
        justifyContent="start"
        alignItems="center"
        sx={{
          flexShrink: 0,
        }}
      >
        <Logo
          smallLogoUrl={configuration.smallLogoUrl}
          largeLogoUrl={configuration.largeLogoUrl}
        />
      </Flexbox>

      {/*<Box
        sx={(theme) => {
          return {
            display: "none",
            ...getMinContainerWidthCSS(
              containerWidth,
              theme.breakpoints.values["desktop"],
              {
                display: "block",
                flex: 1,
              }
            ),
          };
        }}
      >
        <Flexbox
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <LayoutTabs />
        </Flexbox>
      </Box>*/}

      <Flexbox
        direction="row"
        justifyContent="end"
        alignItems="center"
        spacing={[0.5, 1]}
        sx={{
          flex: 1,
        }}
      >
        {/*
        <SmtBalance
          balance={
            batchedValues !== null &&
            batchedValues.balanceSMT !== undefined &&
            batchedValues.decimalsSMT !== undefined
              ? getDenormalizedDecimalsNumber(
                  batchedValues.balanceSMT.toString(),
                  batchedValues.decimalsSMT
                ).toString()
              : "-"
          }
        />
        */}

        {connectedChain !== null ? (
          <NetworkSelector
            chains={chains}
            connectedChain={connectedChain}
            setChain={setChain}
          />
        ) : null}

        <AccountButton
          connectedAddress={connectedAddress}
          connect={connect}
          disconnect={disconnect}
        />

        <Breakpoint from="desktop">
          <WalletsButton />
        </Breakpoint>

        <Breakpoint from="desktop">
          <PassportButton />
        </Breakpoint>

        <MenuButton />
      </Flexbox>
    </Flexbox>
  );
};
