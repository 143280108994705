import { ethers } from "ethers";

import type { Signer } from "@/types/web3";

import type { ERC20 } from "../typechain";
import Erc20Abi from "../interfaces/ERC20.json";

export function getErc20ContractInstance(
  tokenAddress: string,
  signer: Signer
): ERC20 {
  return new ethers.Contract(tokenAddress, Erc20Abi, signer) as ERC20;
}
