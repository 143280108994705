import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import CPK from "contract-proxy-kit";

import type { Address, EthersBigNumber } from "@/types/web3";
import type { State } from "@/hooks/useTwoStepTransactionFlag";
import { getBigNumber } from "@/utils/getBigNumber";
import type { NativeSwapToken, SwapToken, Tier } from "@/pages/Swap/types";
import { useBatchedValues } from "@/hooks/useBatchedValues";
import { getErc20AllowanceMulticall } from "@/services/multicall";
import { useIsWaitingProtocolAfterOnboardingStorage } from "@/services/local-storage";
import { useInterval } from "@/hooks/useInterval";
import { Chain } from "@/constants/chains";
import { TRADE_PLATFORM_BASE_URL } from "@/constants/environment";
import { useDialog } from "@/components/Dialog";
import { ROUTES } from "@/routes";

import { SwapFormOnboardingDialog } from "../SwapFormOnboardingDialog";

import { openCenteredPopupWindow } from "./utils/openCenteredPopupWindow";
import { matchIsAllowedToSwap } from "./utils/matchIsAllowedToSwap";

const REVALIDATE_TIER_INTERVAL_MILLISECONDS = 2000;

interface Props {
  cpk: CPK | null;
  connectedAddress: Address | null;
  connectedChain: Chain | null;
  tier: Tier | undefined;
  preOnboardingContent: string;
  tokenIn: SwapToken | NativeSwapToken | undefined;
  tokenInAmount: string;
  maxTokenInAmount: string | undefined;
  smtProtocolFee: string | undefined;
  approveState: State;
  swapState: State;
  wrapState: State;
  unwrapState: State;
  swapRouterError: string | undefined;
  swapDetailsError: string | undefined;
  isSmtFeeDiscountApplied: boolean;
  isWrappingNativeToken: boolean;
  isUnwrappingNativeToken: boolean;
  isSecurityRestrictionCompliant: boolean | undefined;
  connectWallet: () => void;
  revalidateTier: () => void;
  approve: (tokenAddress: Address) => void;
  makeSwap: () => void;
}

export const SwapFormButton: React.FC<Props> = (props) => {
  const {
    cpk,
    connectedAddress,
    connectedChain,
    tier,
    preOnboardingContent,
    tokenIn,
    tokenInAmount,
    maxTokenInAmount,
    smtProtocolFee,
    approveState,
    swapState,
    wrapState,
    unwrapState,
    swapRouterError,
    swapDetailsError,
    isSmtFeeDiscountApplied,
    isWrappingNativeToken,
    isUnwrappingNativeToken,
    isSecurityRestrictionCompliant,
    connectWallet,
    revalidateTier,
    approve,
    makeSwap,
  } = props;

  const {
    value: isWaitingProtocolAfterOnboarding,
    setValue: setIsWaitingProtocolAfterOnboarding,
  } = useIsWaitingProtocolAfterOnboardingStorage();

  const onboardingDialogProps = useDialog();

  const cpkAddress = cpk !== null ? cpk.address : undefined;
  const smtAddress =
    connectedChain !== null ? connectedChain.contractsAddresses.smt : undefined;

  const batchedValuesCalls = React.useMemo(() => {
    if (
      smtAddress === undefined ||
      connectedAddress === null ||
      cpkAddress === undefined
    ) {
      return [];
    }

    if (isSmtFeeDiscountApplied === false) {
      return [];
    }

    return [
      getErc20AllowanceMulticall(smtAddress, connectedAddress, cpkAddress),
    ];
  }, [isSmtFeeDiscountApplied, smtAddress, connectedAddress, cpkAddress]);

  const batchedValues = useBatchedValues<{ smtAllowance: EthersBigNumber }>(
    batchedValuesCalls
  );

  const isLoading =
    isWaitingProtocolAfterOnboarding ||
    approveState === "confirming" ||
    approveState === "executing" ||
    swapState === "confirming" ||
    swapState === "executing" ||
    wrapState === "confirming" ||
    wrapState === "executing" ||
    unwrapState === "confirming" ||
    unwrapState === "executing";

  const hasToConnectWallet = connectedAddress === null;

  const hasToCompletePassport =
    tier === undefined || matchIsAllowedToSwap(tier) === false;

  const hasToApproveTokenIn =
    tokenIn !== undefined &&
    tokenInAmount !== "" &&
    (tokenIn.allowance === undefined ||
      getBigNumber(tokenIn.allowance).lt(tokenInAmount));

  const hasToApproveSmt =
    batchedValues !== null &&
    batchedValues.smtAllowance !== undefined &&
    smtProtocolFee !== undefined &&
    batchedValues.smtAllowance.lt(smtProtocolFee);

  const hasInsufficientFunds =
    tokenInAmount !== "" &&
    maxTokenInAmount !== undefined &&
    getBigNumber(maxTokenInAmount).lt(tokenInAmount);

  const [onboardingPopupWindow, setOnboardingPopupWindow] =
    React.useState<Window | null>(null);

  React.useEffect(() => {
    if (onboardingPopupWindow === null) {
      return;
    }

    const closePopupOnOnboardingCompleted = (event: MessageEvent<string>) => {
      if (event.origin !== TRADE_PLATFORM_BASE_URL) {
        return;
      }

      if (event.data === "onboardingCompleted") {
        setIsWaitingProtocolAfterOnboarding(true);
        onboardingPopupWindow.close();
      }
    };

    window.addEventListener("message", closePopupOnOnboardingCompleted);

    return () => {
      window.removeEventListener("message", closePopupOnOnboardingCompleted);
    };
  }, [onboardingPopupWindow, setIsWaitingProtocolAfterOnboarding]);

  // Revalidate tier in the background
  useInterval(
    revalidateTier,
    isWaitingProtocolAfterOnboarding
      ? REVALIDATE_TIER_INTERVAL_MILLISECONDS
      : undefined
  );

  // Toggle it off when finished
  React.useEffect(() => {
    if (
      isWaitingProtocolAfterOnboarding &&
      tier !== undefined &&
      matchIsAllowedToSwap(tier)
    ) {
      setIsWaitingProtocolAfterOnboarding(false);
    }
  }, [
    isWaitingProtocolAfterOnboarding,
    setIsWaitingProtocolAfterOnboarding,
    tier,
  ]);

  const getLabel = (): string => {
    if (
      approveState === "executing" ||
      swapState === "executing" ||
      wrapState === "executing" ||
      unwrapState === "executing"
    ) {
      return "Executing...";
    }

    if (
      approveState === "confirming" ||
      swapState === "confirming" ||
      wrapState === "confirming" ||
      unwrapState === "confirming"
    ) {
      return "Confirming...";
    }

    if (isWaitingProtocolAfterOnboarding) {
      return "Updating passport...";
    }

    if (swapRouterError !== undefined) {
      const hardcodedError =
        SWAP_ROUTER_ERRORS[swapRouterError as keyof typeof SWAP_ROUTER_ERRORS];

      if (hardcodedError !== undefined) {
        return hardcodedError;
      }

      return "There was an error";
    }

    if (swapDetailsError !== undefined) {
      const hardcodedError =
        SWAP_ROUTER_ERRORS[swapDetailsError as keyof typeof SWAP_ROUTER_ERRORS];

      if (hardcodedError !== undefined) {
        return hardcodedError;
      }

      return "There was an error";
    }

    if (hasToConnectWallet) {
      return "Connect wallet";
    }

    if (hasToCompletePassport) {
      return "Complete passport";
    }

    if (hasInsufficientFunds && tokenIn !== undefined) {
      return `Insufficient ${tokenIn.symbol} funds`;
    }

    if (hasToApproveTokenIn) {
      return `Enable ${tokenIn.symbol}`;
    }

    if (hasToApproveSmt && smtAddress !== undefined) {
      return "Enable SMT";
    }

    if (isWrappingNativeToken && connectedChain !== null) {
      return `Wrap ${connectedChain.nativeToken.symbol} to ${connectedChain.wrappedNativeToken.symbol}`;
    }

    if (isUnwrappingNativeToken && connectedChain !== null) {
      return `Unwrap ${connectedChain.wrappedNativeToken.symbol} to ${connectedChain.nativeToken.symbol}`;
    }

    return "Swap";
  };

  const getOnClick = (): (() => void) => {
    if (isWaitingProtocolAfterOnboarding) {
      return () => {};
    }

    if (swapRouterError !== undefined || swapDetailsError !== undefined) {
      return () => {};
    }

    if (hasToConnectWallet) {
      return connectWallet;
    }

    if (hasToCompletePassport) {
      return () => {
        onboardingDialogProps.open();
      };
    }

    if (hasToApproveTokenIn) {
      return () => {
        approve(tokenIn.address);
      };
    }

    if (hasToApproveSmt && smtAddress !== undefined) {
      return () => {
        approve(smtAddress);
      };
    }

    if (
      approveState === "idle" &&
      swapState === "idle" &&
      wrapState === "idle" &&
      unwrapState === "idle"
    ) {
      return makeSwap;
    }

    return () => {};
  };

  return (
    <React.Fragment>
      <LoadingButton
        variant="contained"
        fullWidth={true}
        sx={(theme) => {
          return {
            height: "52px",
            textTransform: "none",
            fontSize: "1.25rem",
            fontWeight: 600,
            boxShadow: theme.custom.shadows.levelOne,
            "&:hover": {
              boxShadow: theme.custom.shadows.levelTwo,
            },
          };
        }}
        loading={isLoading}
        loadingPosition={isLoading ? "start" : undefined}
        startIcon={isLoading ? <SaveIcon /> : null}
        disabled={
          (tokenInAmount === "" ||
            hasInsufficientFunds ||
            isSecurityRestrictionCompliant === undefined ||
            isSecurityRestrictionCompliant === false) &&
          hasToConnectWallet === false &&
          hasToCompletePassport === false
        }
        onClick={getOnClick()}
      >
        {getLabel()}
      </LoadingButton>

      <SwapFormOnboardingDialog
        content={preOnboardingContent}
        isOpen={onboardingDialogProps.isOpen}
        openOnboarding={() => {
          setOnboardingPopupWindow(
            openCenteredPopupWindow(
              ROUTES.TRADE_PLATFORM_ONBOARDING,
              "onboarding",
              window,
              1200,
              600
            )
          );
          onboardingDialogProps.close();
        }}
        close={onboardingDialogProps.close}
      />
    </React.Fragment>
  );
};

const SWAP_ROUTER_ERRORS = {
  "0x546f6b656e206973206e6f7420616c6c6f77656420696e2070726f746f636f6c":
    "Token is not authorized",
  "0x416d6f756e74206578636565647320706f6f6c206c696d6974":
    "Amount entered exceeds pool limits",
  "0x546f6b656e206973206e6f7420737761707061626c652c207468657265206e6f20616e7920706f6f6c":
    "No pools with this asset",
  "0x53656c656374656420746f6b656e20706169722063616e6e6f742062652073776170706564":
    "Selected token pair cannot be swapped",
};
