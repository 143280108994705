import type { StoreSlice } from "../store";

export interface WalletStoreSlice {
  lastConnectedWalletLabel: string | null;
  setLastConnectedWalletLabel: (label: string) => void;
  clearLastConnectedWalletLabel: () => void;
}

export const createWalletStoreSlice: StoreSlice<WalletStoreSlice> = (set) => {
  return {
    lastConnectedWalletLabel: null,
    setLastConnectedWalletLabel: (label) => {
      set({
        lastConnectedWalletLabel: label,
      });
    },
    clearLastConnectedWalletLabel: () => {
      set({
        lastConnectedWalletLabel: null,
      });
    },
  };
};
