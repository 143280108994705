import SwapRouter, {
  SwapError,
  SwapTxType,
} from "@swarmmarkets/smart-order-router";

import { getNormalizedDecimalsNumber } from "@/utils/getNormalizedDecimalsNumber";
import type {
  NativeSwapToken,
  SwapOptimizationStrategy,
  SwapToken,
} from "@/pages/Swap/types";

export function getSwapDetails(
  swapRouter: SwapRouter | undefined,
  swapType: SwapTxType,
  swapOptimizationStrategy: SwapOptimizationStrategy,
  tokenIn: SwapToken | NativeSwapToken | undefined,
  tokenOut: SwapToken | NativeSwapToken | undefined,
  tokenInAmount: string,
  tokenOutAmount: string
) {
  if (swapRouter === undefined) {
    return { swapDetails: undefined, swapDetailsError: undefined };
  }

  const referenceSwapToken =
    swapType === SwapTxType.exactIn ? tokenIn : tokenOut;

  if (referenceSwapToken === undefined) {
    return { swapDetails: undefined, swapDetailsError: undefined };
  }

  const swapAmount =
    swapType === SwapTxType.exactIn ? tokenInAmount : tokenOutAmount;

  if (swapAmount === "") {
    return { swapDetails: undefined, swapDetailsError: undefined };
  }

  const normalizedSwapAmount = getNormalizedDecimalsNumber(
    swapAmount,
    referenceSwapToken.decimals
  );

  try {
    const { shortestRoute, bestSpotPriceRoute, routesDiff } =
      swapRouter.getOptimizedRoutes(swapType, normalizedSwapAmount);

    if (routesDiff !== null && swapOptimizationStrategy === "price") {
      return {
        swapDetails: {
          ...bestSpotPriceRoute,
          swapStrategiesDiff: routesDiff,
        },
        swapDetailsError: undefined,
      };
    }

    return {
      swapDetails: { ...shortestRoute, swapStrategiesDiff: routesDiff },
      swapDetailsError: undefined,
    };
  } catch (error) {
    if (error instanceof SwapError) {
      return {
        swapDetails: undefined,
        swapDetailsError: error.hash,
      };
    }

    throw error;
  }
}
