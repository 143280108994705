import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

interface StyledImageProps {
  $isDisabled: boolean;
}

const StyledImage = styled("img")<StyledImageProps>(({ $isDisabled }) => {
  return {
    opacity: $isDisabled ? 0.4 : 1,
    filter: $isDisabled ? "alpha(opacity=40)" : "none",
  };
});

interface Props extends React.ComponentProps<typeof Box> {
  src: string;
  width: string;
  height: string;
  fallback: string;
  alt: string;
  isDisabled?: boolean;
}

export const Image: React.FC<Props> = (props) => {
  const {
    src,
    width,
    height,
    fallback,
    alt,
    isDisabled = false,
    ...boxProps
  } = props;

  const [derivedSrc, setDerivedSrc] = React.useState<string>(src);

  const handleOnError = () => {
    setDerivedSrc(fallback);
  };

  React.useEffect(() => {
    setDerivedSrc(src);
  }, [src]);

  return (
    <Box {...boxProps} width={width} height={height}>
      <StyledImage
        src={derivedSrc}
        alt={alt}
        $isDisabled={isDisabled}
        onError={handleOnError}
        sx={{
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  );
};
