import { Call } from "../types";

export function getXTokenAmountInSmt(
  smtPriceFeedAddress: string,
  xTokenAddress: string,
  xTokenAmount: string,
  options: { label?: string } = {}
): Call {
  const { label = "xTokenAmountInSmt" } = options;

  return {
    target: smtPriceFeedAddress,
    call: [
      "calculateAmount(address,uint256)(uint256)",
      xTokenAddress,
      xTokenAmount,
    ],
    label,
  };
}
