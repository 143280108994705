import React from "react";

import { Chain } from "@/constants/chains";

import type { EthersBigNumber } from "@/types/web3";
import { useBatchedValues } from "@/hooks/useBatchedValues";
import {
  getProtocolFeeOne,
  getSwapFeeProtocolFeePercentage,
  getTokenAmountMinimumProtocolFeePercentage,
} from "@/services/multicall";
import { getBigNumber } from "@/utils/getBigNumber";

interface ReturnValue {
  swapFeeProtocolFeePercentage: number;
  tokenAmountMinimumProtocolFeePercentage: number;
}

export function useSwarmProtocolFee(connectedChain: Chain | null): ReturnValue {
  const batchedValuesCalls = React.useMemo(() => {
    if (connectedChain === null) {
      return [];
    }

    return [
      getProtocolFeeOne(connectedChain.contractsAddresses.protocolFee, {
        label: "protocolFeeOne",
      }),
      getSwapFeeProtocolFeePercentage(
        connectedChain.contractsAddresses.protocolFee,
        { label: "swapFeeProtocolFeePercentage" }
      ),
      getTokenAmountMinimumProtocolFeePercentage(
        connectedChain.contractsAddresses.protocolFee,
        { label: "tokenAmountMinimumProtocolFeePercentage" }
      ),
    ];
  }, [connectedChain]);

  const batchedValues = useBatchedValues<{
    protocolFeeOne: EthersBigNumber;
    swapFeeProtocolFeePercentage: EthersBigNumber;
    tokenAmountMinimumProtocolFeePercentage: EthersBigNumber;
  }>(batchedValuesCalls);

  const value = React.useMemo(() => {
    if (
      batchedValues === null ||
      batchedValues.protocolFeeOne === undefined ||
      batchedValues.swapFeeProtocolFeePercentage === undefined ||
      batchedValues.tokenAmountMinimumProtocolFeePercentage === undefined
    ) {
      return {
        swapFeeProtocolFeePercentage: 0,
        tokenAmountMinimumProtocolFeePercentage: 0,
      };
    }

    const swapFeeProtocolFeePercentage = getBigNumber(
      batchedValues.swapFeeProtocolFeePercentage
    )
      .div(getBigNumber(batchedValues.protocolFeeOne))
      .mul(100)
      .toNumber();

    const tokenAmountMinimumProtocolFeePercentage = getBigNumber(
      batchedValues.tokenAmountMinimumProtocolFeePercentage
    )
      .div(getBigNumber(batchedValues.protocolFeeOne))
      .mul(100)
      .toNumber();

    return {
      swapFeeProtocolFeePercentage,
      tokenAmountMinimumProtocolFeePercentage,
    };
  }, [batchedValues]);

  return value;
}
