import { Call } from "../types";

export function getXTokenPriceInSmt(
  smtPriceFeedAddress: string,
  xTokenAddress: string,
  options: { label?: string } = {}
): Call {
  const { label = "xTokenPriceInSmt" } = options;

  return {
    target: smtPriceFeedAddress,
    call: ["getPrice(address)(uint256)", xTokenAddress],
    label,
  };
}
