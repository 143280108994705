import { ethers } from "ethers";

import type { Address } from "@/types/web3";

export function matchIsSameEthereumAddress(
  addressA: Address,
  addressB: Address
): boolean {
  try {
    return (
      ethers.utils.getAddress(addressA) === ethers.utils.getAddress(addressB)
    );
  } catch {
    return false;
  }
}
